const RegexUtils = {
  NAME: /^[a-zA-Z][a-zA-Z ]*$/,
  EMAIL: /^\S+@\S+\.\S+$/,
  PASSWORD: /^(?=.*[A-Za-z])(?=.*[0-9])(?=.*[!@#$%^&*()_+,.\\\/;':"-]).{8,}$/,
  OTP: /^$|[0-9]{6}/,
  PHONE: /^\d{3}-\d{3}-\d{4}$/,
  NUMERIC: /^(0|[0-9]\d*)(\.\d+)?$/,
};

export default RegexUtils;
