import EnumUtils from "utils/EnumUtils.js";
import Chip from "@mui/material/Chip";

function getStatusChip(formStatusTypeEnum) {
  let color = "success";
  if (formStatusTypeEnum === "NOT_STARTED") {
    color = "error";
  } else if (formStatusTypeEnum === "INCOMPLETE") {
    color = "warning";
  }
  return <Chip label={EnumUtils.parse(formStatusTypeEnum)} color={color} />;
}

const debounce = (func, delay) => {
  let timer;
  return function (...args) {
    const context = this;
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(context, args);
    }, delay);
  };
};

const FormUtils = {
  getStatusChip,
  debounce,
};

export default FormUtils;
