function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name) {
  if (name === undefined || name === "") return "";
  return {
    sx: {
      bgcolor: stringToColor(name),
      width: 32,
      height: 32,
    },
    children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
  };
}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function formatPhoneNumber(e) {
  const input = e.target.value.replace(/\D/g, "").substring(0, 10);
  const areaCode = input.substring(0, 3);
  const middle = input.substring(3, 6);
  const last = input.substring(6, 10);
  let result;
  if (input.length > 6) {
    result = `${areaCode}-${middle}-${last}`;
  } else if (input.length > 3) {
    result = `${areaCode}-${middle}`;
  } else if (input.length > 0) {
    result = `${areaCode}`;
  }
  return result;
}

function getAgeFromDOB(dateString) {
  var today = new Date();
  var birthDate = new Date(dateString);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
}

const StringUtils = {
  stringAvatar,
  capitalizeFirstLetter,
  formatPhoneNumber,
  getAgeFromDOB,
};

export default StringUtils;
