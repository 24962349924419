const HOSPITAL_STATS_CHART_PROPS = {
  patientAgeGroup: {
    dataLabels: ["Child", "Adult", "Adolescent"],
  },
  doctorAsmtForms: {
    title: "Doctor Assessments Completed",
    width: 450,
    xaxis: {
      title: "",
      categories: [""],
    },
    yaxis: {
      min: 0,
      max: undefined,
      title: "Count",
    },
    annotations: {
      yaxis: [],
    },
  },
  enrollmentSessions: {
    title: "Enrollment Sessions Completed",
    width: 450,
    xaxis: {
      title: "",
      categories: [""],
    },
    yaxis: {
      min: 0,
      max: undefined,
      title: "Count",
    },
    annotations: {
      yaxis: [],
    },
  },
};
export default HOSPITAL_STATS_CHART_PROPS;
