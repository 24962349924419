import "styles/App.css";
import PatientHeader from "components/headers/PatientHeader";
import Footer from "components/footers/Footer";
import {Outlet} from "react-router-dom";

function PatientHome() {
  return (
    <div className="d-flex justify-content-between flex-row no-wrap">
      <div className="flex-grow-1">
        <PatientHeader />
        <div>
          <div id="patient-container" className="patient-container">
            <Outlet />
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default PatientHome;
