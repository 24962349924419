import Chart from "react-apexcharts";

function ColumnBarChart({ chartProps, data }) {
  let series = data;

  let options = {
    title: {
      text: chartProps.title,
      style: {
        fontSize: "14px",
        fontWeight: "bold",
        fontFamily: "Open Sans",
        color: "#263238",
      },
    },
    chart: {
      type: "bar",
    },
    annotations: {
      yaxis: chartProps.annotations.yaxis.map((annotation) => {
        return {
          y: annotation.low,
          y2: annotation.high,
          borderColor: "#000",
          fillColor: "#00E396",
          label: {
            text: annotation.label,
            borderColor: "#00E396",
            style: {
              color: "#fff",
              background: "#00E396",
            },
          },
        };
      }),
    },
    plotOptions: {
      bar: {
        borderRadius: 5,
        horizontal: false,
        columnWidth: "50px",
        barHeight: "20px",
        endingShape: "rounded",
      },
    },
    noData: {
      text: "No Data",
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 0,
      style: {
        color: "black",
        fontSize: "14px",
        // fontFamily: undefined,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 5,
      colors: ["transparent"],
    },
    legend: {
      show: true,
      position: "top",
      horizontalAlign: "center",
      fontSize: "14px",
      fontFamily: "Helvetica, Arial, sans-serif",
      fontWeight: 400,
      offsetY: -5,
    },
    xaxis: {
      title: {
        text: chartProps.xaxis.title,
      },
      max: 1,
      categories: chartProps.xaxis.categories,
      labels: {
        style: {
          colors: [],
          fontSize: "14px",
          fontFamily: "Helvetica, Arial, sans-serif",
          fontWeight: 400,
          cssClass: "apexcharts-xaxis-label",
        },
      },
    },
    yaxis: {
      min: chartProps.yaxis.min,
      max: chartProps.yaxis.max,
      title: {
        text: chartProps.yaxis.title,
        style: {
          colors: [],
          fontSize: "14px",
          fontWeight: 400,
        },
      },
      labels: {
        style: {
          colors: [],
          fontSize: "14px",
          fontFamily: "Helvetica, Arial, sans-serif",
          fontWeight: 400,
          cssClass: "apexcharts-yaxis-label",
        },
        formatter: function (val) {
          return val.toFixed(0);
        },
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val;
        },
      },
    },
  };
  return (
    <Chart
      options={options}
      series={series}
      type="bar"
      width={chartProps.width}
    />
  );
}

export default ColumnBarChart;
