import { useState, useRef, useEffect, useCallback } from "react";
import {
  Typography,
  Box,
  Stack,
  Button,
  Paper,
  CircularProgress,
} from "@mui/material";
import { useParams } from "react-router";
import LoadingButton from "@mui/lab/LoadingButton";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import EnhancedTable from "components/utils/Table.js";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import TableRowActionsCell from "components/utils/TableRowActionsCell.js";
import PopUpModal from "components/utils/PopUpModal.js";
import FamilyService from "services/FamilyService.js";
import useAPI from "hooks/useAPI.js";

function FamilyMemberDocs() {
  const { patientId } = useParams();

  const [documentsList, setDocumentsList] = useState([]);
  const inputFileRef = useRef(null);
  const popUpModalRef = useRef(null);
  const [popUpModalContent, setPopUpModalContent] = useState({
    documentId: "",
  });
  const [documentIdToBeDeleted, setDocumentIdToBeDeleted] = useState();

  const getDocumentsAPI = useAPI(
    FamilyService.getMemberDocuments,
    getDocumentsHandler
  );
  const uploadDocumentAPI = useAPI(
    FamilyService.uploadMemberDocument,
    uploadDocumentHandler,
    true
  );
  const deleteDocumentAPI = useAPI(
    FamilyService.deleteMemberDocument,
    deleteDocumentHandler,
    true
  );

  const tableHeadings = [
    {
      id: "id",
      label: "ID",
    },
    {
      id: "fileName",
      label: "File Name",
    },
    {
      id: "uploadedAt",
      label: "Uploaded At",
    },
    {
      id: "actions",
      label: "Actions",
    },
  ];

  const getDocuments = useCallback(() => {
    getDocumentsAPI.execute(patientId);
  }, []);

  useEffect(() => {
    getDocuments();
  }, []);

  function uploadDocumentHandler(response) {
    getDocuments();
  }

  function deleteDocument(documentId) {
    deleteDocumentAPI.execute(patientId, documentId);
    setDocumentIdToBeDeleted(documentId);
  }

  function uploadDocument(file) {
    var formData = new FormData();
    formData.append("document", file);
    uploadDocumentAPI.execute(patientId, formData);
  }

  function getDocumentsHandler(response) {
    let freshDocumentsList = [];
    for (let form of response.data) {
      freshDocumentsList.push(transformJsonData(form));
    }
    setDocumentsList(freshDocumentsList);
  }

  function deleteDocumentHandler(response) {
    setDocumentsList((prevState) => {
      let documentListIndex = prevState.findIndex(
        (document) => document.id === documentIdToBeDeleted
      );
      prevState.splice(documentListIndex, 1);
      return [...prevState];
    });
  }

  const onFileChange = (e) => {
    /* Selected files data can be collected here. */

    uploadDocument(e.target.files[0]);
  };
  const onUploadButtonClick = () => {
    /* Collecting node-element and performing click */
    inputFileRef.current.click();
  };

  function openModal() {
    popUpModalRef.current.openModal();
  }

  function closeModal() {
    popUpModalRef.current.closeModal();
  }

  return (
    <Paper elevation={0}>
      <div className="d-flex justify-content-between p-3">
        <h5>Documents</h5>
        <div>
          {uploadDocumentAPI.status === "pending" ? (
            <LoadingButton
              loading
              loadingPosition="start"
              startIcon={<FileUploadIcon />}
              variant="contained"
              className="me-3"
              color="secondary"
            >
              Upload File
            </LoadingButton>
          ) : (
            <Button
              onClick={onUploadButtonClick}
              startIcon={<FileUploadIcon />}
              variant="contained"
              color="secondary"
            >
              Upload File
            </Button>
          )}

          <input
            type="file"
            ref={inputFileRef}
            onChangeCapture={onFileChange}
            hidden="hidden"
          />
        </div>
      </div>
      {getDocumentsAPI.status === "pending" ? (
        <div className="text-center">
          <CircularProgress className="text-center" />
        </div>
      ) : (
        <EnhancedTable
          headings={tableHeadings}
          rowsData={documentsList}
          rowsPerPage={10}
        />
      )}
      <PopUpModal ref={popUpModalRef}>
        <DeleteDocumentModal documentId={popUpModalContent.documentId} />
      </PopUpModal>
    </Paper>
  );

  function transformJsonData(form) {
    return {
      id: form.id,
      fileName: form.originalFilename,
      uploadedAt: form.lastUpdatedAt,
      actions: <ActionsCell documentId={form.id} link={form.s3FileUrl} />,
    };
  }

  function ActionsCell({ documentId, link }) {
    const actions = {
      startItems: [
        {
          component: <OpenInNewIcon fontSize="inherit" color="primary" />,
          onClickHandler: () => window.open(link),
        },
        {
          component: <DeleteOutlineIcon fontSize="inherit" color="error" />,
          onClickHandler: () => {
            setPopUpModalContent({
              documentId,
            });
            openModal();
          },
        },
      ],
      menuItems: [],
    };
    return <TableRowActionsCell actions={actions} />;
  }

  function DeleteDocumentModal({ documentId }) {
    return (
      <Box
        className="px-3 py-4"
        sx={{
          maxWidth: "350px",
        }}
      >
        <Typography id="modal-modal-title" variant="h3" color="text">
          Do you want to confirm deleting the document?
        </Typography>
        <Stack direction="row" spacing={3} className="mt-4">
          <Button
            onClick={() => {
              deleteDocument(documentId);
              closeModal();
            }}
            variant="contained"
          >
            Confirm
          </Button>
          <Button onClick={() => closeModal()} variant="outlined">
            Cancel
          </Button>
        </Stack>
      </Box>
    );
  }
}

export default FamilyMemberDocs;
