import ApiBaseService from "services/ApiBaseService.js";

class OrganizationService {
  static getOrganizationHospitals() {
    return ApiBaseService.request(`/organizations/hospitals`, "GET", null);
  }

  static getHospitalStats([hospitalId]) {
    return ApiBaseService.request(
      `/organizations/hospital/${hospitalId}/stats`,
      "GET",
      null
    );
  }

  static getHospitalDoctors([hospitalId]) {
    return ApiBaseService.request(
      `/organizations/hospital/${hospitalId}/doctors`,
      "GET",
      null
    );
  }
}

export default OrganizationService;
