import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import EnhancedTable from "components/utils/Table.js";
import IconButton from "@mui/material/IconButton";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";

function PatientConsent() {
  return (
    <div className="d-flex flex-column align-items-end">
      <Button
        className="mb-3"
        onClick="{}"
        startIcon={<img src="assets/plus-icon.svg" alt="Add icon" />}
        variant="contained"
      >
        New Consent Form
      </Button>
      <ConsentFormsList />
    </div>
  );
}

function ConsentFormsList() {
  const tableRowData = [
    createData("1", "12/04/2022", getActionsCell("1")),
    createData("2", "08/06/2022", getActionsCell("2")),
    createData("3", "19/09/2022", getActionsCell("3")),
    createData("4", "06/07/2022", getActionsCell("4")),
    createData("5", "24/01/2022", getActionsCell("5"))
  ];

  const tableHeadings = [
    {
      id: "id",
      label: "S/N"
    },
    {
      id: "date",
      label: "Date"
    },
    {
      id: "actions",
      label: "Actions"
    }
  ];

  return (
    <Paper elevation={0} className="px-3 w-100">
      <EnhancedTable
        headings={tableHeadings}
        rowsData={tableRowData}
        rowsPerPage={10}
      />
    </Paper>
  );
}

function getActionsCell(id) {
  return <TableRowActionsCell />;
}

function TableRowActionsCell() {
  return (
    <>
      <IconButton size="small">
        <VisibilityOutlinedIcon fontSize="inherit" color="secondary" />
      </IconButton>
      <IconButton size="small">
        <DeleteOutlineIcon fontSize="inherit" color="error" />
      </IconButton>
    </>
  );
}

function createData(id, date, actions) {
  return {id, date, actions};
}

export default PatientConsent;
