import AuthService from "services/AuthService.js";
import Cookies from "universal-cookie";

const cookies = new Cookies();

class Authenticator {
  static verifyCredentials(email, password, captchaToken) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await AuthService.verifyCredentials(
          { email, password },
          captchaToken
        );
        const transactionId = response.data.transactionId;
        resolve({
          transactionId,
        });
      } catch (error) {
        console.log(error);
        reject(error);
      }
    });
  }

  static authenticate(email, password, otp, transactionId, captchaToken) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await AuthService.authenticate(
          { email, password, otp, transactionId },
          captchaToken
        );
        // Set access_token
        cookies.set("access_token", response.data.access_token, { path: "/" });

        // Set api_base_url
        cookies.set("api_base_url", process.env.REACT_APP_API_BASE_URL, {
          path: "/",
        });

        let roles = response.data.roles;
        // Set user_role_type
        cookies.set("user_role_type", roles[0], { path: "/" });

        let navigateTo = "/hospital";
        if (roles.includes("PATIENT")) {
          navigateTo = "/patient";
        }

        // Set start_view_path
        cookies.set("start_view_path", navigateTo, { path: "/" });

        resolve({ navigateTo });
      } catch (error) {
        reject(error);
      }
    });
  }

  static resendOTP(email, password, captchaToken) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await AuthService.resendOTP(
          { email, password },
          captchaToken
        );
        const transactionId = response.data.transactionId;
        resolve({
          transactionId,
        });
      } catch (error) {
        console.log(error);
        reject(error);
      }
    });
  }

  static logout() {
    this.clearCookies();
    window.location = "/";
  }

  static isAuthenticated() {
    let isAuth = cookies.get("access_token") && cookies.get("start_view_path");

    if (!isAuth) this.clearCookies();

    //TODO: Validate if the access token is valid
    return isAuth;
  }

  static isAuthorized(routeType) {
    let userRoleType = cookies.get("user_role_type");

    if (routeType === "user") return true;

    if (routeType === "patient") return userRoleType === "PATIENT";

    if (routeType === "admin")
      return (
        userRoleType === "ORGANIZATION_ADMIN" ||
        userRoleType === "HOSPITAL_ADMIN"
      );

    return true;
  }

  static clearCookies() {
    // Remove access_token
    cookies.remove("access_token", { path: "/" });

    // Remove start_view_path
    cookies.remove("start_view_path", { path: "/" });

    // Remove user_role_type
    cookies.remove("user_role_type", { path: "/" });
  }
}

export default Authenticator;
