import Chart from "react-apexcharts";

function RadialChart({ chartProps, dataLabels, data }) {
  let series = data;

  let options = {
    chart: {
      type: "radialBar",
    },
    plotOptions: {
      radialBar: {
        hollow: {
          size: "35%",
          background: "transparent",
        },
        dataLabels: {
          name: {
            show: true,
            offsetY: 5,
            fontSize: "14px",
          },
          value: {
            show: true,
            fontSize: "16px",
            formatter: function (val) {
              return val;
            },
          },
          total: {
            show: true,
            label: chartProps.total.label,
            fontSize: "14px",
            fontWeight: 400,

            formatter: function (w) {
              return "";
            },
          },
        },
      },
    },
    labels: dataLabels,
    legend: {
      show: true,
      position: "top",
      horizontalAlign: "center",
      fontSize: "14px",
      fontFamily: "Helvetica, Arial, sans-serif",
      fontWeight: 400,
      offsetY: 20,
    },
  };

  return (
    <Chart
      options={options}
      series={series}
      type="radialBar"
      height="325"
      width="400"
    />
  );
}

export default RadialChart;
