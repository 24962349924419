import ApiBaseService from "services/ApiBaseService";

class PasswordService {
  static requestOtp(data, captchaToken) {
    const headers = {
      "Captcha-Token": captchaToken
    };
    return ApiBaseService.request("/users/password/request-otp", "POST", data, headers);
  }

  static verifyOtp(data, captchaToken) {
    const headers = {
      "Captcha-Token": captchaToken
    };
    return ApiBaseService.request("/users/password/verify-otp", "POST", data, headers);
  }

  static resetPassword(data, captchaToken) {
    const headers = {
      "Captcha-Token": captchaToken
    };
    return ApiBaseService.request(`/users/password/reset`, "POST", data, headers);
  }

  static changePassword(data) {
    return ApiBaseService.request(`/users/password/change`, "PUT", data);
  }
}

export default PasswordService;
