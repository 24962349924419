import {useState, useEffect} from "react";
import withRouter from "components/utils/withRouter.js";
import styles from "styles/patients.module.css";
import PathUtils from "utils/PathUtils.js";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";

function SideNavBar(props) {
  const [activeItem, setActiveItem] = useState();

  useEffect(() => {
    let path = props.location.pathname;
    setActiveItem(PathUtils.getSideNavBarItemName(path, props.items, props.basePathElement));
  }, [props.location.pathname]);

  return (<Paper elevation={0} className="px-3 pt-4 pb-3" sx={{
      width: 220
    }}>
    {
      props.items.map((item, index) => (<Button key={index} startIcon={getItemStartIcon(item["name"])} color={getItemColor(item["name"])} onClick={() => {
          setActiveItem(item["name"]);
          props.navigate(item.routeName);
        }} className="mb-2 ps-3 w-100 justify-content-start">
        {item.name}
      </Button>))
    }
  </Paper>);

  function getItemColor(itemName) {
    if (itemName === activeItem) 
      return "info";
    return "text";
  }

  function getItemStartIcon(itemName) {
    if (itemName === activeItem) 
      return <img src="/assets/side-arrow-blue-icon.svg" alt="Arrow icon"/>;
    return <img src="/assets/side-arrow-grey-icon.svg" alt="Arrow icon"/>;
  }
}

export default withRouter(SideNavBar);
