export const DOCTOR_ASSESSMENT_FORM_TYPES = [
  {
    name: "Basic Assessment Form",
    formTypeEnum: "BASIC_ASSESSMENT",
  },
  {
    name: "General Assessment Form",
    formTypeEnum: "GENERAL_ASSESSMENT",
  },
  {
    name: "Extended Assessment Form",
    formTypeEnum: "EXTENDED_ASSESSMENT",
  },
];

export const DOCTOR_STIM_FORM_TYPES = [
  {
    name: "Basic Stim Form",
    formTypeEnum: "BASIC_STIM",
  },
  {
    name: "General Stim Form",
    formTypeEnum: "GENERAL_STIM",
  },
  {
    name: "Extended Stim Form",
    formTypeEnum: "EXTENDED_STIM",
  },
  {
    name: "Virtual Stim Form",
    formTypeEnum: "VIRTUAL_STIM",
  },
];
