import ApiBaseService from "services/ApiBaseService.js";

class PatientService {
  static addPatient([hospitalId, data]) {
    return ApiBaseService.request(
      `/hospitals/${hospitalId}/patients`,
      "POST",
      data
    );
  }

  static getAllPatients([hospitalId, page = 0, size = 1000000, search = ""]) {
    return ApiBaseService.request(
      `/hospitals/${hospitalId}/patients?page=${page}&size=${size}&search=${search}`,
      "GET",
      null
    );
  }

  static getPatient([hospitalId, patientId]) {
    return ApiBaseService.request(
      `/hospitals/${hospitalId}/patients/${patientId}`,
      "GET",
      null
    );
  }

  static updatePatientDetails([hospitalId, patientId, data]) {
    return ApiBaseService.request(
      `/hospitals/${hospitalId}/patients/${patientId}`,
      "PUT",
      data
    );
  }

  static updatePatientEmail([hospitalId, patientId, data]) {
    return ApiBaseService.request(
      `/hospitals/${hospitalId}/patients/${patientId}/email`,
      "PATCH",
      data
    );
  }

  static archivePatient([hospitalId, patientId]) {
    return ApiBaseService.request(
      `/hospitals/${hospitalId}/patients/${patientId}/archive`,
      "POST",
      null
    );
  }

  static updatePatientProfilePhoto([hospitalId, patientId, formData]) {
    const headers = {
      "Content-Type": "multipart/form-data",
    };
    return ApiBaseService.request(
      `/hospitals/${hospitalId}/patients/${patientId}/profile-photo`,
      "POST",
      formData,
      headers
    );
  }

  static getPatientBiometrics([hospitalId, patientId]) {
    return ApiBaseService.request(
      `/hospitals/${hospitalId}/patients/${patientId}/biometrics`,
      "GET",
      null
    );
  }

  static updatePatientBiometrics([hospitalId, patientId, data]) {
    return ApiBaseService.request(
      `/hospitals/${hospitalId}/patients/${patientId}/biometrics`,
      "POST",
      data
    );
  }

  static addSoapNote([hospitalId, patientId, data]) {
    console.log(patientId, data);
    return ApiBaseService.request(
      `/hospitals/${hospitalId}/patients/${patientId}/soap-notes`,
      "POST",
      data
    );
  }

  static getSoapNotes([hospitalId, patientId, noteType]) {
    return ApiBaseService.request(
      `/hospitals/${hospitalId}/patients/${patientId}/soap-notes?noteType=${noteType}`,
      "GET",
      null
    );
  }

  static updateSoapNote([hospitalId, patientId, noteId, data]) {
    return ApiBaseService.request(
      `/hospitals/${hospitalId}/patients/${patientId}/soap-notes/${noteId}`,
      "PUT",
      data
    );
  }

  static deleteSoapNote([hospitalId, patientId, noteId]) {
    return ApiBaseService.request(
      `/hospitals/${hospitalId}/patients/${patientId}/soap-notes/${noteId}`,
      "DELETE",
      null
    );
  }

  static uploadPatientDocument([hospitalId, patientId, formData]) {
    const headers = {
      "Content-Type": "multipart/form-data",
    };
    return ApiBaseService.request(
      `/hospitals/${hospitalId}/patients/${patientId}/documents`,
      "POST",
      formData,
      headers
    );
  }

  static getPatientDocuments([hospitalId, patientId]) {
    return ApiBaseService.request(
      `/hospitals/${hospitalId}/patients/${patientId}/documents`,
      "GET",
      null
    );
  }

  static deletePatientDocument([hospitalId, patientId, documentId]) {
    return ApiBaseService.request(
      `/hospitals/${hospitalId}/patients/${patientId}/documents/${documentId}`,
      "DELETE",
      null
    );
  }

  static sendRegistrationEmail([hospitalId, patientId]) {
    return ApiBaseService.request(
      `/hospitals/${hospitalId}/patients/${patientId}/registration-email`,
      "POST",
      null
    );
  }

  static addPatientParent([hospitalId, patientId, data]) {
    return ApiBaseService.request(
      `/hospitals/${hospitalId}/patients/${patientId}/parents`,
      "POST",
      data
    );
  }

  static getPatientParents([hospitalId, patientId]) {
    return ApiBaseService.request(
      `/hospitals/${hospitalId}/patients/${patientId}/parents`,
      "GET",
      null
    );
  }

  static updatePatientParent([hospitalId, patientId, patientParentId, data]) {
    return ApiBaseService.request(
      `/hospitals/${hospitalId}/patients/${patientId}/parents/${patientParentId}`,
      "PUT",
      data
    );
  }

  static removePatientParent([hospitalId, patientId, patientParentId]) {
    return ApiBaseService.request(
      `/hospitals/${hospitalId}/patients/${patientId}/parents/${patientParentId}`,
      "DELETE",
      null
    );
  }

  static getRegistrationForms([hospitalId, patientId]) {
    return ApiBaseService.request(
      `/hospitals/${hospitalId}/patients/${patientId}/registration-forms`,
      "GET"
    );
  }

  static revokeRegistrationFormSubmission([hospitalId, patientId, formId]) {
    return ApiBaseService.request(
      `/hospitals/${hospitalId}/patients/${patientId}/registration-forms/${formId}/revoke`,
      "POST",
      {}
    );
  }
}

export default PatientService;
