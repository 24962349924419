import styles from "styles/footer.module.css";
import Link from "@mui/material/Link";

function HospitalFooter() {
  return (
    <div
      id={styles["footer"]}
      className="d-flex justify-content-between align-items-center"
    >
      <span id={styles["copyright-text"]}>
        &#169; Copyright 2022 - Neuroelpis
      </span>
      <div>
        <Link href="/terms-of-service" underline="none" target="blank">
          Terms of Service
        </Link>
        <Link
          href="/privacy-policy"
          underline="none"
          className="ms-4"
          target="blank"
        >
          Privacy Policy
        </Link>
      </div>
    </div>
  );
}

export default HospitalFooter;
