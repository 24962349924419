import ApiBaseService from "services/ApiBaseService.js";

class FamilyService {
  static addMember([data]) {
    return ApiBaseService.request("/patients/family", "POST", data);
  }

  static getMembers() {
    return ApiBaseService.request("/patients/family", "GET", null);
  }

  static getMember([patientId]) {
    return ApiBaseService.request(`/patients/family/${patientId}`, "GET", null);
  }

  static getMemberBasicDetails([patientId]) {
    return ApiBaseService.request(
      `/patients/family/${patientId}/basic-details`,
      "GET",
      null
    );
  }

  static updateMember([patientId, data]) {
    return ApiBaseService.request(`/patients/family/${patientId}`, "PUT", data);
  }

  static uploadMemberDocument([patientId, formData]) {
    const headers = {
      "Content-Type": "multipart/form-data",
    };
    return ApiBaseService.request(
      `/patients/family/${patientId}/documents`,
      "POST",
      formData,
      headers
    );
  }

  static getMemberDocuments([patientId]) {
    return ApiBaseService.request(
      `/patients/family/${patientId}/documents`,
      "GET",
      null
    );
  }

  static deleteMemberDocument([patientId, documentId]) {
    return ApiBaseService.request(
      `/patients/family/${patientId}/documents/${documentId}`,
      "DELETE",
      null
    );
  }
}

export default FamilyService;
