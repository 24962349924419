import moment from "moment";

function formatDateTime(dateTime) {
  return moment(dateTime, "YYYY-MM-DD hh:mm a")
    .utc(dateTime)
    .local()
    .format("MM/DD/YYYY hh:mm A");
}

function formatDateToCalendarInput(dateTime) {
  return moment(dateTime, "YYYY-MM-DD hh:mm a")
    .utc(dateTime)
    .local()
    .format("YYYY-MM-DDTHH:mm:ss");
}

function formatTime(time) {
  return moment(time, "hh:mm a").format("hh:mm a");
}

function formatUtcDateTimeToDateInput(dateTime) {
  return moment(dateTime, "YYYY-MM-DD hh:mm a")
    .utc(dateTime)
    .local()
    .format("yyyy-MM-DD");
}

function formatUtcDateTimeToTimeInput(dateTime) {
  return moment(dateTime, "YYYY-MM-DD hh:mm a")
    .utc(dateTime)
    .local()
    .format("HH:mm");
}

function convertTimestampToDateInput(timestamp) {
  return moment(timestamp, "YYYY-MM-DDTHH:mm:ss").format("yyyy-MM-DD");
}

function convertTimestampToTimeInput(timestamp) {
  return moment(timestamp, "YYYY-MM-DDTHH:mm:ss").format("HH:mm");
}

function formatDateTimeToDate(dateTime) {
  return moment(dateTime, "YYYY-MM-DD hh:mm a")
    .utc(dateTime)
    .local()
    .format("MM/DD/YYYY");
}

function formatDateTimeToTime(dateTime) {
  return moment(dateTime, "YYYY-MM-DD hh:mm a")
    .utc(dateTime)
    .local()
    .format("hh:mm a");
}

function formatDateTimeToTimestamp(dateTime) {
  return moment.utc(moment(dateTime)).format("YYYY-MM-DDTHH:mm:ss");
}

function isValidDate(date) {
  date = moment(date);
  return date.isValid();
}

function addOneYear(startDate) {
  startDate = moment(startDate); // Replace this with your desired start date
  const endDate = startDate.clone().add(1, "year");

  return formatDateTimeToTimestamp(endDate);
}

const DateUtils = {
  formatDateTime,
  formatDateToCalendarInput,
  formatTime,
  formatUtcDateTimeToDateInput,
  formatUtcDateTimeToTimeInput,
  convertTimestampToDateInput,
  convertTimestampToTimeInput,
  formatDateTimeToDate,
  formatDateTimeToTime,
  formatDateTimeToTimestamp,
  isValidDate,
  addOneYear,
};

export default DateUtils;
