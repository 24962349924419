import React, { useState, useEffect, useCallback } from "react";
import {useSnackbar} from "notistack";
import SnackbarConstants from "utils/constants/Snackbar.js";


function useAPI(apiServiceFunction, handleSuccess = () => {}, showSuccessAlert = false, immediate = false) {
    const [status, setStatus] = useState("idle");
    const [response, setResponse] = useState(null);
    const [error, setError] = useState(null);

    const {enqueueSnackbar} = useSnackbar();

    // The execute function wraps apiServiceFunction and
    // handles setting state for pending, response, and error.
    // useCallback ensures the below useEffect is not called
    // on every render, but only if apiServiceFunction changes.
    const execute = useCallback((...args) => {
      setStatus("pending");
      setResponse(null);
      setError(null);
      return apiServiceFunction(args)
        .then((response) => {
          console.log(response)
          setResponse(response);
          setStatus("success");
          handleSuccess(response);
          if(showSuccessAlert)
            enqueueSnackbar(response.message, SnackbarConstants.success);
        })
        .catch((error) => {
          setError(error);
          setStatus("error");
          handleError(error);
        });
    }, [apiServiceFunction]);

    function handleError(error) {
      console.log(error);
      let errorData = error.data;
      if (errorData && errorData.message) {
        enqueueSnackbar(errorData.message, SnackbarConstants.error);
        let errors = errorData.errors;
        if (errors) {
          errors.forEach((item, i) => {
            enqueueSnackbar(item, SnackbarConstants.error);
          });
        }
      } else {
        enqueueSnackbar("Oops! Something went wrong", SnackbarConstants.error);
      }
    }

    // Call execute if we want to fire it right away.
    // Otherwise execute can be called later, such as
    // in an onClick handler.
    useEffect(() => {
      if (immediate) {
        execute();
      }
    }, [execute, immediate]);
    return { execute, status, response, error };
}

export default useAPI;