import Chart from "react-apexcharts";

function PieChart({ chartProps, dataLabels, data }) {
  let series = data;

  let options = {
    chart: {
      type: "pie",
    },
    plotOptions: {
      pie: {
        expandOnClick: true,
        offsetX: 0,
        offsetY: 20,
        customScale: 1,
        dataLabels: {
          offset: 0,
        },
      },
    },
    labels: dataLabels,
    legend: {
      show: true,
      position: "top",
      horizontalAlign: "center",
      fontSize: "14px",
      fontFamily: "Helvetica, Arial, sans-serif",
      fontWeight: 400,
      offsetY: 20,
    },
  };

  return (
    <Chart
      options={options}
      series={series}
      type="pie"
      height="250"
      width="250"
    />
  );
}

export default PieChart;
