import styles from "styles/side-main-menu.module.css";
import MAIN_NAV_BAR_ITEMS from "utils/constants/MainNavBarItems.js";
import PathUtils from "utils/PathUtils.js";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import withRouter from "components/utils/withRouter.js";

function MainNavBar(props) {
  const [activeItem, setActiveItem] = useState();
  const [activeSubItem, setActiveSubItem] = useState();

  const location = useLocation();

  useEffect(() => {
    let path = location.pathname;
    setActiveItem(PathUtils.getMainNavBarItemRouteName(path));
    setActiveSubItem(PathUtils.getMainNavBarSubItemName(path));
  }, [location.pathname]);

  return (
    <div id={styles["side-nav-bar"]}>
      <header>
        <img
          id={styles["logo"]}
          src="assets/logo-color.webp"
          alt="Neuroelpis Logo"
        />
      </header>
      {MAIN_NAV_BAR_ITEMS.map((navItem, i) => (
        <div
          key={i}
          className={
            styles["nav-item"] +
            " " +
            styles[getItemStyleClassName(navItem["routeName"])]
          }
          onClick={() => navigateView(navItem)}
        >
          <div className="d-flex justify-content-between align-items-center flex-row no-wrap">
            <div>
              <img
                src={
                  isNavBarItemActive(navItem["routeName"])
                    ? navItem["iconActive"]
                    : navItem["icon"]
                }
                alt={navItem["iconAltText"]}
              />
              <span>{navItem["name"]}</span>
            </div>
            {getMenuItemDownArrow(
              navItem["children"].length,
              isNavBarItemActive(navItem["routeName"])
            )}
          </div>
          {isNavBarItemActive(navItem["routeName"])
            ? getNavBarItemChildren(navItem["children"])
            : null}
        </div>
      ))}
    </div>
  );

  function navigateView(navItem) {
    setActiveItem(navItem["routeName"]);
    if (navItem["children"].length === 0) {
      // change view
      props.navigate(navItem["routeName"]);
    }
  }

  function getNavBarItemChildren(childrenItems) {
    if (childrenItems.length === 0) return null;
    return (
      <div className="mt-2">
        {childrenItems.map((childItem, i) => (
          <div
            key={i}
            className={
              styles["nav-sub-item"] +
              " " +
              styles[getSubItemStyleClassName(childItem["name"])]
            }
            onClick={() => navigateSubView(childItem)}
          >
            <span>{childItem["name"]}</span>
          </div>
        ))}
      </div>
    );
  }

  function getMenuItemDownArrow(childrenLength, isActive) {
    if (childrenLength === 0) return null;
    if (isActive)
      return (
        <img
          className={styles["down-arrow-icon"]}
          src="assets/down-arrow-icon.svg"
          alt="Down Arrow Icon"
        />
      );
    else
      return (
        <img
          className={styles["down-arrow-icon"]}
          src="assets/down-arrow-icon.svg"
          alt="Down Arrow Icon"
        />
      );
  }

  function navigateSubView(navSubItem) {
    setActiveSubItem(navSubItem["name"]);
    props.navigate(navSubItem["routeName"]);
  }

  function isNavBarItemActive(navItemName) {
    return navItemName === activeItem;
  }

  function getItemStyleClassName(navItemName) {
    if (navItemName === activeItem) return "active-nav-item";
    return "";
  }

  function getSubItemStyleClassName(navSubItemName) {
    if (navSubItemName === activeSubItem) return "active-nav-sub-item";
    return "";
  }
}

export default withRouter(MainNavBar);
