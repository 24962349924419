import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import RRuleGenerator from "components/rrule";

const EventRecurrenceModal = ({ rRule, setRrule, onCloseHandler }) => {
  return (
    <>
      <div className="my-2">
        <div
          className="rounded p-3"
          style={{
            backgroundColor: "#eef3f8",
          }}
        >
          <Typography variant="h4">Set Recurrence</Typography>
        </div>
        <div className="m-3">
          <RRuleGenerator
            value={rRule}
            onChange={(rrule) => setRrule(rrule)}
            config={{
              repeat: ["Daily", "Weekly", "Monthly", "Yearly"],
              yearly: "on",
              monthly: "on the",
              end: ["After"],
            }}
          />
        </div>
        <div className="text-end">
          <Button
            onClick={() => {
              setRrule("");
              onCloseHandler();
            }}
            variant="outlined"
            className="me-2"
          >
            Discard
          </Button>
          <Button type="button" variant="contained" onClick={onCloseHandler}>
            Save
          </Button>
        </div>
      </div>
    </>
  );
};

export default EventRecurrenceModal;
