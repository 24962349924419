const COUNTRY_CODES_LIST = [
  {
    value: "+213",
    country: "Algeria (+213)"
  }, {
    value: "+376",
    country: "Andorra (+376)"
  }, {
    value: "+244",
    country: "Angola (+244)"
  }, {
    value: "+1264",
    country: "Anguilla (+1264)"
  }, {
    value: "+1268",
    country: "Antigua & Barbuda (+1268)"
  }, {
    value: "+54",
    country: "Argentina (+54)"
  }, {
    value: "+374",
    country: "Armenia (+374)"
  }, {
    value: "+297",
    country: "Aruba (+297)"
  }, {
    value: "+61",
    country: "Australia (+61)"
  }, {
    value: "+43",
    country: "Austria (+43)"
  }, {
    value: "+994",
    country: "Azerbaijan (+994)"
  }, {
    value: "+1242",
    country: "Bahamas (+1242)"
  }, {
    value: "+973",
    country: "Bahrain (+973)"
  }, {
    value: "+880",
    country: "Bangladesh (+880)"
  }, {
    value: "+1246",
    country: "Barbados (+1246)"
  }, {
    value: "+375",
    country: "Belarus (+375)"
  }, {
    value: "+32",
    country: "Belgium (+32)"
  }, {
    value: "+501",
    country: "Belize (+501)"
  }, {
    value: "+229",
    country: "Benin (+229)"
  }, {
    value: "+1441",
    country: "Bermuda (+1441)"
  }, {
    value: "+975",
    country: "Bhutan (+975)"
  }, {
    value: "+591",
    country: "Bolivia (+591)"
  }, {
    value: "+387",
    country: "Bosnia Herzegovina (+387)"
  }, {
    value: "+267",
    country: "Botswana (+267)"
  }, {
    value: "+55",
    country: "Brazil (+55)"
  }, {
    value: "+673",
    country: "Brunei (+673)"
  }, {
    value: "+359",
    country: "Bulgaria (+359)"
  }, {
    value: "+226",
    country: "Burkina Faso (+226)"
  }, {
    value: "+257",
    country: "Burundi (+257)"
  }, {
    value: "+855",
    country: "Cambodia (+855)"
  }, {
    value: "+237",
    country: "Cameroon (+237)"
  }, {
    value: "+1",
    country: "USA/Canada (+1)"
  }, {
    value: "+238",
    country: "Cape Verde Islands (+238)"
  }, {
    value: "+1345",
    country: "Cayman Islands (+1345)"
  }, {
    value: "+236",
    country: "Central African Republic (+236)"
  }, {
    value: "+56",
    country: "Chile (+56)"
  }, {
    value: "+86",
    country: "China (+86)"
  }, {
    value: "+57",
    country: "Colombia (+57)"
  }, {
    value: "+269",
    country: "Comoros (+269)"
  }, {
    value: "+242",
    country: "Congo (+242)"
  }, {
    value: "+682",
    country: "Cook Islands (+682)"
  }, {
    value: "+506",
    country: "Costa Rica (+506)"
  }, {
    value: "+385",
    country: "Croatia (+385)"
  }, {
    value: "+53",
    country: "Cuba (+53)"
  }, {
    value: "+90392",
    country: "Cyprus North (+90392)"
  }, {
    value: "+357",
    country: "Cyprus South (+357)"
  }, {
    value: "+42",
    country: "Czech Republic (+42)"
  }, {
    value: "+45",
    country: "Denmark (+45)"
  }, {
    value: "+253",
    country: "Djibouti (+253)"
  }, {
    value: "+1809",
    country: "Dominica (+1809)"
  }, {
    value: "+1809",
    country: "Dominican Republic (+1809)"
  }, {
    value: "+593",
    country: "Ecuador (+593)"
  }, {
    value: "+20",
    country: "Egypt (+20)"
  }, {
    value: "+503",
    country: "El Salvador (+503)"
  }, {
    value: "+240",
    country: "Equatorial Guinea (+240)"
  }, {
    value: "+291",
    country: "Eritrea (+291)"
  }, {
    value: "+372",
    country: "Estonia (+372)"
  }, {
    value: "+251",
    country: "Ethiopia (+251)"
  }, {
    value: "+500",
    country: "Falkland Islands (+500)"
  }, {
    value: "+298",
    country: "Faroe Islands (+298)"
  }, {
    value: "+679",
    country: "Fiji (+679)"
  }, {
    value: "+358",
    country: "Finland (+358)"
  }, {
    value: "+33",
    country: "France (+33)"
  }, {
    value: "+594",
    country: "French Guiana (+594)"
  }, {
    value: "+689",
    country: "French Polynesia (+689)"
  }, {
    value: "+241",
    country: "Gabon (+241)"
  }, {
    value: "+220",
    country: "Gambia (+220)"
  }, {
    value: "+7880",
    country: "Georgia (+7880)"
  }, {
    value: "+49",
    country: "Germany (+49)"
  }, {
    value: "+233",
    country: "Ghana (+233)"
  }, {
    value: "+350",
    country: "Gibraltar (+350)"
  }, {
    value: "+30",
    country: "Greece (+30)"
  }, {
    value: "+299",
    country: "Greenland (+299)"
  }, {
    value: "+1473",
    country: "Grenada (+1473)"
  }, {
    value: "+590",
    country: "Guadeloupe (+590)"
  }, {
    value: "+671",
    country: "Guam (+671)"
  }, {
    value: "+502",
    country: "Guatemala (+502)"
  }, {
    value: "+224",
    country: "Guinea (+224)"
  }, {
    value: "+245",
    country: "Guinea - Bissau (+245)"
  }, {
    value: "+592",
    country: "Guyana (+592)"
  }, {
    value: "+509",
    country: "Haiti (+509)"
  }, {
    value: "+504",
    country: "Honduras (+504)"
  }, {
    value: "+852",
    country: "Hong Kong (+852)"
  }, {
    value: "+36",
    country: "Hungary (+36)"
  }, {
    value: "+354",
    country: "Iceland (+354)"
  }, {
    value: "+91",
    country: "India (+91)"
  }, {
    value: "+62",
    country: "Indonesia (+62)"
  }, {
    value: "+98",
    country: "Iran (+98)"
  }, {
    value: "+964",
    country: "Iraq (+964)"
  }, {
    value: "+353",
    country: "Ireland (+353)"
  }, {
    value: "+972",
    country: "Israel (+972)"
  }, {
    value: "+39",
    country: "Italy (+39)"
  }, {
    value: "+1876",
    country: "Jamaica (+1876)"
  }, {
    value: "+81",
    country: "Japan (+81)"
  }, {
    value: "+962",
    country: "Jordan (+962)"
  }, {
    value: "+7",
    country: "Kazakhstan (+7)"
  }, {
    value: "+254",
    country: "Kenya (+254)"
  }, {
    value: "+686",
    country: "Kiribati (+686)"
  }, {
    value: "+850",
    country: "Korea North (+850)"
  }, {
    value: "+82",
    country: "Korea South (+82)"
  }, {
    value: "+965",
    country: "Kuwait (+965)"
  }, {
    value: "+996",
    country: "Kyrgyzstan (+996)"
  }, {
    value: "+856",
    country: "Laos (+856)"
  }, {
    value: "+371",
    country: "Latvia (+371)"
  }, {
    value: "+961",
    country: "Lebanon (+961)"
  }, {
    value: "+266",
    country: "Lesotho (+266)"
  }, {
    value: "+231",
    country: "Liberia (+231)"
  }, {
    value: "+218",
    country: "Libya (+218)"
  }, {
    value: "+417",
    country: "Liechtenstein (+417)"
  }, {
    value: "+370",
    country: "Lithuania (+370)"
  }, {
    value: "+352",
    country: "Luxembourg (+352)"
  }, {
    value: "+853",
    country: "Macao (+853)"
  }, {
    value: "+389",
    country: "Macedonia (+389)"
  }, {
    value: "+261",
    country: "Madagascar (+261)"
  }, {
    value: "+265",
    country: "Malawi (+265)"
  }, {
    value: "+60",
    country: "Malaysia (+60)"
  }, {
    value: "+960",
    country: "Maldives (+960)"
  }, {
    value: "+223",
    country: "Mali (+223)"
  }, {
    value: "+356",
    country: "Malta (+356)"
  }, {
    value: "+692",
    country: "Marshall Islands (+692)"
  }, {
    value: "+596",
    country: "Martinique (+596)"
  }, {
    value: "+222",
    country: "Mauritania (+222)"
  }, {
    value: "+269",
    country: "Mayotte (+269)"
  }, {
    value: "+52",
    country: "Mexico (+52)"
  }, {
    value: "+691",
    country: "Micronesia (+691)"
  }, {
    value: "+373",
    country: "Moldova (+373)"
  }, {
    value: "+377",
    country: "Monaco (+377)"
  }, {
    value: "+976",
    country: "Mongolia (+976)"
  }, {
    value: "+1664",
    country: "Montserrat (+1664)"
  }, {
    value: "+212",
    country: "Morocco (+212)"
  }, {
    value: "+258",
    country: "Mozambique (+258)"
  }, {
    value: "+95",
    country: "Myanmar (+95)"
  }, {
    value: "+264",
    country: "Namibia (+264)"
  }, {
    value: "+674",
    country: "Nauru (+674)"
  }, {
    value: "+977",
    country: "Nepal (+977)"
  }, {
    value: "+31",
    country: "Netherlands (+31)"
  }, {
    value: "+687",
    country: "New Caledonia (+687)"
  }, {
    value: "+64",
    country: "New Zealand (+64)"
  }, {
    value: "+505",
    country: "Nicaragua (+505)"
  }, {
    value: "+227",
    country: "Niger (+227)"
  }, {
    value: "+234",
    country: "Nigeria (+234)"
  }, {
    value: "+683",
    country: "Niue (+683)"
  }, {
    value: "+672",
    country: "Norfolk Islands (+672)"
  }, {
    value: "+670",
    country: "Northern Marianas (+670)"
  }, {
    value: "+47",
    country: "Norway (+47)"
  }, {
    value: "+968",
    country: "Oman (+968)"
  }, {
    value: "+680",
    country: "Palau (+680)"
  }, {
    value: "+507",
    country: "Panama (+507)"
  }, {
    value: "+675",
    country: "Papua New Guinea (+675)"
  }, {
    value: "+595",
    country: "Paraguay (+595)"
  }, {
    value: "+51",
    country: "Peru (+51)"
  }, {
    value: "+63",
    country: "Philippines (+63)"
  }, {
    value: "+48",
    country: "Poland (+48)"
  }, {
    value: "+351",
    country: "Portugal (+351)"
  }, {
    value: "+1787",
    country: "Puerto Rico (+1787)"
  }, {
    value: "+974",
    country: "Qatar (+974)"
  }, {
    value: "+262",
    country: "Reunion (+262)"
  }, {
    value: "+40",
    country: "Romania (+40)"
  }, {
    value: "+7",
    country: "Russia (+7)"
  }, {
    value: "+250",
    country: "Rwanda (+250)"
  }, {
    value: "+378",
    country: "San Marino (+378)"
  }, {
    value: "+239",
    country: "Sao Tome & Principe (+239)"
  }, {
    value: "+966",
    country: "Saudi Arabia (+966)"
  }, {
    value: "+221",
    country: "Senegal (+221)"
  }, {
    value: "+381",
    country: "Serbia (+381)"
  }, {
    value: "+248",
    country: "Seychelles (+248)"
  }, {
    value: "+232",
    country: "Sierra Leone (+232)"
  }, {
    value: "+65",
    country: "Singapore (+65)"
  }, {
    value: "+421",
    country: "Slovak Republic (+421)"
  }, {
    value: "+386",
    country: "Slovenia (+386)"
  }, {
    value: "+677",
    country: "Solomon Islands (+677)"
  }, {
    value: "+252",
    country: "Somalia (+252)"
  }, {
    value: "+27",
    country: "South Africa (+27)"
  }, {
    value: "+34",
    country: "Spain (+34)"
  }, {
    value: "+94",
    country: "Sri Lanka (+94)"
  }, {
    value: "+290",
    country: "St. Helena (+290)"
  }, {
    value: "+1869",
    country: "St. Kitts (+1869)"
  }, {
    value: "+1758",
    country: "St. Lucia (+1758)"
  }, {
    value: "+249",
    country: "Sudan (+249)"
  }, {
    value: "+597",
    country: "Suriname (+597)"
  }, {
    value: "+268",
    country: "Swaziland (+268)"
  }, {
    value: "+46",
    country: "Sweden (+46)"
  }, {
    value: "+41",
    country: "Switzerland (+41)"
  }, {
    value: "+963",
    country: "Syria (+963)"
  }, {
    value: "+886",
    country: "Taiwan (+886)"
  }, {
    value: "+7",
    country: "Tajikstan (+7)"
  }, {
    value: "+66",
    country: "Thailand (+66)"
  }, {
    value: "+228",
    country: "Togo (+228)"
  }, {
    value: "+676",
    country: "Tonga (+676)"
  }, {
    value: "+1868",
    country: "Trinidad & Tobago (+1868)"
  }, {
    value: "+216",
    country: "Tunisia (+216)"
  }, {
    value: "+90",
    country: "Turkey (+90)"
  }, {
    value: "+7",
    country: "Turkmenistan (+7)"
  }, {
    value: "+993",
    country: "Turkmenistan (+993)"
  }, {
    value: "+1649",
    country: "Turks & Caicos Islands (+1649)"
  }, {
    value: "+688",
    country: "Tuvalu (+688)"
  }, {
    value: "+256",
    country: "Uganda (+256)"
  }, {
    value: "+44",
    country: "UK (+44)"
  }, {
    value: "+380",
    country: "Ukraine (+380)"
  }, {
    value: "+971",
    country: "United Arab Emirates (+971)"
  }, {
    value: "+598",
    country: "Uruguay (+598)"
  }, {
    value: "+7",
    country: "Uzbekistan (+7)"
  }, {
    value: "+678",
    country: "Vanuatu (+678)"
  }, {
    value: "+379",
    country: "Vatican City (+379)"
  }, {
    value: "+58",
    country: "Venezuela (+58)"
  }, {
    value: "+84",
    country: "Vietnam (+84)"
  }, {
    value: "+84",
    country: "Virgin Islands - British (+1284)"
  }, {
    value: "+84",
    country: "Virgin Islands - US (+1340)"
  }, {
    value: "+681",
    country: "Wallis & Futuna (+681)"
  }, {
    value: "+969",
    country: "Yemen (North)(+969)"
  }, {
    value: "+967",
    country: "Yemen (South)(+967)"
  }, {
    value: "+260",
    country: "Zambia (+260)"
  }, {
    value: "+263",
    country: "Zimbabwe (+263)"
  }
];

export default COUNTRY_CODES_LIST;
