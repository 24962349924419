import BreadCrumbUpperCaseTokens from "utils/constants/BreadCrumbUpperCaseTokens.js";
import StringUtils from "utils/StringUtils.js";
import MAIN_NAV_BAR_ITEMS from "utils/constants/MainNavBarItems.js";

function extractBreadCrumbTokens(path) {
  const pathElements = path.split("/");
  let breadCrumbList = [];
  for (let index in pathElements) {
    let element = pathElements[index];
    element = element.replace("-", " ");
    element = element.replace("%20", " ");
    let elementTokens = element.split(" ");
    let updatedElement = "";
    for (let tokenIndex in elementTokens) {
      let elementToken = decoratePathToken(elementTokens[tokenIndex]);
      updatedElement += StringUtils.capitalizeFirstLetter(elementToken) + " ";
    }
    updatedElement = updatedElement.slice(0, -1);
    if (updatedElement !== "") breadCrumbList.push(updatedElement);
  }
  return breadCrumbList;
}

function decoratePathToken(token) {
  if (BreadCrumbUpperCaseTokens.includes(token)) token = token.toUpperCase();
  return token;
}

function getPatientId(path) {
  const pathElements = path.split("/");
  let index = pathElements.indexOf("patients");
  return pathElements[index + 1];
}

function getMainNavBarItemRouteName(path) {
  const pathElements = path.split("/");
  let index = pathElements.indexOf("hospital");
  let routeName = "";
  if (pathElements.length <= index + 1) return routeName;
  routeName = pathElements[index + 1];
  for (let menuItem of MAIN_NAV_BAR_ITEMS) {
    if (menuItem.routeName === routeName) return menuItem.routeName;
  }
  return routeName;
}

function getMainNavBarSubItemName(path) {
  const pathElements = path.split("/");
  let index = pathElements.indexOf("hospital");
  let name = "";
  if (pathElements.length <= index + 2) return name;
  name = pathElements[index + 1] + "/" + pathElements[index + 2];
  for (let menuItem of MAIN_NAV_BAR_ITEMS) {
    for (let menuSubItem of menuItem.children) {
      if (menuSubItem.routeName === name) return menuSubItem.name;
    }
  }
  return name;
}

function getSideNavBarItemName(path, sideNavBarItems, basePathElement) {
  const pathElements = path.split("/");
  let index = pathElements.indexOf(basePathElement);
  let name = "";
  if (pathElements.length <= index + 1) return name;
  name = pathElements[pathElements.length - 1];
  for (let menuItem of sideNavBarItems) {
    if (menuItem.routeName === name) return menuItem.name;
  }
  return name;
}

const PathUtils = {
  extractBreadCrumbTokens,
  getPatientId,
  getMainNavBarItemRouteName,
  getMainNavBarSubItemName,
  getSideNavBarItemName,
};

export default PathUtils;
