import React, { useState, useEffect, useRef } from "react";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import { useForm } from "react-hook-form";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import DoneIcon from "@mui/icons-material/Done";
import SaveIcon from "@mui/icons-material/Save";
import useAPI from "hooks/useAPI.js";
import InputErrorMessage from "components/utils/InputErrorMessage.js";
import PopUpModal from "components/utils/PopUpModal.js";
import EventRecurrenceModal from "./EventRecurrenceModal";
import SchedulingService from "services/SchedulingService";
import { useSnackbar } from "notistack";
import SnackbarConstants from "utils/constants/Snackbar.js";
import DateUtils from "utils/DateUtils";

const AddEventModal = ({ hospitalId, data, onCloseHandler }) => {
  const [eventGuestList, setEventGuestList] = useState([]);
  const [eventGuest, setEventGuest] = useState("");
  const [isEventRecurring, setIsEventRecurring] = useState(false);
  const [rRule, setRrule] = useState("RRULE:FREQ=DAILY;INTERVAL=1;COUNT=1");
  const { enqueueSnackbar } = useSnackbar();

  const setEventRecurrenceModalRef = useRef(null);

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({ criteriaMode: "all", mode: "onTouched" });

  const createEventAPI = useAPI(
    SchedulingService.createEvent,
    createEventHandler,
    false
  );

  function createEvent(data) {
    if (isEventRecurring && rRule === "") {
      enqueueSnackbar("Recurrence is not set", SnackbarConstants.error);
      return;
    }

    data.isRecurring = isEventRecurring;
    if (isEventRecurring) {
      const rule = rRule.substring(6); // removes the preceding 'RRULE:'
      data.recurrentEvent = {
        rule,
        until: DateUtils.addOneYear(data.startDate),
      };
    }

    data.eventGuests = eventGuestList.map((guest) => guest.email);
    data.startsAt = DateUtils.formatDateTimeToTimestamp(
      data.startDate + " " + data.startTime
    );
    data.endsAt = DateUtils.formatDateTimeToTimestamp(
      data.endDate + " " + data.endTime
    );

    console.log(data);

    if (data.eventGuests.length === 0) {
      enqueueSnackbar("Guest to the event not added", SnackbarConstants.error);
      return;
    }

    createEventAPI.execute(hospitalId, data);
  }

  useEffect(() => {
    reset(data);
  }, [data]);

  /* API Success Handlers */

  function createEventHandler(response) {
    onCloseHandler();
  }

  return (
    <>
      <div className="my-2">
        <div
          className="rounded p-3"
          style={{
            backgroundColor: "#eef3f8",
          }}
        >
          <Typography variant="h3">Add Event</Typography>
        </div>
        <form className="m-3" onSubmit={handleSubmit(createEvent)}>
          <div className="row">
            <div className="col col-12 mb-3">
              <label htmlFor="titleInput" className="form-label">
                Title
              </label>
              <input
                type="text"
                className="form-control"
                id="titleInput"
                {...register("title", {
                  required: "Title is required",
                })}
              />
              <InputErrorMessage name="title" errors={errors} />
            </div>
            <div className="col col-12 mb-3">
              <label htmlFor="descriptionInput" className="form-label">
                Description
              </label>
              <textarea
                rows={3}
                className="form-control"
                id="descriptionInput"
                {...register("description", {
                  required: "Description is required",
                })}
              />
              <InputErrorMessage name="description" errors={errors} />
            </div>
            <div className="col col-3 mb-3">
              <label htmlFor="startDateInput" className="form-label">
                Start Date
              </label>
              <input
                type="date"
                className="form-control"
                id="startDateInput"
                {...register("startDate", {
                  required: "Start date is required",
                })}
              />
              <InputErrorMessage name="startDate" errors={errors} />
            </div>
            <div className="col col-3 mb-3">
              <label htmlFor="endDateInput" className="form-label">
                End Date
              </label>
              <input
                type="date"
                className="form-control"
                id="endDateInput"
                {...register("endDate", {
                  required: "End date is required",
                })}
              />
              <InputErrorMessage name="description" errors={errors} />
            </div>
            <div className="col col-3 mb-3">
              <label htmlFor="startTimeInput" className="form-label">
                Start Time
              </label>
              <input
                type="time"
                className="form-control"
                id="startTimeInput"
                {...register("startTime", {
                  required: "Start time is required",
                })}
              />
              <InputErrorMessage name="startTime" errors={errors} />
            </div>
            <div className="col col-3 mb-3">
              <label htmlFor="endTimeInput" className="form-label">
                End Time
              </label>
              <input
                type="time"
                className="form-control"
                id="endTimeInput"
                {...register("endTime", {
                  required: "End time is required",
                })}
              />
              <InputErrorMessage name="endTime" errors={errors} />
            </div>
            <div className="col col-6 mb-3">
              <label className="form-label d-block">Recurring</label>
              <div className="form-check form-switch">
                <input
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  id="isRecurringInput"
                  name="isRecurring"
                  onChange={() => {
                    setIsEventRecurring(!isEventRecurring);
                  }}
                />
                {isEventRecurring ? (
                  <Button
                    onClick={() => {
                      setEventRecurrenceModalRef.current.openModal();
                    }}
                    size="small"
                    variant="outlined"
                    className="ms-2"
                  >
                    Set Recurrence
                  </Button>
                ) : null}
              </div>
            </div>
            <div className="col col-6 mb-3">
              <label className="form-label d-block">Location Type</label>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  id="locationTypeOnline"
                  name="locationType"
                  value="Online"
                  {...register("locationType", {
                    required: "Location type is required",
                  })}
                />
                <label
                  className="form-check-label"
                  htmlFor="locationTypeOnline"
                >
                  Online
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  id="locationTypeOffline"
                  name="locationType"
                  value="Offline"
                  {...register("locationType", {
                    required: "Location type is required",
                  })}
                />
                <label
                  className="form-check-label"
                  htmlFor="locationTypeOffline"
                >
                  Offline
                </label>
              </div>
              <InputErrorMessage name="locationType" errors={errors} />
            </div>
            <div className="col col-7 mb-3">
              <label htmlFor="eventGuestInput" className="form-label">
                Event Guests
              </label>
              <div className="d-flex flex-row">
                <input
                  type="text"
                  className="form-control"
                  id="eventGuestInput"
                  placeholder="Guest email id"
                  value={eventGuest}
                  onChange={(event) => {
                    setEventGuest(event.target.value);
                  }}
                />
                <Button
                  type="button"
                  className="ms-2"
                  size="small"
                  variant="outlined"
                  onClick={() => {
                    if (eventGuest != "") {
                      setEventGuestList((prevState) => {
                        return [
                          ...prevState,
                          {
                            type: "guest",
                            email: eventGuest,
                            isDisabled: false,
                          },
                        ];
                      });
                      setEventGuest("");
                    }
                  }}
                >
                  Add
                </Button>
              </div>

              <div className="d-flex flex-wrap mt-2">
                {eventGuestList.map((guest, index) => {
                  if (guest.isDisabled) {
                    return (
                      <Chip
                        key={index}
                        label={guest.email}
                        variant="outlined"
                        className="me-2 mb-2"
                      />
                    );
                  }
                  return (
                    <Chip
                      key={index}
                      label={guest.email}
                      variant="outlined"
                      className="me-2 mb-2"
                      onDelete={() => {
                        setEventGuestList((prevState) => {
                          let index = prevState.findIndex(
                            (guestItem) => guestItem.email === guest.email
                          );
                          prevState.splice(index, 1);
                          return [...prevState];
                        });
                      }}
                    />
                  );
                })}
              </div>
            </div>
          </div>
          <div className="text-end">
            {" "}
            <Button
              onClick={() => onCloseHandler()}
              variant="outlined"
              className="me-2"
            >
              Close
            </Button>
            {createEventAPI.status === "pending" ? (
              <LoadingButton
                loading
                loadingPosition="start"
                startIcon={<SaveIcon />}
                variant="contained"
              >
                Submit
              </LoadingButton>
            ) : (
              <Button
                type="submit"
                startIcon={<DoneIcon />}
                variant="contained"
              >
                Submit
              </Button>
            )}
          </div>
        </form>
      </div>
      <PopUpModal ref={setEventRecurrenceModalRef} modalWidth={600}>
        <EventRecurrenceModal
          rRule={rRule}
          setRrule={setRrule}
          onCloseHandler={() => {
            setEventRecurrenceModalRef.current.closeModal();
          }}
        />
      </PopUpModal>
    </>
  );
};

export default AddEventModal;
