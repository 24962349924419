const PATIENT_MAIN_NAV_BAR_ITEMS = [
  {
    name: "Patient Profile",
    routeName: "profile"
  },
  // {
  //   name: "Consent Form",
  //   routeName: "consent-form"
  // },
  // {
  //   name: "Timeline",
  //   routeName: "timeline"
  // },
  {
    name: "SOAP Notes",
    routeName: "notes"
  },
  {
    name: "Assessments",
    routeName: "assessments"
  },
  {
    name: "Enrollments",
    routeName: "enrollments"
  }
];

export default PATIENT_MAIN_NAV_BAR_ITEMS;
