import "styles/App.css";
import { Outlet } from "react-router-dom";
import HospitalHeader from "components/headers/HospitalHeader";
import HospitalFooter from "components/footers/HospitalFooter";
import MainNavBar from "components/navs/MainNavBar";

import { useSelector } from "react-redux";
import { selectHospital } from "state/slices/hospitalSlice";

function HospitalHome() {
  const focusedHospital = useSelector(selectHospital);
  const hospitalId = focusedHospital.id;

  return (
    <div className="d-flex justify-content-between flex-row no-wrap">
      <MainNavBar />
      <div className="flex-grow-1">
        <HospitalHeader />
        <div id="home-container">
          {hospitalId != "" ? <Outlet context={[hospitalId]} /> : null}
        </div>
        <HospitalFooter />
      </div>
    </div>
  );
}

export default HospitalHome;
