import "styles/App.css";
import {Outlet} from "react-router-dom";
import {useNavigate} from "react-router-dom";
import HospitalHeader from "components/headers/HospitalHeader";
import PatientHeader from "components/headers/PatientHeader";
import Footer from "components/footers/Footer";
import HospitalFooter from "components/footers/HospitalFooter";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Cookies from "universal-cookie";

const cookies = new Cookies();

function UserHome() {
  const navigate = useNavigate();

  let startViewPath = cookies.get("start_view_path");

  return (<div>
    {
      startViewPath === "/hospital"
        ? <HospitalHeader/>
        : <PatientHeader/>
    }
    <div id="home-container">
      <Stack direction="row" justifyContent="left" alignItems="center" className="mb-3">
        <Button type="button" variant="outlined" onClick={() => navigate(startViewPath)}>
          Go to Dashboard
        </Button>
      </Stack>
      <Outlet/>
    </div>
    {
      (startViewPath === '/hospital')? <HospitalFooter /> : <Footer/>
    }
  </div>);
}

export default UserHome;
