const DOCTOR_ASMT_CHART_PROPS = [
  {
    sectionName: "Autonomic Information",
    charts: [
      {
        type: "columnBar",
        title: "Pulse Rate",
        width: 400,
        xaxis: {
          title: "",
          categories: ["Left", "Right"],
        },
        yaxis: {
          min: 60,
          max: 140,
          title: "BPM",
        },
        annotations: {
          yaxis: [],
        },
        dataKeyChains: [
          "Autonomic Information:2:Pulse Rate:Left:Measure",
          "Autonomic Information:2:Pulse Rate:Right:Measure",
        ],
      },
      {
        type: "columnBar",
        title: "Pulse Ox",
        width: 400,
        xaxis: {
          title: "",
          categories: ["Left", "Right"],
        },
        yaxis: {
          min: 90,
          max: 100,
          title: "",
        },
        annotations: {
          yaxis: [],
        },
        dataKeyChains: [
          "Autonomic Information:3:Pulse Ox:Left:Measure",
          "Autonomic Information:3:Pulse Ox:Right:Measure",
        ],
      },
      {
        type: "columnBar",
        title: "Respiration Rate",
        width: 400,
        xaxis: {
          title: "",
          categories: [""],
        },
        yaxis: {
          min: 8,
          max: 25,
          title: "",
        },
        annotations: {
          yaxis: [],
        },
        dataKeyChains: ["Autonomic Information:4:Respiration Rate:Measure:"],
      },
      {
        type: "columnBar",
        title: "Body Fat",
        width: 400,
        xaxis: {
          title: "",
          categories: [""],
        },
        yaxis: {
          min: 0,
          max: 100,
          title: "%",
        },
        annotations: {
          yaxis: [],
        },
        dataKeyChains: ["Autonomic Information:5:Body Fat:Measure:"],
      },
      {
        type: "columnBar",
        title: "Temperature",
        width: 400,
        xaxis: {
          title: "",
          categories: ["Left Ear", "Right Ear"],
        },
        yaxis: {
          min: 30,
          max: 42,
          title: "C",
        },
        annotations: {
          yaxis: [],
        },
        dataKeyChains: [
          "Autonomic Information:6:Temperature:Left Ear:Measure",
          "Autonomic Information:6:Temperature:Right Ear:Measure",
        ],
      },
    ],
  },
  {
    sectionName: "Primitive Reflexes",
    charts: [
      {
        type: "columnBar",
        title: "TLR",
        width: 400,
        xaxis: {
          title: "",
          categories: ["Level"],
        },
        yaxis: {
          min: 0,
          max: 4,
          title: "",
        },
        annotations: {
          yaxis: [],
        },
        dataKeyChains: ["Primitive Reflexes:1::TLR:Level"],
      },
      {
        type: "columnBar",
        title: "ASTNR (S)",
        width: 400,
        xaxis: {
          title: "",
          categories: ["Left", "Right", "Level"],
        },
        yaxis: {
          min: 0,
          max: 4,
          title: "",
        },
        annotations: {
          yaxis: [],
        },
        dataKeyChains: [
          "Primitive Reflexes:1::ASTNR (S):Left",
          "Primitive Reflexes:1::ASTNR (S):Right",
          "Primitive Reflexes:1::ASTNR (S):Level",
        ],
      },
      {
        type: "columnBar",
        title: "Moro (Sound)",
        width: 400,
        xaxis: {
          title: "",
          categories: ["Level"],
        },
        yaxis: {
          min: 0,
          max: 4,
          title: "",
        },
        annotations: {
          yaxis: [],
        },
        dataKeyChains: ["Primitive Reflexes:1::Moro (Sound):Level"],
      },
      {
        type: "columnBar",
        title: "ATNR (Kneeling)",
        width: 400,
        xaxis: {
          title: "",
          categories: ["Left", "Right", "Level"],
        },
        yaxis: {
          min: 0,
          max: 4,
          title: "",
        },
        annotations: {
          yaxis: [],
        },
        dataKeyChains: [
          "Primitive Reflexes:1::ATNR (Kneeling):Left",
          "Primitive Reflexes:1::ATNR (Kneeling):Right",
          "Primitive Reflexes:1::ATNR (Kneeling):Level",
        ],
      },
      {
        type: "columnBar",
        title: "STNR",
        width: 400,
        xaxis: {
          title: "",
          categories: ["Left", "Right", "Level"],
        },
        yaxis: {
          min: 0,
          max: 4,
          title: "",
        },
        annotations: {
          yaxis: [],
        },
        dataKeyChains: [
          "Primitive Reflexes:1::STNR:Left",
          "Primitive Reflexes:1::STNR:Right",
          "Primitive Reflexes:1::STNR:Level",
        ],
      },
      {
        type: "columnBar",
        title: "Spinal Galant",
        width: 400,
        xaxis: {
          title: "",
          categories: ["Left", "Right", "Level"],
        },
        yaxis: {
          min: 0,
          max: 4,
          title: "",
        },
        annotations: {
          yaxis: [],
        },
        dataKeyChains: [
          "Primitive Reflexes:1::Spinal Galant:Left",
          "Primitive Reflexes:1::Spinal Galant:Right",
          "Primitive Reflexes:1::Spinal Galant:Level",
        ],
      },
      {
        type: "columnBar",
        title: "Babinski",
        width: 400,
        xaxis: {
          title: "",
          categories: ["Left", "Right", "Level"],
        },
        yaxis: {
          min: 0,
          max: 4,
          title: "",
        },
        annotations: {
          yaxis: [],
        },
        dataKeyChains: [
          "Primitive Reflexes:1::Babinski:Left",
          "Primitive Reflexes:1::Babinski:Right",
          "Primitive Reflexes:1::Babinski:Level",
        ],
      },
      {
        type: "columnBar",
        title: "Palmer",
        width: 400,
        xaxis: {
          title: "",
          categories: ["Left", "Right", "Level"],
        },
        yaxis: {
          min: 0,
          max: 4,
          title: "",
        },
        annotations: {
          yaxis: [],
        },
        dataKeyChains: [
          "Primitive Reflexes:1::Palmer:Left",
          "Primitive Reflexes:1::Palmer:Right",
          "Primitive Reflexes:1::Palmer:Level",
        ],
      },
      {
        type: "columnBar",
        title: "Palmer",
        width: 400,
        xaxis: {
          title: "",
          categories: ["Left", "Right", "Level"],
        },
        yaxis: {
          min: 0,
          max: 4,
          title: "",
        },
        annotations: {
          yaxis: [],
        },
        dataKeyChains: [
          "Primitive Reflexes:1::Palmer:Left",
          "Primitive Reflexes:1::Palmer:Right",
          "Primitive Reflexes:1::Palmer:Level",
        ],
      },
      {
        type: "columnBar",
        title: "Snout",
        width: 400,
        xaxis: {
          title: "",
          categories: ["Level"],
        },
        yaxis: {
          min: 0,
          max: 4,
          title: "",
        },
        annotations: {
          yaxis: [],
        },
        dataKeyChains: ["Primitive Reflexes:1::Snout:Level"],
      },
      {
        type: "columnBar",
        title: "Moro (Head)",
        width: 400,
        xaxis: {
          title: "",
          categories: ["Level"],
        },
        yaxis: {
          min: 0,
          max: 4,
          title: "",
        },
        annotations: {
          yaxis: [],
        },
        dataKeyChains: ["Primitive Reflexes:1::Moro (Head):Level"],
      },
      {
        type: "columnBar",
        title: "Fear Paralysis",
        width: 400,
        xaxis: {
          title: "",
          categories: ["Level"],
        },
        yaxis: {
          min: 0,
          max: 4,
          title: "",
        },
        annotations: {
          yaxis: [],
        },
        dataKeyChains: ["Primitive Reflexes:1::Fear Paralysis:Level"],
      },
      {
        type: "columnBar",
        title: "TLR (Forward)",
        width: 400,
        xaxis: {
          title: "",
          categories: ["Level"],
        },
        yaxis: {
          min: 0,
          max: 4,
          title: "",
        },
        annotations: {
          yaxis: [],
        },
        dataKeyChains: ["Primitive Reflexes:1::TLR (Forward):Level"],
      },
    ],
  },
  {
    sectionName: "Core Stability",
    charts: [
      {
        type: "columnBar",
        title: "Supine",
        width: 400,
        xaxis: {
          title: "",
          categories: ["Level"],
        },
        yaxis: {
          min: 1,
          max: 5,
          title: "",
        },
        annotations: {
          yaxis: [],
        },
        dataKeyChains: ["Core Stability:1::Supine:Level"],
      },
      {
        type: "columnBar",
        title: "Supine",
        width: 400,
        xaxis: {
          title: "",
          categories: ["Seconds"],
        },
        yaxis: {
          min: 5,
          max: 60,
          title: "",
        },
        annotations: {
          yaxis: [],
        },
        dataKeyChains: ["Core Stability:1::Supine:Seconds"],
      },
      {
        type: "columnBar",
        title: "Prone",
        width: 400,
        xaxis: {
          title: "",
          categories: ["Level"],
        },
        yaxis: {
          min: 1,
          max: 5,
          title: "",
        },
        annotations: {
          yaxis: [],
        },
        dataKeyChains: ["Core Stability:1::Prone:Level"],
      },
      {
        type: "columnBar",
        title: "Prone",
        width: 400,
        xaxis: {
          title: "",
          categories: ["Seconds"],
        },
        yaxis: {
          min: 5,
          max: 60,
          title: "",
        },
        annotations: {
          yaxis: [],
        },
        dataKeyChains: ["Core Stability:1::Prone:Seconds"],
      },
      {
        type: "columnBar",
        title: "Lateral (Right)",
        width: 400,
        xaxis: {
          title: "",
          categories: ["Level"],
        },
        yaxis: {
          min: 1,
          max: 5,
          title: "",
        },
        annotations: {
          yaxis: [],
        },
        dataKeyChains: ["Core Stability:1::Lateral (Right):Level"],
      },
      {
        type: "columnBar",
        title: "Lateral (Right)",
        width: 400,
        xaxis: {
          title: "",
          categories: ["Seconds"],
        },
        yaxis: {
          min: 5,
          max: 60,
          title: "",
        },
        annotations: {
          yaxis: [],
        },
        dataKeyChains: ["Core Stability:1::Lateral (Right):Seconds"],
      },
      {
        type: "columnBar",
        title: "Lateral (Left)",
        width: 400,
        xaxis: {
          title: "",
          categories: ["Level"],
        },
        yaxis: {
          min: 1,
          max: 5,
          title: "",
        },
        annotations: {
          yaxis: [],
        },
        dataKeyChains: ["Core Stability:1::Lateral (Left):Level"],
      },
      {
        type: "columnBar",
        title: "Lateral (Left)",
        width: 400,
        xaxis: {
          title: "",
          categories: ["Seconds"],
        },
        yaxis: {
          min: 5,
          max: 60,
          title: "",
        },
        annotations: {
          yaxis: [],
        },
        dataKeyChains: ["Core Stability:1::Lateral (Left):Seconds"],
      },
      {
        type: "columnBar",
        title: "Push Up Plank",
        width: 400,
        xaxis: {
          title: "",
          categories: ["Level"],
        },
        yaxis: {
          min: 1,
          max: 5,
          title: "",
        },
        annotations: {
          yaxis: [],
        },
        dataKeyChains: ["Core Stability:1::Push Up Plank:Level"],
      },
      {
        type: "columnBar",
        title: "Push Up Plank",
        width: 400,
        xaxis: {
          title: "",
          categories: ["Seconds"],
        },
        yaxis: {
          min: 5,
          max: 60,
          title: "",
        },
        annotations: {
          yaxis: [],
        },
        dataKeyChains: ["Core Stability:1::Push Up Plank:Seconds"],
      },
      {
        type: "columnBar",
        title: "Sit Ups",
        width: 400,
        xaxis: {
          title: "",
          categories: ["Count"],
        },
        yaxis: {
          min: 0,
          max: 50,
          title: "",
        },
        annotations: {
          yaxis: [],
        },
        dataKeyChains: ["Core Stability:2::Sit Ups:Count"],
      },
      {
        type: "columnBar",
        title: "Hands Pulling",
        width: 400,
        xaxis: {
          title: "",
          categories: ["Count"],
        },
        yaxis: {
          min: 0,
          max: 50,
          title: "",
        },
        annotations: {
          yaxis: [],
        },
        dataKeyChains: ["Core Stability:2::Hands Pulling:Count"],
      },
      {
        type: "columnBar",
        title: "Push Ups",
        width: 400,
        xaxis: {
          title: "",
          categories: ["Count"],
        },
        yaxis: {
          min: 0,
          max: 50,
          title: "",
        },
        annotations: {
          yaxis: [],
        },
        dataKeyChains: ["Core Stability:2::Push Ups:Count"],
      },
      {
        type: "columnBar",
        title: "Pull Ups",
        width: 400,
        xaxis: {
          title: "",
          categories: ["Count"],
        },
        yaxis: {
          min: 0,
          max: 50,
          title: "",
        },
        annotations: {
          yaxis: [],
        },
        dataKeyChains: ["Core Stability:2::Pull Ups:Count"],
      },
      {
        type: "columnBar",
        title: "Curl Ups",
        width: 400,
        xaxis: {
          title: "",
          categories: ["Count"],
        },
        yaxis: {
          min: 0,
          max: 50,
          title: "",
        },
        annotations: {
          yaxis: [],
        },
        dataKeyChains: ["Core Stability:2::Curl Ups:Count"],
      },
      {
        type: "columnBar",
        title: "Wheelbarrow (Parachute Assist)",
        width: 400,
        xaxis: {
          title: "",
          categories: ["Steps"],
        },
        yaxis: {
          min: 0,
          max: 20,
          title: "",
        },
        annotations: {
          yaxis: [],
        },
        dataKeyChains: [
          "Core Stability:3::Wheelbarrow (Parachute Assist):Steps",
        ],
      },
    ],
  },
  {
    sectionName: "Flexion/Extension Ratio",
    charts: [
      {
        type: "radial",
        total: {
          label: "Seconds",
        },
        title: "Flexion",
        dataKeyChains: ["Flexion/Extension Ratio:1::Flexion:Seconds"],
      },
      {
        type: "radial",
        total: {
          label: "Seconds",
        },
        title: "Extension",
        dataKeyChains: ["Flexion/Extension Ratio:1::Extension:Seconds"],
      },
    ],
  },
  {
    sectionName: "Brachiation",
    charts: [
      {
        type: "radial",
        total: {
          label: "Seconds",
        },
        title: "Flexion",
        dataKeyChains: ["Brachiation:1::Hang from bar:Seconds"],
      },
      {
        type: "radial",
        total: {
          label: "Seconds",
        },
        title: "Extension",
        dataKeyChains: ["Brachiation:1::Extension:Seconds"],
      },
    ],
  },
  {
    sectionName: "One Leg Stand",
    charts: [
      {
        type: "columnBar",
        title: "Right Leg Down",
        width: 400,
        xaxis: {
          title: "",
          categories: ["Eyes Open", "Eyes Closed"],
        },
        yaxis: {
          min: 5,
          max: 60,
          title: "Seconds",
        },
        annotations: {
          yaxis: [],
        },
        dataKeyChains: [
          "One Leg Stand:1::Right Leg Down - Eyes Open:Seconds",
          "One Leg Stand:1::Right Leg Down - Eyes Closed:Seconds",
        ],
      },
      {
        type: "columnBar",
        title: "Left Leg Down",
        width: 400,
        xaxis: {
          title: "",
          categories: ["Eyes Open", "Eyes Closed"],
        },
        yaxis: {
          min: 5,
          max: 60,
          title: "Seconds",
        },
        annotations: {
          yaxis: [
            // {
            //   low: 42,
            //   high: undefined,
            //   label: "Mean",
            // },
          ],
        },
        dataKeyChains: [
          "One Leg Stand:1::Left Leg Down - Eyes Open:Seconds",
          "One Leg Stand:1::Left Leg Down - Eyes Closed:Seconds",
        ],
      },
    ],
  },
  {
    sectionName: "Fakuda Test",
    charts: [
      {
        type: "columnBar",
        title: "Linear Movement- Forward",
        width: 400,
        xaxis: {
          title: "",
          categories: ["Steps"],
        },
        yaxis: {
          min: 0,
          max: 10,
          title: "",
        },
        annotations: {
          yaxis: [],
        },
        dataKeyChains: ["Fakuda Test:1::Linear Movement- Forward:Steps"],
      },
      {
        type: "radial",
        total: {
          label: "Degrees",
        },
        title: "Rotate Right",
        dataKeyChains: ["Fakuda Test:2::Rotate Right:"],
      },
      {
        type: "radial",
        total: {
          label: "Degrees",
        },
        title: "Rotate Left",
        dataKeyChains: ["Fakuda Test:2::Rotate Left:"],
      },
    ],
  },
  {
    sectionName: "Post Rotatory Nystagmus (2 Hz Spin)",
    charts: [
      {
        type: "columnBar",
        title: "Right Spin",
        width: 400,
        xaxis: {
          title: "",
          categories: [""],
        },
        yaxis: {
          min: 0,
          max: 20,
          title: "Seconds",
        },
        annotations: {
          yaxis: [],
        },
        dataKeyChains: [
          "Post Rotatory Nystagmus (2 Hz Spin):1::Right Spin:Seconds",
        ],
      },
      {
        type: "columnBar",
        title: "Left Spin",
        width: 400,
        xaxis: {
          title: "",
          categories: [""],
        },
        yaxis: {
          min: 0,
          max: 20,
          title: "Seconds",
        },
        annotations: {
          yaxis: [],
        },
        dataKeyChains: [
          "Post Rotatory Nystagmus (2 Hz Spin):1::Left Spin:Seconds",
        ],
      },
    ],
  },
  {
    sectionName: "Postural Reflexes",
    charts: [
      {
        type: "columnBar",
        title: "Hands Pulling Reflex",
        width: 400,
        xaxis: {
          title: "",
          categories: [""],
        },
        yaxis: {
          min: 0,
          max: 4,
          title: "",
        },
        annotations: {
          yaxis: [],
        },
        dataKeyChains: ["Postural Reflexes:1::Hands Pulling Reflex:"],
      },
      {
        type: "columnBar",
        title: "Parachute",
        width: 400,
        xaxis: {
          title: "",
          categories: [""],
        },
        yaxis: {
          min: 0,
          max: 4,
          title: "",
        },
        annotations: {
          yaxis: [],
        },
        dataKeyChains: ["Postural Reflexes:1::Parachute:"],
      },

      {
        type: "columnBar",
        title: "Landau Reflex",
        width: 400,
        xaxis: {
          title: "",
          categories: [""],
        },
        yaxis: {
          min: 0,
          max: 4,
          title: "",
        },
        annotations: {
          yaxis: [],
        },
        dataKeyChains: ["Postural Reflexes:1::Landau Reflex:"],
      },
    ],
  },
  {
    sectionName: "Nystagmus Trails Needed",
    charts: [
      {
        type: "columnBar",
        title: "Right Spin",
        width: 400,
        xaxis: {
          title: "",
          categories: [""],
        },
        yaxis: {
          min: 0,
          max: 10,
          title: "",
        },
        annotations: {
          yaxis: [],
        },
        dataKeyChains: ["Nystagmus Trails Needed:1::Right Spin:"],
      },
      {
        type: "columnBar",
        title: "Left Spin",
        width: 400,
        xaxis: {
          title: "",
          categories: [""],
        },
        yaxis: {
          min: 0,
          max: 10,
          title: "",
        },
        annotations: {
          yaxis: [],
        },
        dataKeyChains: ["Nystagmus Trails Needed:1::Left Spin:"],
      },
    ],
  },
  {
    sectionName: "Beats In A Row",
    charts: [
      {
        type: "columnBar",
        title: "Horizontal Pursuit Right",
        width: 400,
        xaxis: {
          title: "",
          categories: [""],
        },
        yaxis: {
          min: 0,
          max: 10,
          title: "",
        },
        annotations: {
          yaxis: [],
        },
        dataKeyChains: ["Beats In A Row:1::Horizontal Pursuit Right:"],
      },
      {
        type: "columnBar",
        title: "Horizontal Pursuit Left",
        width: 400,
        xaxis: {
          title: "",
          categories: [""],
        },
        yaxis: {
          min: 0,
          max: 10,
          title: "",
        },
        annotations: {
          yaxis: [],
        },
        dataKeyChains: ["Beats In A Row:1::Horizontal Pursuit Left:"],
      },
      {
        type: "columnBar",
        title: "Horizontal Saccade Right",
        width: 400,
        xaxis: {
          title: "",
          categories: [""],
        },
        yaxis: {
          min: 0,
          max: 10,
          title: "",
        },
        annotations: {
          yaxis: [],
        },
        dataKeyChains: ["Beats In A Row:1::Horizontal Saccade Right:"],
      },
      {
        type: "columnBar",
        title: "Horizontal Saccade Left",
        width: 400,
        xaxis: {
          title: "",
          categories: [""],
        },
        yaxis: {
          min: 0,
          max: 10,
          title: "",
        },
        annotations: {
          yaxis: [],
        },
        dataKeyChains: ["Beats In A Row:1::Horizontal Saccade Left:"],
      },
      {
        type: "columnBar",
        title: "Vertical Pursuit Up",
        width: 400,
        xaxis: {
          title: "",
          categories: [""],
        },
        yaxis: {
          min: 0,
          max: 10,
          title: "",
        },
        annotations: {
          yaxis: [],
        },
        dataKeyChains: ["Beats In A Row:1::Vertical Pursuit Up:"],
      },
      {
        type: "columnBar",
        title: "Vertical Pursuit Down",
        width: 400,
        xaxis: {
          title: "",
          categories: [""],
        },
        yaxis: {
          min: 0,
          max: 10,
          title: "",
        },
        annotations: {
          yaxis: [],
        },
        dataKeyChains: ["Beats In A Row:1::Vertical Pursuit Down:"],
      },
      {
        type: "columnBar",
        title: "Vertical Saccade Up",
        width: 400,
        xaxis: {
          title: "",
          categories: [""],
        },
        yaxis: {
          min: 0,
          max: 10,
          title: "",
        },
        annotations: {
          yaxis: [],
        },
        dataKeyChains: ["Beats In A Row:1::Vertical Saccade Up:"],
      },
      {
        type: "columnBar",
        title: "Vertical Saccade Down",
        width: 400,
        xaxis: {
          title: "",
          categories: [""],
        },
        yaxis: {
          min: 0,
          max: 10,
          title: "",
        },
        annotations: {
          yaxis: [],
        },
        dataKeyChains: ["Beats In A Row:1::Vertical Saccade Down:"],
      },
    ],
  },
  {
    sectionName: "Autonomic Tone / Balance",
    charts: [
      {
        type: "columnBar",
        title: "Pulse Rate",
        width: 400,
        xaxis: {
          title: "",
          categories: ["Left", "Right"],
        },
        yaxis: {
          min: 60,
          max: 140,
          title: "BPM",
        },
        annotations: {
          yaxis: [],
        },
        dataKeyChains: [
          "Autonomic Tone/Balance:3:Pulse Rate:Left:Measure",
          "Autonomic Tone/Balance:3:Pulse Rate:Right:Measure",
        ],
      },
    ],
  },
  {
    sectionName: "Oxygenation",
    charts: [
      {
        type: "columnBar",
        title: "",
        width: 400,
        xaxis: {
          title: "",
          categories: ["Right", "Left"],
        },
        yaxis: {
          min: 90,
          max: 100,
          title: "%",
        },
        annotations: {
          yaxis: [],
        },
        dataKeyChains: [
          "Oxygenation:1::Right:Percentage",
          "Oxygenation:1::Left:Percentage",
        ],
      },
    ],
  },
  {
    sectionName: "Respiration",
    charts: [
      {
        type: "columnBar",
        title: "",
        width: 400,
        xaxis: {
          title: "",
          categories: ["Respiration Breathes per minute"],
        },
        yaxis: {
          min: 5,
          max: 60,
          title: "",
        },
        annotations: {
          yaxis: [],
        },
        dataKeyChains: ["Respiration:4::Respiration Breathes:Measure"],
      },
    ],
  },
];

export default DOCTOR_ASMT_CHART_PROPS;
