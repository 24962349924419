import axios from "axios";
import Cookies from "universal-cookie";
import Authenticator from "services/Authenticator.js";

const cookies = new Cookies();

const HTTP = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

const HTTP_UN_INTERCEPTED = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

HTTP.interceptors.request.use(
  (config) => {
    if (cookies.get("access_token") !== undefined) {
      config.headers.Authorization = "Bearer " + cookies.get("access_token");
    }
    config.headers["Organization"] = cookies.get("organization");
    return config;
  },
  (error) => {
    console.log("Interceptor Request Error" + error);
  }
);

// This allows you to intercept the response and check the status and error messages and if necessary reject the promise.
HTTP.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    if (error.response.status === 401 || error.response.status === 403) {
      Authenticator.logout();
    } else {
      return Promise.reject(error);
    }
  }
);

export { HTTP, HTTP_UN_INTERCEPTED };
