import ApiBaseService from "services/ApiBaseService.js";

class SchedulingService {
  static getAppointmentEventsByHospital([
    hospitalId,
    isUpcoming,
    isPast,
    page,
    size,
  ]) {
    return ApiBaseService.request(
      `/hospitals/${hospitalId}/schedule/appointments/events?upcoming=${isUpcoming}&past=${isPast}&page=${page}&size=${size}`,
      "GET",
      null
    );
  }

  static fetchAppointment([hospitalId, appointmentId]) {
    return ApiBaseService.request(
      `/hospitals/${hospitalId}/schedule/appointments/${appointmentId}`,
      "GET",
      null
    );
  }

  static fetchAppointmentEvent([hospitalId, appointmentId, calendarEventId]) {
    return ApiBaseService.request(
      `/hospitals/${hospitalId}/schedule/appointments/${appointmentId}/events/${calendarEventId}`,
      "GET",
      null
    );
  }

  static createAppointment([hospitalId, data]) {
    return ApiBaseService.request(
      `/hospitals/${hospitalId}/schedule/appointments`,
      "POST",
      data
    );
  }

  static getEventsByHospital([
    hospitalId,
    startRange,
    endRange,
    doctorId,
    patientId,
  ]) {
    return ApiBaseService.request(
      `/hospitals/${hospitalId}/schedule/events?startRange=${startRange}&endRange=${endRange}&doctorId=${doctorId}&patientId=${patientId}`,
      "GET",
      null
    );
  }

  static getEvents([hospitalId, eventId]) {
    return ApiBaseService.request(
      `/hospitals/${hospitalId}/schedule/events/${eventId}`,
      "GET",
      null
    );
  }

  static createEvent([hospitalId, data]) {
    return ApiBaseService.request(
      `/hospitals/${hospitalId}/schedule/events`,
      "POST",
      data
    );
  }

  static updateEvent([hospitalId, eventId, data]) {
    return ApiBaseService.request(
      `/hospitals/${hospitalId}/schedule/events/${eventId}`,
      "PUT",
      data
    );
  }

  static cancelEvent([hospitalId, eventId, cancelTypeEnum]) {
    return ApiBaseService.request(
      `/hospitals/${hospitalId}/schedule/events/${eventId}/cancel?cancelType=${cancelTypeEnum}`,
      "POST",
      null
    );
  }
}

export default SchedulingService;
