import Paper from "@mui/material/Paper";
import Tabs from "components/utils/Tabs.js";
import { default as TimeMUI } from '@mui/lab/Timeline';
import styles from "styles/patients.module.css";
import * as React from 'react';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import {
  timelineOppositeContentClasses,
} from '@mui/lab/TimelineOppositeContent';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Button from '@mui/material/Button';

function Timeline() {
  const tabItemNames = ["Appointments", "Daily Register", "Payments"];
  const tabViews = [<Appointments />, <DailyRegister />, <Payments />];

  return (
    <div>
      <Tabs itemNames={tabItemNames} views={tabViews} />
    </div>
  );
}

function Appointments() {

  return (
    <Paper elevation={0} >
      <div className="timeline-header">
        <h5>Upcoming Appointments</h5>
        <h5>Previous Appointments</h5>
      </div>
      <div className="timeline-body">
        <div className="future-timeline">
          {timelineDataFuture.map((el, i) => <React.Fragment key={i}>
            <div className="flex-row">
              <TimeMUI
                sx={{
                  [`& .${timelineOppositeContentClasses.root}`]: {
                    flex: 0,
                  },
                }}
              >
                <TimelineItem>
                  <TimelineSeparator id={styles["timeline-patient"]}>
                    <TimelineConnector />
                    <TimelineDot />
                    <TimelineConnector />
                  </TimelineSeparator >
                  <TimelineContent></TimelineContent>
                </TimelineItem>
              </TimeMUI>
              <div className="card">
                <div className="card-header">
                  <span className="bold">{el.date}</span>
                  <div><span>{el.time}</span>
                    <MoreVertIcon></MoreVertIcon></div>
                </div>
                <div className="card-body">
                  <span>{el.note}</span>
                </div>
                <div className="card-footer">
                  <Button variant="contained" className="btn-danger-sm" size="small">Not Visited</Button>
                  <Button variant="contained" className="btn-warn-sm" size="small">Make Bill</Button>
                </div>
              </div>
            </div>
          </React.Fragment>
          )}
        </div>
        <div className="past-timeline">
          {timelineDataPast.map((el, i) => <React.Fragment key={i}>
            <div className="flex-row">
              <TimeMUI
                sx={{
                  [`& .${timelineOppositeContentClasses.root}`]: {
                    flex: 0,
                  },
                }}
              >
                <TimelineItem>
                  <TimelineSeparator id={styles["timeline-patient"]}>
                    <TimelineConnector />
                    <TimelineDot />
                    <TimelineConnector />
                  </TimelineSeparator >
                  <TimelineContent></TimelineContent>
                </TimelineItem>
              </TimeMUI>
              <div className="card card-past">
                <div className="card-header">
                  <span className="bold">{el.date}</span>
                  <div><span>{el.time}</span>
                  </div>
                </div>
                <div className="card-body">
                  <span>{el.note}</span>
                </div>
                <div className="card-footer">
                  <Button variant="contained" className="btn-danger-sm" size="small">Not Visited</Button>
                  <Button variant="contained" className="btn-warn-sm" size="small">Make Bill</Button>
                </div>
              </div>
            </div>
          </React.Fragment>
          )}
        </div>
      </div>
    </Paper>
  );
}

function DailyRegister() {
  return (
    <Paper elevation={0}>
      <>2</>
    </Paper>
  );
}

function Payments() {
  return (
    <Paper elevation={0}>
      <>3</>
    </Paper>
  );
}

const timelineDataFuture = [
  {
    date: '06 July',
    time: '14:30-15:30',
    note: 'Consultant 867/898: Natalie'
  },
  {
    date: '16 July',
    time: '14:30-15:30',
    note: 'Consultant 867/898: Natalie'
  },
  {
    date: '26 July',
    time: '14:30-15:30',
    note: 'Consultant 867/898: Natalie'
  },
]
const timelineDataPast = [{
  date: '06 July',
  time: '14:30-15:30',
  note: 'Consultant 867/898: Natalie'
},
{
  date: '06 July',
  time: '14:30-15:30',
  note: 'Consultant 867/898: Natalie'
}]
export default Timeline;
