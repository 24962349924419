import { useState } from "react";
import Card from "react-bootstrap/Card";
import "styles/add-family.scss";
import IconButton from "@mui/material/IconButton";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import { useNavigate, Outlet } from "react-router-dom";
import FamilyService from "services/FamilyService.js";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import { useTheme } from "@mui/material/styles";
import useAPI from "hooks/useAPI.js";

const options = ["Edit", "Documents"];

export function Family(props) {
  return <Outlet />;
}

export function FamilyMembers() {
  let navigate = useNavigate();
  const theme = useTheme();
  const [familyMembers, setFamilyMembers] = useState([]);

  const getMembersAPI = useAPI(
    FamilyService.getMembers,
    getMembersHandler,
    false,
    true
  );

  function getMembersHandler(response) {
    let data = response.data;
    for (let member of data) {
      setFamilyMembers((prevState) => [...prevState, member]);
    }
  }

  return (
    <>
      <div className="ms-4">
        <Typography variant="h1" color={theme.palette.text.secondary}>
          My Family Members
        </Typography>
        <Typography
          variant="body1"
          color={theme.palette.text.main}
          className="mt-2"
        >
          Add patient (<b>self or family member</b>) profile under this section
          to register at the centre
        </Typography>
      </div>
      <div>
        {getMembersAPI.status === "pending" ? (
          <div className="text-center">
            <CircularProgress
              className="text-center"
              sx={{
                mt: 50,
              }}
            />
          </div>
        ) : (
          <div className="family-container">
            {familyMembers.map((member, i) => {
              return <FamilyMemberCard key={i} member={member} />;
            })}
            <AddFamilyMemberCard clickHandler={() => navigate("add-member")} />
          </div>
        )}
      </div>
    </>
  );
}

function AddFamilyMemberCard({ clickHandler, name }) {
  return (
    <>
      <div className="add-family-container">
        <Card className="card" onClick={clickHandler}>
          <Card.Body>
            <Card.Link>
              <div className="add-family-span">
                <span className="Add">+</span>
                <span>Add Family Member</span>
              </div>
            </Card.Link>
          </Card.Body>
        </Card>
      </div>
    </>
  );
}

function FamilyMemberCard({ member }) {
  let navigate = useNavigate();
  const [anchorFamilyDropdownCard, setAnchorFamilyDropdownCard] =
    useState(null);
  const cardDropdownOpen = Boolean(anchorFamilyDropdownCard);
  const handleDropdownClick = (event) => {
    setAnchorFamilyDropdownCard(event.currentTarget);
  };
  const handleDropdownClose = (param) => {
    setAnchorFamilyDropdownCard(null);
    if (param === "Edit") {
      navigate("edit-member/" + member.id);
    }
    if (param === "Documents") {
      navigate("member-documents/" + member.id);
    }
  };
  return (
    <Card className="card family-card">
      {member.isArchived ? (
        <h6 className="family-card-overlay-text">
          Archived
          <Tooltip title="This patient profile has been archived. Contact the centre for more information.">
            <InfoOutlinedIcon fontSize="inherit" color="custom.light" />
          </Tooltip>
        </h6>
      ) : null}
      <Card.Body>
        {!member.isArchived ? (
          <Card.Text>
            <IconButton
              aria-label="more"
              id="family-action-btn"
              aria-controls={"family-action-menu"}
              aria-expanded={"true"}
              aria-haspopup="true"
              onClick={handleDropdownClick}
            >
              <img
                src="assets/kebab-grey-icon.svg"
                alt="Family action menu icon"
              />
            </IconButton>
            <Menu
              id="family-action-menu"
              MenuListProps={{
                "aria-labelledby": "family-action-btn",
              }}
              anchorEl={anchorFamilyDropdownCard}
              open={cardDropdownOpen}
              onClose={handleDropdownClose}
              PaperProps={{
                style: {
                  maxHeight: 48 * 4.5,
                  width: "20ch",
                },
              }}
            >
              {options.map((option) => (
                <MenuItem
                  key={option}
                  selected={option === "Pyxis"}
                  onClick={() => handleDropdownClose(option)}
                >
                  {option}
                </MenuItem>
              ))}
            </Menu>
          </Card.Text>
        ) : null}
        <Card.Link>
          <div className="family-card-body">
            <div>{`${member.firstName} ${member.lastName}`}</div>
            <div>{member.dob}</div>
          </div>
        </Card.Link>
      </Card.Body>
    </Card>
  );
}
