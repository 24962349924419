import { useEffect } from "react";
import ReactGA from "react-ga4";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import RequireAuth from "components/RequireAuth.js";
import PageNotFound from "views/PageNotFound";

//Public Imports
import Home from "views/public/Home.js";
import AccountContainer from "views/public/AccountContainer";
import Login from "views/public/Login.js";
import PatientSignup from "views/public/PatientSignup.js";
import ResetPassword from "views/public/ResetPassword.js";

// Hosipital Imports
import HospitalHome from "views/hospital/HospitalHome.js";
import Dashboard from "views/hospital/Dashboard.js";
import EMRManagement from "views/hospital/EMRManagement.js";
import { Patients, PatientList } from "views/hospital/patient/Patients.js";
import PatientDetail from "views/hospital/patient/PatientDetail.js";
import AddPatient from "views/hospital/patient/AddPatient.js";

import PatientProfile from "views/hospital/patient/PatientProfile.js";
import SoapNotes from "views/hospital/patient/SoapNotes.js";
import PatientConsent from "views/hospital/patient/PatientConsent.js";
import Calendar from "views/hospital/scheduling/Calendar.js";
import Timeline from "views/hospital/patient/Timeline.js";
import Assessments from "views/hospital/patient/Assessments.js";
import AssessmentAnalysis from "views/hospital/patient/AssessmentAnalysis";
import Appointments from "views/hospital/scheduling/Appointments.js";
import {
  Enrollments,
  EnrollmentList,
} from "views/hospital/patient/enrollment/Enrollments.js";
import {
  EnrollmentSessions,
  EnrollmentSessionList,
} from "views/hospital/patient/enrollment/EnrollmentSessions.js";
import SessionTherapies from "views/hospital/patient/enrollment/SessionTherapies.js";
import ScheduleManagement from "views/hospital/ScheduleManagement.js";
import TermsOfService from "views/hospital/TermsOfService";
import PrivacyPolicy from "views/hospital/PrivacyPolicy";

// Patient Imports
import { Family, FamilyMembers } from "views/patient/family/Family";
import AddFamilyMember from "views/patient/family/AddFamilyMember";
import EditFamilyMember from "views/patient/family/EditFamilyMember";
import FamilyMemberDocs from "views/patient/family/FamilyMemberDocs";
import PatientHome from "views/patient/PatientHome";
import PatientDashboard from "views/patient/PatientDashboard";
import RegistrationForms from "views/patient/RegistrationForms";
import PatientFormsList from "views/patient/PatientFormsList";

// User Imports
import UserHome from "views/user/UserHome.js";
import Profile from "views/user/Profile.js";
import Settings from "views/user/Settings.js";
import ChangePassword from "views/user/ChangePassword.js";

// Admin Imports
import AdminHome from "views/admin/AdminHome.js";
import OrganizationAdmin from "views/admin/OrganizationAdmin.js";
import UsersManagement from "views/admin/UsersManagement.js";

function AppRoutes() {
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname + location.search,
    });
  }, [location]);

  return (
    <Routes>
      {/* --- Public Routes ---- */}
      <Route path="/" element={<Home />}>
        {/*  Remove below route later */}
        <Route
          path="signup/patient"
          element={<Navigate to="/account/signup/patient" replace />}
        />
        <Route path="account" element={<AccountContainer />}>
          <Route index element={<Login />} />
          <Route path="signup/patient" element={<PatientSignup />} />
          <Route path="reset-password" element={<ResetPassword />} />
        </Route>
        <Route path="terms-of-service" element={<TermsOfService />} />
        <Route path="privacy-policy" element={<PrivacyPolicy />} />
      </Route>

      {/* --- Hospital Routes ---- */}
      <Route
        path="/hospital"
        element={
          <RequireAuth routeType="hospital">
            <HospitalHome />
          </RequireAuth>
        }
      >
        <Route index element={<Dashboard />} />
        {/* <Route path="about" element={<HospitalHome />} /> */}
        <Route path="emr-management" element={<EMRManagement />}>
          <Route path="patients" element={<Patients />}>
            <Route index element={<PatientList />} />
            <Route path="add-patient" element={<AddPatient />} />
            <Route path=":patientId" element={<PatientDetail />}>
              <Route index path="profile" element={<PatientProfile />} />
              <Route path="notes" element={<SoapNotes />} />
              <Route path="consent-form" element={<PatientConsent />} />
              <Route path="timeline" element={<Timeline />} />
              <Route path="assessments">
                <Route index element={<Assessments />} />
              </Route>
              <Route path="enrollments" element={<Enrollments />}>
                <Route index element={<EnrollmentList />} />
                <Route
                  path=":enrollmentId/sessions"
                  element={<EnrollmentSessions />}
                >
                  <Route index element={<EnrollmentSessionList />} />
                  <Route path=":sessionId" element={<SessionTherapies />} />
                </Route>
              </Route>
            </Route>
          </Route>
        </Route>
        <Route path="schedule-management" element={<ScheduleManagement />}>
          <Route path="calendar" element={<Calendar />}></Route>
          <Route path="appointments" element={<Appointments />}></Route>
        </Route>
        <Route path="assessment-analysis" element={<AssessmentAnalysis />} />
      </Route>

      {/* ---- Patient Routes ---- */}
      <Route
        path="/patient"
        element={
          <RequireAuth routeType="patient">
            <PatientHome />
          </RequireAuth>
        }
      >
        <Route index element={<PatientDashboard />} />
        <Route path="family" element={<Family />}>
          <Route index element={<FamilyMembers />} />
          <Route path="add-member" element={<AddFamilyMember />} />
          <Route path="edit-member/:patientId" element={<EditFamilyMember />} />
          <Route
            path="member-documents/:patientId"
            element={<FamilyMemberDocs />}
          />
        </Route>
        <Route path="assessments-and-forms" element={<RegistrationForms />} />
        <Route
          path="assessments-and-forms/:patientId"
          element={<PatientFormsList />}
        />
      </Route>

      {/* --- User Routes ---- */}
      <Route
        path="/user"
        element={
          <RequireAuth routeType="user">
            <UserHome />
          </RequireAuth>
        }
      >
        <Route path="profile" element={<Profile />} />
        <Route path="settings" element={<Settings />}>
          <Route path="change-password" element={<ChangePassword />} />
        </Route>
      </Route>

      {/* --- Admin Routes ---- */}
      <Route
        path="/admin"
        element={
          <RequireAuth routeType="admin">
            <AdminHome />
          </RequireAuth>
        }
      >
        <Route path="organization" element={<OrganizationAdmin />}>
          <Route path="users" element={<UsersManagement />} />
        </Route>
      </Route>

      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
}

export default AppRoutes;
