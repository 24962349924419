import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import Divider from "@mui/material/Divider";

function PrivacyPolicy() {
  const theme = useTheme();
  return (
    <div className="container">
      <Typography variant="h1" color={theme.palette.text.secondary}>
        Privacy Policy
      </Typography>
      <Divider className="mt-2" />
      <div>
        <i>Effective date: February 1, 2023</i>
        <br></br>
        <br></br>
        This Privacy Policy ("Policy") lets you know how ACSESTECH LLC
        (“ACSESTECH”, “We, “Us”) collects and uses your Personal Information,
        how you can control its use, and describes our practices regarding
        information collected from our website, computer or mobile applications,
        and social media pages and HTML-formatted e-mail messages (“Site” or
        "Services").
        <br></br>
        <br></br>
        Before you submit any information on or through the ACSESTECH or Site or
        account portal or use ACSESTECH products or services, please carefully
        review this policy. You acknowledge and understand that, by using any
        part of the ACSESTECH Site or services, your continued use of the
        ACSESTECH or Site, and/or continued provision of information to us will
        be subject to the terms of the then-current Privacy Policy. When we say
        “You” we are referring to any user who views our website, that uses our
        Services or that downloads our app.
        <br></br>
        <br></br>
        This policy applies to information we collect:
        <ul>
          <li>On this Website and Services.</li>
          <li>
            In e-mail, text and other electronic messages between you and this
            Website.
          </li>
          <li>Through our Mobile App and Web Based Software.</li>
          <li>
            When you interact with our advertising and applications on
            third-party websites and services, if those applications or
            advertising include links to this policy.
          </li>
        </ul>
        By accessing or using the Site and/or Services, you agree that you have
        read, understood and agree to our collection, storage, use and
        disclosure of your personal information as described in this Privacy
        Policy and our <a href="terms-of-service">Terms of Service</a>
        <br></br>
        <br></br>
        Information for California consumers can be found in section 10
        “California Consumer Privacy Act.”<br></br>
        We use cookies to collect data automatically about your use of our
        website or for advertising. <br></br>
        We can share the data we collect from you with those outside of
        ACSESTECH if they are a service provider for ACSESTECH (and have to
        follow our requirements) or if we’re legally required to share it with
        those outside of ACSESTECH.<br></br>
        <br></br>
        This Policy does not apply to information collected by:
        <ul>
          <li>
            us offline or through any other means, including on any other
            website operated by Us or any third party (including our affiliates
            and subsidiaries); or
          </li>
          <li>
            any third party (including our affiliates and subsidiaries),
            including through any application or content (including advertising)
            that may link to or be accessible from or on the Website.
          </li>
        </ul>
        We do not participate in the E.U. - U.S. and Swiss - U.S. Privacy Shield
        Frameworks and do not comply with the Privacy Shield Prin
        <br></br>
        <br></br>
        We at ACSESTECH know you care about how your personal information is
        used and shared, and we take your privacy seriously. Please read the
        following to learn more about our Privacy Policy. By using or accessing
        the Services in any manner, you acknowledge that you accept the
        practices and policies outlined in this Privacy Policy, and you hereby
        authorize ACSESTECH to collect, use, and share your information for the
        purposes identified herein and on the Site.
        <br></br>
        <br></br>
        Remember that your use of ACSESTECH’s Services is at all times subject
        to the Terms of Service, which incorporates this Privacy Policy. Any
        terms we use in this Policy without defining them have the definitions
        given to them in the Terms of Service.
        <br></br>
        <br></br>The Services may provide links to or the ability to connect
        with non-ACSESTECH websites, services, social networks or applications.
        Clicking on those links or enabling those connections might allow the
        third party to collect or share information about you. Those third-party
        websites or services are beyond ACSESTECH ’s control. ACSESTECH advises
        you to check the privacy policies and terms of use of any non-ACSESTECH
        websites or services before providing any of your Personal Information
        to them.
        <br></br>
        <br></br>As noted in the Terms of Service, we do not knowingly collect
        or solicit personal information from anyone under the age of 18. If you
        are under 18, please do not attempt to register for the Services or send
        any personal information about yourself to us. If we learn that we have
        collected personal information from anyone under age 18, we will delete
        that information as quickly as possible. If you believe that anyone
        under the age of 18 may have provided us personal information, please
        contact us at: <i>SWAPNA.ATMAKURI@ACSESTECH.COM</i>
        <br></br>
        <ol>
          <br></br>
          <li>
            <b>SCOPE</b>
            <br></br>
            This Privacy Policy is effective with respect to any data that we
            have collected, or collect, about and/or from you, according to our
            Terms of Service.
          </li>
          <br></br>
          <li>
            <b>WHAT DOES THIS PRIVACY POLICY COVER?</b>
            <br></br>This Privacy Policy covers our treatment of personally
            identifiable information ("Personal Information") that we gather
            when you are accessing or using our Services, but not to the
            practices of companies we don’t own or control, or people that we
            don’t manage such as advertisers and third parties that help Us
            maintain and improve our Services. We gather various types of
            Personal Information from our users, as explained in more detail
            below, and we use this Personal Information internally in connection
            with our Services, including to personalize, provide, and improve
            our services, to allow you to set up a user account and profile, to
            contact you and allow other users to contact you, to fulfill your
            requests for certain products and services, and to analyze how you
            use the Services. In certain cases, we may also share some Personal
            Information with third parties, but only as described below.
            <br></br>
            <br></br>As noted in the Terms of Service, we do not knowingly
            collect or solicit personal information from anyone under the age of
            18. If you are under 18, please do not attempt to register for the
            Services or send any personal information about yourself to us. If
            we learn that we have collected personal information from anyone
            under age 18, we will delete that information as quickly as
            possible. If you believe that a child under the age of 18 may have
            provided us personal information, please contact us at:
            SWAPNA.ATMAKURI@ACSESTECH.COM
          </li>
          <br></br>
          <li>
            <b>Will ACSESTECH ever change this Privacy Policy?</b>
            <br></br>We’re constantly trying to improve our Services, so we may
            need to change this Privacy Policy from time to time as well, but we
            will alert you to changes by placing a notice in the app, by sending
            you an email, and/or by some other means. Please note that if you’ve
            opted not to receive legal notice emails from us (or you haven’t
            provided us with your email address), those legal notices will still
            govern your use of the Services, and you are still responsible for
            reading and understanding them. If you use the Services after any
            changes to the Privacy Policy have been posted, that means you agree
            to all of the changes
          </li>
          <br></br>
          <li>
            <b>What Information does ACSESTECH Collect?</b>
            <br></br>
            <br></br> (A) Information You Voluntarily Provide to Us:<br></br>
            <br></br>
            When you sign up for by means of the registration process and use
            the Services, or consult with a member of our customer service team,
            send us an email or communicate in any manner with us, you are
            knowingly and voluntarily providing us information that we collect.
            That information might include either your name, physical address,
            e-mail address, IP address, phone number, as well as other
            information including occupation, location, and other information.
            By giving us this information, you consent to this information being
            collected, used, or disclosed to us in the United States and stored
            by us, as set forth in our Terms of Service and in this Privacy
            Policy. If you provide third-party account credentials to us or
            otherwise sign in to the Services through a third party site or
            service, such as Facebook you understand some content and/or
            information in those accounts (“Third Party Account Information”)
            may be transmitted into your account with us, and that Third Party
            Account Information transmitted to our Services is covered by this
            Privacy Policy. Certain information may be required to register with
            us or to take advantage of some of our features. We may communicate
            with you if you’ve provided us the means to do so. For example, if
            you’ve given us your email address, we may send you promotional
            email offers on behalf of other businesses, or email you about your
            use of the Services. Also, we may receive a confirmation when you
            open an email from us. This confirmation helps us make our
            communications with you more interesting and improve our services.
            If you do not want to receive communications from us, please
            indicate your preference by unsubscribing at the bottom of the email
            and/or through the settings menu in the application if the option is
            available.
            <br></br>
            <br></br>
            (B) What Information do we collect automatically: <br></br>
            <br></br>
            When you use our Services or browse one of our Websites, we might
            collect information about your visit to our Websites, your usage of
            the Services, and your web browsing. That information might include
            your operating system, your browser ID, IP address, your browsing
            activity, and other information related to how you interacted with
            our Websites or other websites. We might collect this information as
            a part of log files as well as through the use of cookies or other
            tracking technologies. “Cookies” are identifiers we transfer to your
            browser or device that allow ACSESTECH to recognize your browser or
            device and tell us how and when pages and features in our Services
            are visited and by how many people. You may be able to change the
            preferences on your browser or device to prevent or limit your
            device’s acceptance of cookies, but this may prevent you from taking
            advantage of some of our features. Our use of cookies is discussed
            more below. We do not usually link any IP addresses to anything that
            can be personally identifiable. This means that your Internet
            session will be logged, but you will remain anonymous to us. We will
            seek to link your IP address when we deem it necessary to protect
            the Website or App and other Users from harm and to prevent criminal
            misconduct.
            <br></br>
            <br></br>(C) Information from your use of the Services: <br></br>
            <br></br>
            We may receive information about how and when you have used our
            Services, store it in log files or other types of files associated
            with your account, and link it to other information we collect about
            you. This information might include, for example, your IP address,
            time, date, browser used, and actions you have taken within the
            application. This type of information helps us to improve our
            Services for both you and for all of our users.
            <br></br>
            <br></br>(D) Cookies and tracking: <br></br>
            <br></br>
            We and our partners may use cookies or similar technologies to
            analyze trends, administer the website, track users’ movements
            around the website, and to gather demographic information about our
            user base as a whole. Users can control the use of cookies at the
            individual browser level. We may partner with a third party to
            either display advertising on our website or to manage our
            advertising on other sites. Our third party partners may use cookies
            or similar technologies in order to provide you advertising or other
            content based upon your browsing activities and interests. We might
            use this data gathered by cookies or similar technologies to
            customize content for you that we think you might like, based on
            your usage patterns. We may also use it to improve the Services. If
            you click on a link to a third party website or service, a third
            party may also transmit cookies to you. Be advised that this Privacy
            Policy does not cover the use of cookies by any third parties, and
            we are not responsible or liable in any manner for their privacy
            policies and practices. Please be aware that cookies transmitted by
            third parties might continue to track your activities online even
            after you have left our Services, and those third parties may not
            honor “Do Not Track” requests you have set using your browser or
            device.
            <br></br>
            <br></br>
            (E) Web Beacons: <br></br>
            <br></br>
            Web pages might contain an electronic file which is called a web
            beacon, that allows a website to count the number of users who have
            visited that page or to access certain cookies. <br></br>
            <br></br>We might use web beacons in the following ways:<br></br>
            <br></br>(i) Within the ACSESTECH Websites<br></br>
            <ul>
              <li>
                We may use web beacons within any of the ACSESTECH websites so
                as to count users and to recognize users by accessing ACSESTECH
                cookies.
              </li>
              <li>
                Being able to access ACSESTECH cookies allows us to personalize
                your experience when you visit the ACSESTECH websites that are
                located both on and off of the ACSESTECH website domains.
              </li>
            </ul>
            <br></br>(ii) Outside of the ACSESTECH Websites<br></br>
            <ul>
              <li>
                ACSESTECH may use web beacons to conduct research on behalf of
                certain partners on their websites and also for auditing
                purposes.
              </li>
              Information recorded through these Web beacons is used to report
              anonymous individual and/or aggregate information about ACSESTECH
              users to our partners. Aggregate information may include
              demographic and usage information. Be advised that no personally
              identifiable information about you is shared with partners from
              this research.
              <li>
                Be advised that this opt-out applies to a specific browser
                rather than a specific user. Therefore you will have to opt-out
                separately from each computer or browser that you use.
              </li>
            </ul>
            <br></br>(iii) HTML Mail<br></br>
            <ul>
              <li>
                ACSESTECH might include web beacons in HTML-formatted email
                messages that ACSESTECH, or its agents, or affiliates send so as
                to determine what specific email messages were opened and to
                note whether a message was acted upon. Be advised that typically
                any file served as part of a web page, including an ad banner,
                can act as a web beacon. ACSESTECH may also include web beacons
                from third parties and affiliates within pages served by
                ACSESTECH so that ACSESTECH's advertisers might receive
                auditing, research and reporting.
              </li>
            </ul>
            <br></br>(F) Information from other sources: <br></br>
            <br></br>
            We may receive more information about you such as your name, email
            address, demographic information, IP addresses, location, and use of
            social media websites, by searching the Internet or querying third
            parties. We may communicate with you if you’ve provided us the means
            to do so. For example, if you’ve given us your email address, we may
            send you promotional email offers on behalf of other businesses, or
            email you about your use of the Services. Also, we may receive a
            confirmation when you open an email from us. This confirmation helps
            us make our communications with you more interesting and improve our
            services. If you do not want to receive communications from us,
            please indicate your preference by unsubscribing at the bottom of
            the email and or through the settings menu in the application if the
            option is available.
          </li>
          <br></br>
          <li>
            <b>Do Not Track Policy</b>
            <br></br>
            <br></br>
            Your browser may offer you a “Do Not Track” option, which allows you
            to signal to operators of websites and web applications and services
            that you do not want those operators to track some of your online
            activities and over different websites. Our Services do not support
            Do Not Track requests at this time.
          </li>
          <br></br>
          <li>
            <b>Use and Disclosure of Personal Information</b>
            <br></br>
            <br></br>
            We might use and disclose Personal Information only for the
            following purposes:<br></br>
            <br></br>(a) To send you informational and promotional content that
            you may choose (or “opt in”) to receive. You can stop receiving our
            promotional emails by following the unsubscribe instructions that
            are included in every email.
            <br></br>(b) For promotional use of our Services to you.
            Specifically, if you provide your Personal Information when you use
            our App or visit our Website and decide not to sign up for any of
            our Services, we might send you an email asking you to sign up for
            some of our Services. If you use any of our Services and we believe
            you might benefit from using another Service we offer, we might send
            you an email about that specific offer. You can stop receiving our
            promotional emails by following the unsubscribe instructions that we
            include in every email we send to you. In addition, we might use
            information we collect in order to advertise our Services to you or
            suggest additional features of our Services that you might want to
            use.
            <br></br>(c) Be advised that if we collect payment from you of any
            kind that we will utilize the services of a third party payment
            processor to facilitate your payment by credit card. We do not store
            any credit card and/or financial information on our servers. Your
            financial information will be stored on the servers of Stripe. See
            Stripe’s Privacy Policy. https://stripe.com/privacy. We will only
            use your credit card and/or financial information for all secure
            payment transaction processing. We do store a record of the payment
            transaction, including the last 4 digits of the credit card number,
            for account history, invoicing, and billing support. We also store
            your billing address to calculate any sales tax due in the United
            States, to maintain records that may be used for investigations of
            potentially fraudulent credit card transactions, and to print on
            your invoices. We may collect commercial information such as your
            purchase history, including records of products or services you have
            purchased, provided, returned, or are considering purchasing from
            us.
            <br></br>(d) To communicate with our Customers/Members about their
            accounts and provide customer support and related information about
            customer/member accounts.
            <br></br>(e) To send you System Alert messages. We may need to
            inform you of any temporary or permanent changes to our Services,
            such as planned outages, new and additional features, version
            updates, releases, abuse warnings, and/or changes to our Privacy
            Policy.
            <br></br>(f) To enforce compliance with our Terms of Service and
            applicable laws.
            <br></br>(g) To protect the rights and safety of our Customers and
            third parties, as well as our own.
            <br></br>(h) To meet legal requirements, including complying with
            subpoenas, court orders, discovery requests, and other legal
            matters.
            <br></br>(i) To provide information to representatives and advisors,
            including attorneys and accountants, to help us comply with legal,
            accounting, or security requirements.
            <br></br>(j) To prosecute and defend a court, arbitration, or
            similar legal proceeding.
            <br></br>(k) To respond to lawful requests by public authorities,
            which include court orders, lawsuits and subpoenas which may relate
            to national security or law enforcement requirements or requests.
            <br></br>(l) To provide support and improve the Services we offer.
            This may include our use of the data that you provide us in order to
            enable You to use the Services.
            <br></br>(m) To provide suggestions to Users.
          </li>
          <br></br>
          <li>
            <b>Data Collected for and by our Users</b>
            <br></br>
            <br></br>
            Pursuant to the uses of Personal Information mentioned in Paragraph
            6, we may transfer Personal Information of you to companies that
            assist ACSESTECH in providing or supporting our Services. As part of
            our Services, we may use and incorporate into features information
            you have provided, we have collected from you. We may share this
            information with third parties consistent with the approved uses
            above. We will retain Personal Information we process on behalf of
            our Users for as long as needed to provide our Services or to comply
            with our legal obligations, to prevent abuse, or to enforce any of
            our agreements.
          </li>
          <br></br>
          <li>
            <b>
              Will ACSESTECH Disclose or Share Any of the Personal Information
              it Receives?
            </b>
            <br></br>
            <br></br>
            We do not rent or sell your Personal Information in personally
            identifiable form to anyone, provided certain Personal Information
            may be transferred in connection with business transfers, as
            described below. We may share your Personal Information with third
            parties as described below.
          </li>
          <br></br>
          <li>
            <b>Third Parties</b>
            <br></br>
            <br></br>
            We may disclose or share your Personal Information with the
            following types of third parties for the purposes described in this
            policy:
            <br></br>
            <br></br>(a) With Service Providers. We do not share your personal
            information with unaffiliated third parties except agents or
            companies acting on ACSESTECH’s behalf who perform functions to
            enable ACSESTECH to carry on its business operations. This means we
            may share information about you, including your health information
            (PHI) protected by the Health Insurance Portability and
            Accountability Act of 1996 (HIPAA) that can identify you personally
            with service providers or agents in order to have them perform on
            our behalf any of the activities we are permitted to conduct under
            this Privacy Policy and our Terms of Use including but not limited
            to website design, sending email communications, fraud detection and
            prevention, customer care, removing repetitive information from
            customer lists, analyzing data, providing marketing assistance,
            providing search results and links (including paid listings and
            links), processing credit card payments, providing customer service
            or performing analytics. These third parties are acting on our
            behalf so while they may have access to your information they’re
            required to follow our instructions and meet the standards explained
            in our Privacy Policy when handling information about you that we
            provide them.
            <br></br>
            <br></br>Advertisers: We may allow advertisers and/or merchant
            partners (“Advertisers”) to choose the demographic information of
            users who will see their advertisements and/or promotional offers
            and you agree that we may provide any of the information we have
            collected from you in a form that is not personally identifiable to
            an Advertiser, in order for that Advertiser to select the
            appropriate audience for those advertisements and/or offers. For
            example, we might use the fact you are located in New Jersey to show
            you ads or offers for New Jersey businesses, but we will not tell
            such businesses who you are. Or, we might allow Advertisers to
            display their ads to users with similar usage patterns to yours, but
            we will not disclose usage information to Advertisers except in
            aggregate form, and not in a manner that would identify you
            personally. Note that if an advertiser asks us to show an ad to a
            certain audience or audience segment and you respond to that ad, the
            advertiser may conclude that you fit the description of the audience
            they were trying to reach.
            <br></br>
            <br></br>Affiliated Businesses: In certain situations, businesses or
            third party websites we might be affiliated with may sell or provide
            products or services to you through or in connection with the
            Services (either alone or jointly with us). You can recognize when
            an affiliated business is associated with such a transaction or
            service, and we will share your Personal Information with that
            affiliated business only to the extent that it is related to such
            transaction or service. One such service may include the ability for
            you to automatically transmit Third Party Account Information to
            your Services profile or to automatically transmit information in
            ACSESTECH to your third party account; for example, the results of a
            particular question. We have no control over the policies and
            practices of third party websites or businesses as to privacy or
            anything else, so if you choose to take part in any transaction or
            service relating to an affiliated website or business, please review
            all such business’ or websites’ policies.
            <br></br>
            <br></br>Agents: We might employ other companies and people to
            perform tasks on our behalf and need to share your information with
            them to provide products or services to you. Unless we tell you
            differently, our agents do not have any right to use the Personal
            Information we share with them beyond what is necessary to assist
            us. With Data Analytics and Aggregation Vendors. We may share
            aggregated information that does not include your PII or PI, and we
            may otherwise disclose non-identifying information such as general
            (non-specific) geographic location, device/browser technology,
            onsite behavior, population data, user segmentation, survey results,
            and log data, with third parties for data analysis, demographic
            profiling, and other purposes. Any aggregated information shared in
            this manner will not contain your personal information. With Other
            Users of the Website, App, or Services. If you post information or
            content, such as by posting photos, commenting on a blog, or
            participating in online communities, or when you interact with the
            Website, App, or Services through social media sites, plug-ins or
            other applications, depending upon your privacy settings, this
            information may become public on the Internet. We cannot prevent
            further use of this information. You can control what data you share
            through privacy settings available on some social media sites.
            Please refer to those third-party sites’ privacy policies and terms
            of use to learn more about their privacy practices, which we do not
            control. User Profiles and Submissions: Certain user profile
            information, including your name, location, and any video or image
            content that such user has uploaded to the Services, may be
            displayed to other users to facilitate user interaction within the
            Services or address your request for our services. Your account
            privacy settings may allow you to limit the other users who can see
            the Personal Information in your user profile and/or what
            information in your user profile is visible to others. Please
            remember that any content you upload to your public user profile,
            along with any Personal Information or content that you voluntarily
            disclose online in a manner other users can view (on discussion
            boards, in messages and chat areas, etc.) becomes publicly
            available, and can be collected and used by anyone. Your user name
            may also be displayed to other users if and when you send messages
            or comments or upload images or videos through the Services and
            other users can contact you through messages and comments.
            Additionally, if you sign into the Services through a third party
            social networking site or service, your list of “friends” from that
            site or service may be automatically imported to the Services.
            Again, we do not control the policies and practices of any other
            third party site or service.
            <br></br>
            <br></br>Protection of ACSESTECH and Others: We reserve the right to
            access, read, preserve, and disclose any information that we
            [reasonably] believe is necessary to comply with the law or any
            court order; enforce or apply our Terms of Service and other
            agreements; or protect the rights, property, or safety of ACSESTECH,
            our employees, our users, or others. Information that’s no longer
            personally identifiable. We may anonymize your Personal Information
            so that you are not individually identified, and provide that
            information to our partners. We may also provide aggregate usage
            information to our partners, who may use such information to
            understand how often and in what ways people use our Services, so
            that they, too, can provide you with an optimal online experience.
            However, we never disclose aggregate usage information to a partner
            in a manner that would identify you personally, as an individual.
            With Legal and Law Enforcement. We may share information collected
            about you with law enforcement, governmental agencies, or authorized
            third parties, in response to a verified request relating to a
            criminal investigation or alleged illegal activity or any other
            activity that may expose us, you, or any other Website, App, or
            Services user to legal liability or if we believe disclosure is in
            accordance with, or required by, any applicable law or legal
            process, including lawful requests by public authorities to meet
            national security or law enforcement requirements. In Connection
            with a Change of Control. We may transfer or disclose your
            information in connection with, or during negotiations of, any
            merger, sale of company assets, financing or acquisition of all or a
            portion of our business by another company. Selling, Publishing,
            Disclosing or Sharing Combined Information from Many Users to our
            partners in order to help our partners market and sell our Offerings
            pursuant to written agreements with confidentiality, privacy and
            security obligations. We will create statistical, aggregated data
            related to our Customers, Users, Customers’ Clients and the
            Offerings for analytical purposes. Aggregated data includes data
            derived from personal information in aggregated, anonymous form and
            does not identify any individuals or customer or user names (such
            data is referred to as “Aggregate Information”). Subject to
            applicable laws and regulations we use aggregate information to
            understand our Customers, Users, Customers’ Clients and the
            Offerings and to develop, market and/or improve our Offerings. We
            may provide Aggregate Information to third parties. For example, we
            may share demographic data that describes the percentage of our
            customers who use one of our Offerings to analyze usage trends, such
            as by industry or sector, or to identify KPIs, metrics and benchmark
            data or other such aggregated information useful to our partners. We
            or our third party partners may publicly report the aggregated
            findings of the research or analysis, but only in a way that would
            not allow you or any other person or company to be identified. We do
            not sell or rent Personal Information to unaffiliated third parties
            for their advertising or marketing lists. Professional Advisors: We
            may share your personal information with professional advisors
            acting as service providers, processors, or controllers - including
            our lawyers, bankers, auditors and insurers;
            <br></br>
            <br></br>Partners and Value Added Resellers: if you choose to
            interact with or use our partners or value added resellers we may
            share your personal information with them and they may contact you
            regarding their products or services;
            <br></br>
            <br></br>Customers with whom you are affiliated: if you use our
            products, services and offerings as an authorized user of one of our
            customers, we may share your personal information with your
            affiliated customer responsible for your access to the products,
            services and offerings, to the extent necessary for providing our
            products, services and offerings;
            <br></br>
            <br></br>To third parties to market their products or services to
            you if you have consented to these disclosures. We contractually
            require these third parties to keep personal information
            confidential and use it only for the purposes for which we disclose
            it to them.
            <br></br>To fulfill the purpose for which you provide it.
            <br></br>For any other purpose disclosed by us when you provide the
            information.
            <br></br>With your Consent. We may share your information with your
            consent or at your specific direction.
          </li>
          <br></br>
          <li>
            <b>Is Personal Information about me secure?</b>
            <br></br>
            <br></br>We take reasonable and appropriate measures to protect
            Personal Information from loss, misuse and unauthorized access,
            disclosure, alteration and destruction, taking into account the
            risks involved in the processing and the nature of the Personal
            Information.
            <br></br>
            <br></br>Your account is protected by a user name and password for
            your privacy and security. If you access your account via a third
            party site or service, you may have additional or different sign-on
            protections via that third party site or service. You must prevent
            unauthorized access to your account and Personal Information by
            selecting and protecting your password and/or other sign-on
            mechanisms appropriately and limiting access to your computer or
            device and browser by signing off after you have finished accessing
            your account.
            <br></br>
            <br></br>Our credit card processing vendor, uses security measures
            to protect your information both during the transaction and after it
            is complete. If you have any questions about the security of your
            Personal Information, you may contact us. Please refer to Stripe’s
            Privacy Policy (our payment processing vendor) for any additional
            information regarding security measures put in place to protect your
            payment information. You may copy and paste this link into your
            browser to view Stripe’s Privacy Policy:
            (https://stripe.com/privacy)
            <br></br>
            <br></br>We endeavor to protect the privacy of your account and
            other Personal Information we hold in our records, but
            unfortunately, we cannot guarantee complete security. Unauthorized
            entry or use, hardware or software failure, and other factors, may
            compromise the security of user information at any time.
          </li>
          <br></br>
          <li>
            <b>What Personal Information can I access?</b>
            <br></br>
            <br></br>
            You may access the following information you’ve provided to us:
            <br></br>
            <br></br>
            name and password<br></br>
            <br></br>
            email address<br></br>
            <br></br>
            user profile information, including images you have uploaded
            <br></br>
            <br></br>If you want Us to delete any of your personal information
            please email Us at: <i>SWAPNA.ATMAKURI@ACSESTECH.COM</i>.<br></br>
            <br></br>THE INFORMATION YOU CAN VIEW, UPDATE, AND DELETE MAY CHANGE
            AS THE SERVICES CHANGE. IF YOU HAVE ANY QUESTIONS ABOUT VIEWING OR
            UPDATING INFORMATION WE HAVE ON FILE ABOUT YOU, PLEASE CONTACT US
            AT: <i>SWAPNA.ATMAKURI@ACSESTECH.COM</i>.<br></br>
            <br></br>UNDER CALIFORNIA CIVIL CODE SECTIONS 1798.83-1798.84,
            CALIFORNIA RESIDENTS ARE ENTITLED TO ASK US FOR A NOTICE IDENTIFYING
            THE CATEGORIES OF PERSONAL INFORMATION WHICH WE SHARE WITH OUR
            AFFILIATES AND/OR THIRD PARTIES FOR MARKETING PURPOSES, AND
            PROVIDING CONTACT INFORMATION FOR SUCH AFFILIATES AND/OR THIRD
            PARTIES. IF YOU ARE A CALIFORNIA RESIDENT AND WOULD LIKE A COPY OF
            THIS NOTICE, PLEASE SUBMIT A WRITTEN REQUEST TO:{" "}
            <i>SWAPNA.ATMAKURI@ACSESTECH.COM</i>.
          </li>
          <br></br>
          <li>
            <b>WHAT CHOICES DO I HAVE?</b>
            <br></br>
            <br></br>You can always opt not to disclose information to us, but
            keep in mind some information may be needed to register with us or
            to take advantage of some of our features.
            <br></br>
            <br></br>You may be able to add, update, or delete information as
            explained above. When you update information, however, we may
            maintain a copy of the unrevised information in our records. You may
            delete your account by sending an email to:
            <i>SWAPNA.ATMAKURI@ACSESTECH.COM</i>. and requesting termination of
            your account. Some information may remain in our records after your
            deletion of such information from your account. We may use any
            aggregated data derived from or incorporating your Personal
            Information after you update or delete it, but not in a manner that
            would identify you personally.
            <br></br>
            <br></br>What if I have questions about this policy?
            <br></br>
            <br></br>If you have any questions or concerns regarding our privacy
            policies, please send us a detailed message to
            <i>SWAPNA.ATMAKURI@ACSESTECH.COM</i>. and we will try to resolve
            your concerns.
          </li>
          <br></br>
          <li>
            <b>We Are Located in the United States</b>
            <br></br>
            <br></br>Our servers and offices are located in the United States,
            so your information is stored and processed in the United
          </li>
          <br></br>
          <li>
            <b>Special Rules For Children</b>
            <br></br>
            <br></br>Because of federal law, we are not permitted to knowingly
            collect any personal information from a child under the age of
            thirteen without the consent of that child's parent or guardian. The
            statements in this Privacy Policy about our collection and use of
            personal information also apply to our treatment of personal
            information from children under the age of thirteen. Some elements
            of our Services may require the submission of PayPal payment
            information along with personal information in connection with a
            purchase; other elements of our Services require the submission of
            personal information to access or use the Services. Except in
            certain cases where limited contact information may be collected and
            not retained, these elements of our Services are not available to
            children under the age of thirteen. If a child who we know to be
            under the age of thirteen and for whom we have not received parental
            consent attempts to utilize a service on our Services which is not
            available to children, he/she will not be able to access it and may
            receive a message which relays that he/she is not eligible for such
            a feature.
          </li>
          <br></br>
          <li>
            <b>CAN SPAM Act</b>
            <br></br>
            <br></br>The CAN-SPAM Act is a law that sets the rules for
            commercial email, establishes requirements for commercial messages,
            gives recipients the right to have emails stopped from being sent to
            them, and spells out tough penalties for violations. We collect your
            email address in order to:<br></br>
            <br></br>
            <ul>
              <li>
                Send information, respond to inquiries, and/or other requests or
                questions.
              </li>
              <li>
                Market to our mailing list or continue to send emails to our
                clients after the original transaction has occurred
              </li>
              <br></br>
              To be in compliance with CANSPAM we agree to the following:
              <br></br>
              <br></br>
              <li>NOT use false, or misleading subjects or email addresses</li>
              <li>
                Identify the message as an advertisement in some reasonable way
              </li>
              <li>
                Monitor third party email marketing services for compliance, if
                one is used
              </li>
              <li>Honor opt-out/unsubscribe requests quickly</li>
              <li>
                Allow users to unsubscribe by using the link at the bottom of
                each email
              </li>
            </ul>
          </li>
        </ol>
        <br></br>
        If at any time you would like to unsubscribe from receiving future
        emails, you can Follow the instructions at the bottom of each email and
        we will promptly remove you from all correspondence.
        <br></br>
        <br></br>
        Additional Notice to California Residents<br></br>
        <br></br>
        In accordance with the California Consumer Privacy Act (the “CCPA”), the
        following table summarizes our personal information collection, use, and
        sharing practices in the preceding twelve (12) months since we last
        updated this Privacy Policy. Personal Identifiers & Contact Information
        <br></br>
        <br></br>
        <ul>
          <li>
            Examples: Name, username, password, email address, customer number,
            IP address, unique device ID, tracking technologies (e.g., pixel
            tags, cookies) to collect usage information
          </li>
          <li>
            Business Purpose(s) for Which Information is Used: (i) Maintaining
            and servicing Accounts (ii) Processing and fulfilling orders and
            transactions (iii) Register you as a customer (iv) Process and
            deliver or accept services (v) Notify you about changes to our terms
            and conditions of use or privacy policies (vi) Request that you
            write a review or take a survey (vii) Administer and protect our
            business website (viii) Deliver relevant App or Website content and
            advertisements and measure or understand the effectiveness of the
            advertising (ix) Make suggestions and recommendations about services
            that may interest you (x) Respond to inquiries through the App or
            Website and (xi) Verify and respond to consumer requests
          </li>
          <li>
            Categories of Sources from Which Information Received: Users of our
            Website, App, or Services
          </li>
          <li>
            Categories of Third Parties to Whom Info Was Disclosed in Last 12
            Months: (i) IT vendors, cloud computing providers, database
            providers, customer service vendors, and service providers that that
            need access to such information to perform services for us (ii) Data
            analytics providers and (iii) You requiring this information to
            obtain services and/or for safety and security purposes.
          </li>
        </ul>
        Internet Activity & Technical Data<br></br>
        <br></br>
        <ul>
          <li>
            Examples: Domain information, pages visited, cookie information,
            session information (such as time spent on certain pages and page
            interaction information), date and time of your visit, webpages
            visited, links clicked, browser ID, browser type, device ID,
            operating system, form information downloaded, domain name from
            which our site was accessed, and cookies
          </li>
          <li>
            Business Purpose(s) for Which Information is Used: (i) Improve the
            App and Website experience for all visitors (ii) Understand the
            demographics of our App and Website visitors (iii) Operate and
            maintaining the App and Website (iv) Detect security incidents (v)
            Debug to identify and repair errors that impair existing intended
            functionality of the App and Website (vi) Protect against malicious
            or illegal activity and prosecuting those responsible and (vii)
            Verify and responding to consumer requests
          </li>
          <li>
            Categories of Sources from Which Information Collected: Users of our
            Website, App, or Services and the devices or browsers they used
          </li>
          <li>
            Categories of Third Parties to Whom Info Was Disclosed in Last 12
            Months: (i) IT vendors, cloud computing providers, database
            providers, customer service vendors, and service providers that need
            access to such information to perform services for us and (ii) Data
            analytics providers
          </li>
        </ul>
        Geolocation Data<br></br>
        <ul>
          <br></br>
          <li> Examples: IP Address</li>
          <li>
            Business Purpose(s) for Which Information is Used: (i) Improve the
            App and Website experience for all visitors (ii) Understand the
            demographics of our App and Website visitors (iii) Detect security
            incidents (iv) Debug to identify and repair errors that impair
            existing intended functionality of the App and Website (v) Protect
            against malicious or illegal activity and prosecuting those
            responsible and (vi) Verify and responding to consumer requests
          </li>
          <li>
            Categories of Sources from Which Information Received: Users of our
            Website, App, or Services and the devices or browsers they used
          </li>
          <li>
            Categories of Third Parties to Whom Info Was Disclosed in Last 12
            Months: <br></br>(i) IT vendors, cloud computing providers, database
            providers, customer service vendors, and service providers that need
            access to such information to perform services for us and <br></br>
            (ii) Data analytics providers
          </li>
        </ul>
        Financial Data
        <br></br>
        <ul>
          <br></br>
          <li> Examples: Card number</li>
          <li>
            Business Purpose(s) for Which Information is Used: <br></br>(i)
            Manage payments, fees, and charges and <br></br>(ii) Collect and
            recover funds
          </li>
          <li>
            Categories of Sources from Which Information Received: Users of our
            Website, App, or Services
          </li>
          <li>
            Categories of Third Parties to Whom Info Was Disclosed in Last 12
            Months: <br></br>(i) IT vendors, cloud computing providers, database
            providers, customer service vendors, third-party payment processors,
            and service providers that need access to such information to
            perform services for us <br></br>(ii) Data analytics providers and
            (iii) You requiring this information to obtain services and/or for
            safety and security purposes
          </li>
        </ul>
        Transaction Data<br></br>
        <ul>
          <br></br>
          <li>
            Examples: Details about your payment and details about the services
            you considered, purchased or provided
          </li>
          <li>
            Business Purpose(s) for Which Information is Used: <br></br>(i)
            Manage payments, fees, and charges and <br></br>(ii) Collect and
            recover funds
          </li>
          <li>
            Categories of Sources from Which Information Received: Users of our
            Website, App, or Services
          </li>
          <li>
            Categories of Third Parties to Whom Info Was Disclosed in Last 12
            Months: <br></br>(i) IT vendors, cloud computing providers, database
            providers, customer service vendors, third-party payment processors,
            and service providers that need access to such information to
            perform services for us <br></br>(ii) Business customers who require
            information for safety or security purposes <br></br>(iii) Data
            analytics providers and <br></br>(iv) Users requiring this
            information to obtain services and/or for safety and security
            purposes
          </li>
        </ul>
        Profile Data<br></br>
        <ul>
          <br></br>
          <li>
            Examples: Username, email address, password, purchases, orders,
            customer interests, customer preferences, customer feedback, and
            survey responses
          </li>
          <li>
            Business Purpose(s) for Which Information is Used: <br></br>(i)
            Notify you about changes to our terms and conditions of use and
            privacy policy
            <br></br>(ii) Request that you write a review or take a survey{" "}
            <br></br>(iii) Deliver relevant website content and advertisements
            and measure or understand the effectiveness of the advertising and{" "}
            <br></br>(iv) Make suggestions and recommendations about services
            that may interest you
          </li>
          <li>
            Categories of Sources from Which Information Received: Users of our
            Website, App, or Services
          </li>
          <li>
            Categories of Third Parties to Whom Info Was Disclosed in Last 12
            Months: <br></br>(i) IT vendors, cloud computing providers, database
            providers, customer service vendors, and service providers that need
            access to such information to perform services for us <br></br>(ii)
            Business customers who require information for safety or security
            purposes
            <br></br>(iii) Data analytics providers and <br></br>(iv) Users
            requiring this information to obtain services and/or for safety and
            security purposes
          </li>
        </ul>
        <b>Usage Data</b>
        <br></br>
        <ul>
          <br></br>
          <li>
            Examples: Information about how you use our Website, App, or
            Services
          </li>
          <li>
            Business Purpose(s) for Which Information is Used: <br></br>(i)
            Deliver relevant App and website content and advertisements and
            measure or understand the effectiveness of the advertising and{" "}
            <br></br>(ii) To use data analytics to improve the App, website,
            product/services, marketing, and customer relationships
          </li>
          <li>
            Categories of Sources from Which Information Received: Users of our
            Website, App, or Services and the devices or browsers they used
          </li>
          <li>
            Categories of Third Parties to Whom Info Was Disclosed in Last 12
            Months: <br></br>(i) IT vendors, cloud computing providers, database
            providers, customer service vendors, and service providers that need
            access to such information to perform services for us <br></br>(ii)
            Business customers who require information for safety or security
            purposes and <br></br>(iii) Data analytics providers
          </li>
        </ul>
        Marketing and Communications Data<br></br>
        <ul>
          <br></br>
          <li>
            Examples: Your preferences in receiving marketing from Us and your
            communication preferences
          </li>
          <li>
            Business Purpose(s) for Which Information is Used: <br></br>(i)
            Manage payments, fees, and charges <br></br>(ii) Collect and recover
            funds <br></br>(iii) Notify you about changes to our terms and
            conditions of use and privacy policy <br></br>(iv) Request that you
            write a review or take a survey and <br></br>(v) Deliver relevant
            App or website content and advertisements and measure or understand
            the effectiveness of the advertising
          </li>
          <li>
            Categories of Sources from Which Information Received: Users of our
            Website, App, or Services and the devices or browsers they used
          </li>
          <li>
            Categories of Third Parties to Whom Info Was Disclosed in Last 12
            Months: <br></br>(i) IT vendors, cloud computing providers, database
            providers, customer service vendors, and service providers that need
            access to such information to perform services for us <br></br>(ii)
            Business customers who require information for safety or security
            purposes and <br></br>(iii) Data analytics providers
          </li>
        </ul>
        <br></br>We do not knowingly sell or otherwise disclose the PII or PI of
        minors under 16. Choices for Access, Deletion, Opt-Out of Sale, and
        Right to Non-Discrimination for California Residents or As Required by
        Law:
        <br></br>
        <br></br>If you are a California resident, you have the right to know
        what personal information we collect, disclose, and “sell” about you{" "}
        <br></br>(as that term is defined in the CCPA). Additionally, you have
        the right to access, delete and opt out from the “sale” of your personal
        information. Your Rights:<br></br>
        <br></br>
        <ol>
          <li>
            Right to Know. The right to request, up to 2 times in a 12-month
            period, that we identify to you <br></br>(1) the categories of
            personal information we have collected, disclosed or sold about you
            in the last 12 months, <br></br>(2) the categories of sources from
            which the personal information was collected, <br></br>(3) the
            business purpose for which we use this information, and <br></br>(4)
            the categories of third parties with whom we share or have shared
            your personal information in the last 12 months;
          </li>
          <li>
            Right to Access. The right to request, up to 2 times in a 12-month
            period, that we provide you access to or disclose to you, free of
            charge, the specific pieces of personal information we have
            collected about you in the last 12 months;
          </li>
          <li>
            Right to Delete. The right to request, up to 2 times in a 12-month
            period, that we delete personal information that we collected from
            you, subject to certain exceptions;
          </li>
          <li>
            Right to Opt-Out. The right to opt-out of the sale of your personal
            information to third parties (Note: we do NOT sell your personal
            information to third parties.);
          </li>
          <li>
            The right to designate an authorized agent to submit one of the
            above requests on your behalf; and
          </li>
          <li>
            The right to not be discriminated against in receiving different or
            less favorable pricing, service or financial incentive for
            exercising any of the above rights.
          </li>
        </ol>
        <br></br>To exercise these privacy rights and choices, please follow the
        instructions below:<br></br>
        <br></br>
        <ul>
          <li>
            How to Request That We Identify or Provide You Access to Personal
            Information: You may request that we identify or provide you access
            to your personal information twice in a 12-month period. To do so,
            please email us at: <i>SWAPNA.ATMAKURI@ACSESTECH.COM</i> In response
            to a request to identify your personal information, we will identify
            to you <br></br>(1) the categories of personal information we have
            collected, disclosed or sold about you in the last 12 months,{" "}
            <br></br>(2) the categories of sources from which the personal
            information was collected, <br></br>(3) the business purpose for
            which we use this information, and <br></br>(4) the categories of
            third parties with whom we share or have shared your personal
            information in the last 12 months. In response to a request to
            access your personal information, we will produce an “Access Report”
            detailing the personal information we have collected, disclosed,
            and/or sold about you. This Access Report will be delivered by mail
            or electronically at your request.
          </li>
          <li>
            How to Request Deletion of Personal Information: You may request
            that Restaurant Launch delete the personal information it has
            collected and/or maintained about you. To do so, please email:
            <i>SWAPNA.ATMAKURI@ACSESTECH.COM</i> Note, we may need to retain
            certain personal information as permitted by law, such as to
            complete the transaction for which the personal information was
            collected, provide a requested good or service, detect security
            incidents, protect against malicious, deceptive, fraudulent or
            illegal activities, comply with legal obligations or to enable
            solely internal uses that are reasonably aligned with your
            expectations or lawful within the context in which you provided the
            information.
          </li>
          <li>
            How to Opt-Out of the Sale of Personal Information: We do not sell,
            share, or rent your personal information in any way.
          </li>
        </ul>
        <br></br>
        Verification:
        <br></br>
        <br></br>Please note, we will take steps to verify your identity before
        fulfilling any of the above requests. If you maintain an Account with
        us, we will verify your identity through existing authentication
        practices for the Account (e.g., login and password). If you are not a
        registered Website or Services user, we will verify your identity by
        matching two or three data points that you provide with data points that
        we maintain and have determined to be reliable for the purposes of
        verification.
        <br></br>
        <br></br>
        <b>Contact Us</b>
        <br></br>
        <br></br>
        If you have any questions, comments or concerns about our Privacy
        Policy, you may email us at: <i>SWAPNA.ATMAKURI@ACSESTECH.COM</i>.
        Please do not email any confidential, sensitive or personal information
        to this email. Additionally, if you have any questions, comments or
        concerns about our Privacy Policy feel free to send correspondence to
        the following address:<br></br>
        <br></br>
        <b>
          ACSESTECH LLC<br></br>
          919 NORTH MARKET STREET, STE#950, WILMINGTON, DE 19801
        </b>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
