import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    firstName: "",
    lastName: "",
    email: "",
    countryCode: "",
    phone: "",
    profilePhoto: ""
}

export const authUserSlice = createSlice({
  name: 'authUser',
  initialState,
  reducers: {
    updateAuthUser: (state, action) => {
      state = action.payload;
      return state;
    }
  },
})

// Action creators are generated for each case reducer function
export const { updateAuthUser } = authUserSlice.actions;

export const selectAuthUser = state => state.authUser;

export default authUserSlice;