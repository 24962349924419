export default {
  locale: "en-gb",
  invalid_rrule:
    "You provided an invalid RRule value to component. '%{value}' is not a correct RRule string.",
  months: {
    jan: "Jan",
    feb: "Feb",
    mar: "Mar",
    apr: "Apr",
    may: "May",
    jun: "Jun",
    jul: "Jul",
    aug: "Aug",
    sep: "Sep",
    oct: "Oct",
    nov: "Nov",
    dec: "Dec",
  },
  days_short: {
    mon: "Mon",
    tue: "Tue",
    wed: "Wed",
    thu: "Thu",
    fri: "Fri",
    sat: "Sat",
    sun: "Sun",
  },
  days: {
    monday: "Monday",
    tuesday: "Tuesday",
    wednesday: "Wednesday",
    thursday: "Thursday",
    friday: "Friday",
    saturday: "Saturday",
    sunday: "Sunday",
    day: "Day",
    weekday: "Weekday",
    "weekend day": "Weekend day",
  },
  numerals: {
    first: "First",
    second: "Second",
    third: "Third",
    fourth: "Fourth",
    last: "Last",
  },
  start: {
    label: "Start",
    tooltip: "Datetime picker for start on date",
  },
  repeat: {
    label: "Repeat",
    yearly: {
      label: "Yearly",
      on: "on",
      on_the: "on the",
      of: "of",
    },
    monthly: {
      label: "Monthly",
      every: "every",
      months: "month(s)",
      on_day: "on day",
      on_the: "on the",
    },
    weekly: {
      label: "Weekly",
      every: "every",
      weeks: "week(s)",
    },
    daily: {
      label: "Daily",
      every: "every",
      days: "day(s)",
    },
    hourly: {
      label: "Hourly",
      every: "every",
      hours: "hour(s)",
    },
  },
  end: {
    label: "End",
    tooltip: "Datetime picker for end on date",
    never: "Never",
    after: "After",
    on_date: "On date",
    executions: "occurences",
  },
};
