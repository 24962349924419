import ApiBaseService from "services/ApiBaseService.js";

class PatientFormService {
  static getFamilyMembersRegistrationForms() {
    return ApiBaseService.request(`patients/family/registration-forms`, "GET");
  }

  static getRegistrationForms([patientId]) {
    return ApiBaseService.request(
      `patients/${patientId}/registration-forms`,
      "GET"
    );
  }

  static getRegistrationForm(patientId, formId) {
    return ApiBaseService.request(
      `patients/${patientId}/registration-forms/${formId}`,
      "GET"
    );
  }

  static saveRegistrationForm(patientId, formId, data) {
    return ApiBaseService.request(
      `patients/${patientId}/registration-forms/${formId}`,
      "PUT",
      data
    );
  }

  static submitRegistrationForm(patientId, formId) {
    return ApiBaseService.request(
      `patients/${patientId}/registration-forms/${formId}`,
      "POST",
      {}
    );
  }
}

export default PatientFormService;
