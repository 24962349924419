import {useEffect} from "react";
import {useForm} from "react-hook-form";
import {useNavigate} from "react-router-dom";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import UserService from "services/UserService.js";
import InputErrorMessage from "components/utils/InputErrorMessage.js";
import RegexUtils from "utils/RegexUtils.js";
import COUNTRY_CODES_LIST from "utils/constants/CountryCodesList.js";
import StringUtils from "utils/StringUtils.js";
import useAPI from "hooks/useAPI.js";
import { useSelector, useDispatch } from 'react-redux';
import {selectAuthUser, updateAuthUser} from "state/slices/authUserSlice";

function Profile() {
  const navigate = useNavigate();

  const updateProfileAPI = useAPI(UserService.updateProfile, updateProfileHandler, true, false);

  const authUser = useSelector(selectAuthUser);
  const dispatch = useDispatch();

  const {register, formState: {
      errors
    }, handleSubmit, setValue, reset} = useForm({criteriaMode: "all", mode: "onTouched"});

  useEffect(() => {
    // Set form data from the authenticated user state objectz
    reset(authUser)
  }, [authUser]);


  function updateProfile(data) {
    updateProfileAPI.execute(data);
  }

  /* API success handlers */

  function updateProfileHandler(response) {
    dispatch(updateAuthUser(response.data));
  }

  return (<Paper elevation={0} className="px-5 py-4">
    <h4>Profile</h4>
    <form className="mt-4" onSubmit={handleSubmit(updateProfile)}>
      <div className="mb-3">
        <label htmlFor="firstNameInput" className="form-label">
          First Name
        </label>
        <input type="text" className="form-control" id="firstNameInput" {...register("firstName", {
              required: "First Name is required",
              pattern: {
                value: RegexUtils.NAME,
                message: "Fist Name is invalid"
              }
            })}/>
        <InputErrorMessage name="firstName" errors={errors}/>
      </div>
      <div className="mb-3">
        <label htmlFor="lastNameInput" className="form-label">
          Last Name
        </label>
        <input type="text" className="form-control" id="lastNameInput" {...register("lastName", {
              required: "Last Name is required",
              pattern: {
                value: RegexUtils.NAME,
                message: "Last Name is invalid"
              }
            })}/>
        <InputErrorMessage name="lastName" errors={errors}/>
      </div>
      <div className="mb-3">
        <label htmlFor="emailInput" className="form-label">
          Email ID
        </label>
        <input type="email" className="form-control" id="emailInput" {...register("email", {
              required: "Email is required",
              pattern: {
                value: RegexUtils.EMAIL,
                message: "Email is invalid"
              }
            })} disabled/>
        <InputErrorMessage name="email" errors={errors}/>
      </div>
      <div className="mb-3">
        <label htmlFor="countryCode" className="form-label">
          Country Code
        </label>
        <select className="form-select" id="countryCodeInput" aria-label="Default select example" {...register("countryCode", {
              required: "Country Code is required",
              minLength: {
                value: 2,
                message: "Country Code must exceed 2 characters"
              },
              maxLength: {
                value: 5,
                message: "Country Code must not exceed 5 characters"
              }
            })}>
          <option></option>
          {
            COUNTRY_CODES_LIST.map((item, index) => (<option key={item.value + index} value={item.value}>
              {item.country}
            </option>))
          }
        </select>
        <InputErrorMessage name="countryCode" errors={errors}/>
      </div>
      <div className="mb-4">
        <label htmlFor="phoneInput" className="form-label">
          Phone
        </label>
        <input type="text" className="form-control" id="phoneInput" {...register("phone", {
              required: "Phone is required",
              pattern: {
                value: RegexUtils.PHONE,
                message: "Phone is invalid"
              }
            })} onKeyUp={(e) => {
              setValue("phone", StringUtils.formatPhoneNumber(e))
            }}/>
        <InputErrorMessage name="phone" errors={errors}/>
      </div>
      {
        (updateProfileAPI.status === 'pending')
          ? (<LoadingButton loading variant="contained" className="me-3">
            Updating
          </LoadingButton>)
          : (<Button type="submit" className="me-3" variant="contained">
            Update
          </Button>)
      }
      <Button onClick={() => navigate(0)} variant="outlined">
        Cancel
      </Button>
    </form>
  </Paper>);
}

export default Profile;
