import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import PropTypes from "prop-types";

BreadCrumb.propTypes = {
  pathElements: PropTypes.array.isRequired
};

function BreadCrumb(props) {
  function handleClick(event) {
    event.preventDefault();
    console.info("You clicked a breadcrumb.");
  }

  return (
    <div className="mb-3" role="presentation" onClick={handleClick}>
      <Breadcrumbs aria-label="breadcrumb">
        {props.pathElements.map((element, index) => (
          <Link key={index} underline="hover" color="inherit" href="/">
            {element}
          </Link>
        ))}
      </Breadcrumbs>
    </div>
  );
}

export default BreadCrumb;
