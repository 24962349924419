import { useState, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Tabs from "components/utils/Tabs.js";
import LoadingButton from "@mui/lab/LoadingButton";
import DoneIcon from "@mui/icons-material/Done";
import TextField from "@mui/material/TextField";
import EnhancedTable from "components/utils/Table.js";
import EditIcon from "@mui/icons-material/Edit";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import PatientService from "services/PatientService.js";
import SaveIcon from "@mui/icons-material/Save";
import InputErrorMessage from "components/utils/InputErrorMessage.js";
import TableRowActionsCell from "components/utils/TableRowActionsCell.js";
import PopUpModal from "components/utils/PopUpModal.js";
import AuditFieldsModal from "components/modals/AuditFieldsModal.js";
import useAPI from "hooks/useAPI.js";
import DateUtils from "utils/DateUtils.js";
import { useOutletContext } from "react-router-dom";

const tabItemNames = ["Subjective", "Objective", "Assessment", "Plan"];

function SoapNotes() {
  const [hospitalId] = useOutletContext();

  const tabViews = [
    <NotesView noteType="SUBJECTIVE" hospitalId={hospitalId} />,
    <NotesView noteType="OBJECTIVE" hospitalId={hospitalId} />,
    <NotesView noteType="ASSESSMENT" hospitalId={hospitalId} />,
    <NotesView noteType="PLAN" hospitalId={hospitalId} />,
  ];

  return (
    <div>
      <Tabs itemNames={tabItemNames} views={tabViews} />
    </div>
  );
}

function NotesView(props) {
  const { patientId } = useParams();

  const [notesList, setNotesList] = useState([]);
  const [isUpdateNote, setIsUpdateNote] = useState(false);
  const [noteIdUnderUpdate, setNoteIdUnderUpdate] = useState(null);
  const [popUpModalContent, setPopUpModalContent] = useState({
    createdAt: "",
    lastUpdatedAt: "",
    createdBy: "",
    lastUpdatedBy: "",
  });

  const getSoapNotesAPI = useAPI(
    PatientService.getSoapNotes,
    getSoapNotesHandler
  );
  const addSoapNoteAPI = useAPI(PatientService.addSoapNote, addSoapNoteHandler);
  const updateSoapNoteAPI = useAPI(
    PatientService.updateSoapNote,
    updateSoapNoteHandler,
    true
  );
  const deleteSoapNoteAPI = useAPI(
    PatientService.deleteSoapNote,
    deleteSoapNoteHandler
  );

  const popUpModalRef = useRef(null);

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({ criteriaMode: "all", mode: "onTouched" });

  useEffect(() => {
    getSoapNotes();
  }, []);

  /* Utility Functions */

  function clearNoteData() {
    reset({ title: "", description: "" });
    setIsUpdateNote(false);
  }

  function populateNoteData(noteId) {
    setIsUpdateNote(true);
    setNoteIdUnderUpdate(noteId);

    setNotesList((prevState) => {
      let noteListIndex = prevState.findIndex((note) => note.id === noteId);
      reset({
        title: prevState[noteListIndex].title,
        description: prevState[noteListIndex].description,
      });
      return [...prevState];
    });
  }

  function getSoapNotes() {
    getSoapNotesAPI.execute(props.hospitalId, patientId, props.noteType);
  }

  function addSoapNote(data) {
    addSoapNoteAPI.execute(props.hospitalId, patientId, {
      title: data.title,
      description: data.description,
      noteType: props.noteType,
    });
  }

  function updateSoapNote(data) {
    updateSoapNoteAPI.execute(props.hospitalId, patientId, noteIdUnderUpdate, {
      title: data.title,
      description: data.description,
      noteType: props.noteType,
    });
  }

  function deleteNote(noteId) {
    deleteSoapNoteAPI.execute(props.hospitalId, patientId, noteId);
  }

  const onSubmit = (data) => {
    if (isUpdateNote) updateSoapNote(data);
    else addSoapNote(data);
  };

  /* API Success Handlers */

  function getSoapNotesHandler(response) {
    let data = response.data;
    let updatedNotesList = [];
    data.map((note, index) =>
      updatedNotesList.push(transformJsonData(note, index + 1))
    );
    setNotesList(updatedNotesList);
  }

  function addSoapNoteHandler(response) {
    setNotesList((prevState) => {
      prevState.push(transformJsonData(response.data, prevState.length + 1));
      return [...prevState];
    });
    clearNoteData();
  }

  function updateSoapNoteHandler(response) {
    setNotesList((prevState) => {
      let noteListIndex = prevState.findIndex(
        (note) => note.id === response.data.id
      );
      prevState[noteListIndex].title = response.data.title;
      prevState[noteListIndex].description = response.data.description;
      prevState[noteListIndex].actions = <ActionsCell note={response.data} />;
      return [...prevState];
    });
  }

  function deleteSoapNoteHandler(response) {
    getSoapNotes();
    setIsUpdateNote(false);
  }

  return (
    <Paper elevation={0}>
      <form className="container py-4 px-4" onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-4">
          <div>
            <TextField
              id="noteTitleInput"
              label="Title"
              variant="outlined"
              fullWidth
              {...register("title", {
                required: "Title is required",
              })}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <InputErrorMessage name="title" errors={errors} />
          </div>
          <div className="mt-3">
            <TextField
              id="noteDescriptionInput"
              label="Description"
              multiline
              rows={4}
              fullWidth
              {...register("description", {
                required: "Description is required",
              })}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <InputErrorMessage name="description" errors={errors} />
          </div>
        </div>
        {addSoapNoteAPI.status === "pending" ? (
          <LoadingButton
            loading
            loadingPosition="start"
            startIcon={<SaveIcon />}
            variant="contained"
            className="me-3"
          >
            {isUpdateNote ? "Update Note" : "Add Note"}
          </LoadingButton>
        ) : (
          <Button
            type="submit"
            className="me-3"
            startIcon={<DoneIcon />}
            variant="contained"
          >
            {isUpdateNote ? "Update Note" : "Add Note"}
          </Button>
        )}
        <Button
          onClick={() => {
            clearNoteData();
          }}
          variant="outlined"
        >
          Cancel
        </Button>
      </form>
      <div className="px-3">
        <EnhancedTable
          headings={tableHeadings}
          rowsData={notesList}
          rowsPerPage={10}
        />
      </div>
      <PopUpModal ref={popUpModalRef} modalWidth={320}>
        <AuditFieldsModal data={popUpModalContent} />
      </PopUpModal>
    </Paper>
  );

  function ActionsCell({ note }) {
    const actions = {
      startItems: [
        {
          component: (
            <InfoOutlinedIcon fontSize="inherit" color="custom.light" />
          ),
          onClickHandler: () => {
            setPopUpModalContent({
              createdAt: DateUtils.formatDateTime(note.createdAt),
              lastUpdatedAt: DateUtils.formatDateTime(note.lastUpdatedAt),
              createdBy: note.createdByUserFullName,
              lastUpdatedBy: note.lastUpdatedByUserFullName,
            });
            popUpModalRef.current.openModal();
          },
        },
        {
          component: <EditIcon fontSize="inherit" color="secondary" />,
          onClickHandler: () => populateNoteData(note.id),
        },
        {
          component: <DeleteOutlineIcon fontSize="inherit" color="error" />,
          onClickHandler: () => deleteNote(note.id),
        },
      ],
      menuItems: [],
    };
    return <TableRowActionsCell actions={actions} />;
  }

  function transformJsonData(note, index) {
    return {
      id: note.id,
      index,
      title: note.title,
      description: note.description,
      actions: <ActionsCell note={note} />,
    };
  }
}

const tableHeadings = [
  {
    id: "id",
    label: "ID",
  },
  {
    id: "sno",
    label: "S.No.",
  },
  {
    id: "title",
    label: "Title",
  },
  {
    id: "description",
    label: "Description",
  },
  {
    id: "actions",
    label: "Actions",
  },
];

export default SoapNotes;
