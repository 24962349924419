import { useState } from "react";
import EnhancedTable from "components/utils/Table.js";
import CircularProgress from "@mui/material/CircularProgress";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import PatientFormService from "services/PatientFormService.js";
import { useNavigate } from "react-router-dom";
import FormUtils from "utils/FormUtils.js";
import useAPI from "hooks/useAPI.js";

function RegistrationForms() {
  const [assessmentAndFormsList, setAssessmentAndFormsList] = useState([]);
  const getRegistrationFormsAPI = useAPI(
    PatientFormService.getFamilyMembersRegistrationForms,
    getFamilyMembersRegistrationFormsHandler,
    false,
    true
  );

  function getFamilyMembersRegistrationFormsHandler(response) {
    for (let form of response.data) {
      assessmentAndFormsList.push(transformJsonData(form));
    }
    setAssessmentAndFormsList(assessmentAndFormsList);
  }

  return (
    <div className="registration-forms-container">
      <h3>Registration Forms</h3>
      <Paper elevation={0} className="mt-4">
        {getRegistrationFormsAPI.status === "pending" ? (
          <div className="text-center">
            <CircularProgress className="text-center" sx={{ my: 20 }} />
          </div>
        ) : (
          <EnhancedTable
            headings={tableHeadings}
            rowsData={assessmentAndFormsList}
            rowsPerPage={10}
          />
        )}
      </Paper>
      <p className="disclaimer">
        *For Details and all files (questionaire, program, etc.) click on VIEW
      </p>
    </div>
  );
}

const tableHeadings = [
  {
    id: "id",
    label: "ID",
  },
  {
    id: "patientId",
    label: "Patient ID",
  },
  {
    id: "patientName",
    label: "Patient Name",
  },
  {
    id: "formType",
    label: "Form Type",
  },
  {
    id: "hospital",
    label: "Hospital",
  },
  {
    id: "status",
    label: "Status",
  },
  {
    id: "view",
    label: "",
  },
];

function transformJsonData(form) {
  return {
    id: form.id,
    patientId: form.patientId,
    name: form.patientName,
    form_type: form.formGenericName,
    hospital: form.hospitalName,
    status: FormUtils.getStatusChip(form.formStatusTypeEnum),
    view: <ViewLink id={form.patientId} />,
  };
}

function ViewLink({ id }) {
  let navigate = useNavigate();
  return (
    <Link
      component="button"
      onClick={() => navigate(id.toString())}
      underline="none"
    >
      View
    </Link>
  );
}

export default RegistrationForms;
