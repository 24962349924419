import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  id: ''
}

export const hospitalSlice = createSlice({
  name: 'hospital',
  initialState,
  reducers: {
    focusHospital: (state, action) => {
      state = action.payload;
      return state;
    }
  },
})

// Action creators are generated for each case reducer function
export const { focusHospital } = hospitalSlice.actions;

export const selectHospital = state => state.hospital;

export default hospitalSlice;