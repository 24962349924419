import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import withRouter from "components/utils/withRouter.js";
import styles from "styles/public.module.css";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import { useSnackbar } from "notistack";
import SnackbarConstants from "utils/constants/Snackbar.js";
import PasswordService from "services/PasswordService.js";
import Link from "@mui/material/Link";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputErrorMessage from "components/utils/InputErrorMessage.js";
import RegexUtils from "utils/RegexUtils.js";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

function ResetPassword(props) {
  const { enqueueSnackbar } = useSnackbar();
  const [resetPasswordViewState, setResetPasswordViewState] = useState(0);
  const [isFormProcessing, setIsFormProcessing] = useState(false);
  const [transactionId, setTransactionId] = useState("");
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [showOtpTimer, setShowOtpTimer] = useState(false);
  const [timerSeconds, setTimerSeconds] = useState(30);
  let timer;

  const {
    register,
    formState: { errors },
    getValues,
    handleSubmit,
  } = useForm({ criteriaMode: "all", mode: "onTouched" });

  useEffect(() => {
    // Cleanup the timer when the component unmounts
    return () => {
      stopOtpTimer();
    };
  }, []);

  async function handleFormSubmit(data) {
    setIsFormProcessing(true);
    if (resetPasswordViewState === 0) {
      try {
        const captchaToken = await executeRecaptcha("Request_OTP");
        const response = await PasswordService.requestOtp(
          {
            email: data.email,
          },
          captchaToken
        );
        setTransactionId(response.data.transactionId);
        setResetPasswordViewState(1);
        startOtpTimer();
        enqueueSnackbar(response.message, SnackbarConstants.success);
      } catch (error) {
        handleError(error);
      }
    } else if (resetPasswordViewState === 1) {
      try {
        const captchaToken = await executeRecaptcha("Verify_OTP");
        const response = await PasswordService.verifyOtp(
          {
            email: data.email,
            otp: data.otp,
            transactionId,
          },
          captchaToken
        );
        setTransactionId(response.data.transactionId);
        setResetPasswordViewState(2);
        enqueueSnackbar(response.message, SnackbarConstants.success);
      } catch (error) {
        handleError(error);
      }
    } else {
      try {
        const captchaToken = await executeRecaptcha("Reset_Password");
        const response = await PasswordService.resetPassword(
          {
            ...data,
            transactionId,
          },
          captchaToken
        );
        enqueueSnackbar(response.message, SnackbarConstants.success);
        props.navigate("/");
      } catch (error) {
        handleError(error);
      }
    }
    setIsFormProcessing(false);
  }

  function handleError(error) {
    let errorData = error.data;
    enqueueSnackbar(errorData.message, SnackbarConstants.error);
    let errors = errorData.errors;
    if (errors) {
      errors.forEach((item, i) => {
        enqueueSnackbar(item, SnackbarConstants.error);
      });
    }
  }

  async function resendOTP() {
    setIsFormProcessing(true);
    startOtpTimer();

    try {
      const captchaToken = await executeRecaptcha("Request_OTP");

      const data = getValues();

      const response = await PasswordService.requestOtp(
        {
          email: data.email,
        },
        captchaToken
      );
      setTransactionId(response.data.transactionId);
      enqueueSnackbar("OTP resent", SnackbarConstants.success);
    } catch (error) {
      stopOtpTimer();
      handleError(error);
    }
    setIsFormProcessing(false);
  }

  function startOtpTimer() {
    setShowOtpTimer(true);
    setTimerSeconds(30);

    timer = setInterval(() => {
      setTimerSeconds((prevSeconds) => {
        if (prevSeconds == 0) {
          stopOtpTimer();
        }
        // Decrement the seconds by 1
        return prevSeconds - 1;
      });
    }, 1000); // Update every 1000ms (1 second)
  }

  function stopOtpTimer() {
    setShowOtpTimer(false);
    clearInterval(timer);
  }

  return (
    <Paper elevation={0} className={styles["form-container"]}>
      <h4>Reset your password</h4>
      <form className="mt-4" onSubmit={handleSubmit(handleFormSubmit)}>
        <div className="mb-3">
          <label htmlFor="emailInput" className="form-label">
            Email ID
          </label>
          <input
            type="email"
            name="email"
            className="form-control"
            id="emailInput"
            aria-describedby="emailHelp"
            {...register("email", {
              required: "Email ID is required",
              pattern: {
                value: RegexUtils.EMAIL,
                message: "Email ID is invalid",
              },
            })}
            disabled={resetPasswordViewState !== 0}
          />
          <InputErrorMessage name="email" errors={errors} />
        </div>
        {resetPasswordViewState === 1 ? (
          <>
            <div className="mb-3">
              <label htmlFor="otpInput" className="form-label">
                OTP
              </label>
              <input
                type="number"
                name="otp"
                className="form-control"
                id="otpInput"
                {...register("otp", {
                  required: "OTP is required",
                  pattern: {
                    value: RegexUtils.OTP,
                    message: "OTP is invalid",
                  },
                })}
              />
              <InputErrorMessage name="otp" errors={errors} />
            </div>{" "}
            <div className="text-end mb-2">
              {showOtpTimer ? (
                <small>Resend OTP in {timerSeconds} s</small>
              ) : (
                <Link component="button" onClick={resendOTP} underline="none">
                  Resend OTP
                </Link>
              )}
            </div>
          </>
        ) : null}
        {resetPasswordViewState === 2 ? (
          <div>
            <div className="mb-3">
              <label htmlFor="newPasswordInput" className="form-label">
                New Password
              </label>
              <div>
                <OutlinedInput
                  name="newPassword"
                  id="newPasswordInput"
                  className={styles["password-input"]}
                  autoComplete="new-password"
                  type={showNewPassword ? "text" : "password"}
                  endAdornment={
                    <InputAdornment position="end">
                      {" "}
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowNewPassword((show) => !show)}
                        edge="end"
                      >
                        {showNewPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  {...register("newPassword", {
                    required: "New Password is required",
                    pattern: {
                      value: RegexUtils.PASSWORD,
                      message: "New Password is invalid",
                    },
                  })}
                />
              </div>
              <InputErrorMessage name="newPassword" errors={errors} />
              <div id="passwordHelpBlock" className="form-text">
                Password must be atleast 8 characters long, including atleast
                one letter, one number, and one special character
              </div>
            </div>
            <div className="mb-3">
              <label htmlFor="confirmPasswordInput" className="form-label">
                Confirm Password
              </label>
              <div>
                <OutlinedInput
                  name="confirmPassword"
                  id="confirmPasswordInput"
                  className={styles["password-input"]}
                  autoComplete="new-password"
                  type={showConfirmPassword ? "text" : "password"}
                  endAdornment={
                    <InputAdornment position="end">
                      {" "}
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowConfirmPassword((show) => !show)}
                        edge="end"
                      >
                        {showConfirmPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                  {...register("confirmPassword", {
                    required: "Confirm Password is required",
                    pattern: {
                      value: RegexUtils.PASSWORD,
                      message: "Confirm Password is invalid",
                    },
                  })}
                />
                <InputErrorMessage name="confirmPassword" errors={errors} />
              </div>
            </div>
          </div>
        ) : null}
        {isFormProcessing ? (
          <LoadingButton loading variant="contained" className="me-3">
            {getSubmitButtonText()}
          </LoadingButton>
        ) : (
          <Button type="submit" className="me-3" variant="contained">
            {getSubmitButtonText()}
          </Button>
        )}
        <Button onClick={() => props.navigate(0)} variant="outlined">
          Cancel
        </Button>
      </form>
    </Paper>
  );

  function getSubmitButtonText() {
    if (resetPasswordViewState === 0) return "Submit";
    if (resetPasswordViewState === 1) return "Verify";
    return "Reset";
  }
}

export default withRouter(ResetPassword);
