import {useState} from "react";
import {useForm} from "react-hook-form";
import styles from "styles/settings.module.css";
import withRouter from "components/utils/withRouter.js";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import {useSnackbar} from "notistack";
import SnackbarConstants from "utils/constants/Snackbar.js";
import PasswordService from "services/PasswordService.js";
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputErrorMessage from "components/utils/InputErrorMessage.js";
import RegexUtils from "utils/RegexUtils.js";

function ChangePassword(props) {
  const {enqueueSnackbar} = useSnackbar();
  const [isFormProcessing, setIsFormProcessing] = useState(false);

  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const {register, formState: {
      errors
    }, handleSubmit} = useForm({criteriaMode: "all", mode: "onTouched"});

  async function changePassword(data) {
    setIsFormProcessing(true);

    try {
      const response = await PasswordService.changePassword(data);
      enqueueSnackbar(response.message, SnackbarConstants.success);
      props.navigate("/");
    } catch (error) {
      handleError(error);
    }

    setIsFormProcessing(false);
  }

  function handleError(error) {
    console.log(error);
    let errorData = error.data;
    enqueueSnackbar(errorData.message, SnackbarConstants.error);
    let errors = errorData.errors;
    if (errors) {
      errors.forEach((item, i) => {
        enqueueSnackbar(item, SnackbarConstants.error);
      });
    }
  }

  return (<Paper elevation={0} className="px-5 py-4">
    <h4>Change your password</h4>
    <form className="mt-4" onSubmit={handleSubmit(changePassword)}>
      <div>
        <div className="mb-3">
          <label htmlFor="newPasswordInput" className="form-label">
            Current Password
          </label>
          <div>
            <OutlinedInput name="currentPassword" id="currentPasswordInput" className={styles["password-input"]} autoComplete="current-password" type={showCurrentPassword
                ? 'text'
                : 'password'} endAdornment={<InputAdornment position = "end" > <IconButton aria-label="toggle password visibility" onClick={() => setShowCurrentPassword((show) => !show)} edge="end">
                {
                  showCurrentPassword
                    ? <VisibilityOff/>
                    : <Visibility/>
                }
              </IconButton>
            </InputAdornment>} {...register("currentPassword", {
                required: "Current Password is required",
                pattern: {
                  value: RegexUtils.PASSWORD,
                  message: "Current Password is invalid"
                }
              })}/>
          </div>
          <InputErrorMessage name="currentPassword" errors={errors}/>
        </div>
        <div className="mb-3">
          <label htmlFor="newPasswordInput" className="form-label">
            New Password
          </label>
          <div>
            <OutlinedInput name="newPassword" id="newPasswordInput" className={styles["password-input"]} autoComplete="new-password" type={showNewPassword
                ? 'text'
                : 'password'} endAdornment={<InputAdornment position = "end" > <IconButton aria-label="toggle password visibility" onClick={() => setShowNewPassword((show) => !show)} edge="end">
                {
                  showNewPassword
                    ? <VisibilityOff/>
                    : <Visibility/>
                }
              </IconButton>
            </InputAdornment>} {...register("newPassword", {
                required: "New Password is required",
                pattern: {
                  value: RegexUtils.PASSWORD,
                  message: "New Password is invalid"
                }
              })}/>
          </div>
          <InputErrorMessage name="newPassword" errors={errors}/>
          <div id="passwordHelpBlock" className={`form-text ${styles['password-help-text']}`}>
            Password must be atleast 8 characters long, including atleast one letter, one number, and one special character
          </div>
        </div>
        <div className="mb-3">
          <label htmlFor="confirmPasswordInput" className="form-label">
            Confirm Password
          </label>
          <div>
            <OutlinedInput name="confirmPassword" id="confirmPasswordInput" className={styles["password-input"]} autoComplete="new-password" type={showConfirmPassword
                ? 'text'
                : 'password'} endAdornment={<InputAdornment position = "end" > <IconButton aria-label="toggle password visibility" onClick={() => setShowConfirmPassword((show) => !show)} edge="end">
                {
                  showConfirmPassword
                    ? <VisibilityOff/>
                    : <Visibility/>
                }
              </IconButton>
            </InputAdornment>} {...register("confirmPassword", {
                required: "Confirm Password is required",
                pattern: {
                  value: RegexUtils.PASSWORD,
                  message: "Confirm Password is invalid"
                }
              })}/>
          </div>
          <InputErrorMessage name="confirmPassword" errors={errors}/>
        </div>
      </div>
      {
        isFormProcessing
          ? (<LoadingButton loading variant="contained" className="me-3">
            Change
          </LoadingButton>)
          : (<Button type="submit" className="me-3" variant="contained">
            Change
          </Button>)
      }
      <Button onClick={() => props.navigate(0)} variant="outlined">
        Cancel
      </Button>
    </form>
  </Paper>);

}

export default withRouter(ChangePassword);
