const MAIN_NAV_BAR_ITEMS = [
  {
    name: "Dashboard",
    icon: "assets/dashboard-black-icon.svg",
    iconActive: "assets/dashboard-blue-icon.svg",
    iconAltText: "Dashboard Icon",
    routeName: "",
    children: [],
  },
  {
    name: "EMR Management",
    icon: "assets/doctor-black-icon.svg",
    iconActive: "assets/doctor-blue-icon.svg",
    iconAltText: "EMR Management Icon",
    routeName: "emr-management",
    children: [
      {
        name: "Patients",
        routeName: "emr-management/patients",
      },
      //  {
      //   name: "Reports",
      //   routeName: "emr-management/reports"
      // }
    ],
  },
  {
    name: "Schedule Management",
    icon: "assets/clock-black-icon.svg",
    iconActive: "assets/clock-blue-icon.svg",
    iconAltText: "Schedule Management Icon",
    routeName: "schedule-management",
    children: [
      {
        name: "Appointments",
        routeName: "schedule-management/appointments",
      },
      {
        name: "Calendar",
        routeName: "schedule-management/calendar",
      },
    ],
  },
  // {
  //   name: "Practice Management",
  //   icon: "assets/account-tie-black-icon.svg",
  //   iconActive: "assets/account-tie-blue-icon.svg",
  //   iconAltText: "Practice Management Icon",
  //   routeName: "",
  //   children: []
  // },
  // {
  //   name: "Finance Management",
  //   icon: "assets/finance-black-icon.svg",
  //   iconActive: "assets/finance-blue-icon.svg",
  //   iconAltText: "Finance Management Icon",
  //   routeName: "",
  //   children: []
  // },
  // {
  //   name: "Reports",
  //   icon: "assets/file-chart-black-icon.svg",
  //   iconActive: "assets/file-chart-blue-icon.svg",
  //   iconAltText: "Reports Icon",
  //   routeName: "",
  //   children: []
  // }
];

export default MAIN_NAV_BAR_ITEMS;
