import styles from "styles/footer.module.css";

function Footer() {
  return <div id={styles['footer']}>
    <span id={styles['copyright-text']}>
      &#169; Copyright 2022 - Neuroelpis</span>
  </div>;
}

export default Footer;
