import {Outlet} from "react-router-dom";
import SideNavBar from "components/navs/SideNavBar.js";
import USER_SETTINGS_MAIN_NAV_BAR_ITEMS from "utils/constants/UserSettingsSideNavBarItems.js";

function Settings() {
  return (<div>
    <div className="d-flex flex-row flex-nowrap justify-content-around mt-3">
      <div className="me-3">
        <SideNavBar items={USER_SETTINGS_MAIN_NAV_BAR_ITEMS} basePathElement={"settings"}/>
      </div>
      <div className="flex-grow-1">
        <Outlet/>
      </div>
    </div>
  </div>);
}

export default Settings;
