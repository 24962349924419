import "styles/patient-dash.scss";
// import {styled} from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Link from "@mui/material/Link";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
// import Grid from "@mui/material/Grid";
// import Card from "react-bootstrap/Card";
// import Button from "react-bootstrap/Button";
// import ButtonGroup from "react-bootstrap/ButtonGroup";
// import Table from "react-bootstrap/Table";
// import ArrowCircleLeftRoundedIcon from "@mui/icons-material/ArrowCircleLeftRounded";
// import ArrowCircleRightRoundedIcon from "@mui/icons-material/ArrowCircleRightRounded";
// import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";

// const Item = styled(Paper)(({ theme }) => ({
//   backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
//   ...theme.typography.body2,
//   padding: theme.spacing(1),
//   textAlign: 'center',
//   color: theme.palette.text.secondary,
// }));

const PatientDashboard = () => {
  return (
    <div className="patient-dash-container">
      <Box className="d-flex">
        <Paper className="p-3 me-3">
          <Link href="/patient/assessments-and-forms" underline="none">
            View Registration Forms
          </Link>
        </Paper>
        <Paper className="p-3 me-3">
          <Link href="/patient/family" underline="none">
            View Patient Profiles
          </Link>
        </Paper>
        {/*<Grid container spacing={4}>
            <Grid item xs={6}>
              <Card className="card border-color1 card-table-width">
                <Card.Body>
                  <Card.Text></Card.Text>
                  <Card.Link>
                    <div className="booking-color">
                      <p className="booking-size">CREATE NEW CLASS BOOKING</p>
                      Create new booking
                      <div className="icon-button">
                        <ButtonGroup aria-label="Third group">
                          <Button className="button-radius">+</Button>
                        </ButtonGroup>
                      </div>
                    </div>
                  </Card.Link>
                </Card.Body>
              </Card>
            </Grid>
            <Grid item xs={6}>
              <Card className="card border-color2 card-table-width">
                <Card.Body>
                  <Card.Text></Card.Text>
                  <Card.Link>
                    <div className="booking-color">
                      <p className="booking-size">
                        CREATE NEW CONSULTATION BOOKING
                      </p>
                      Create new consultation
                      <div className="icon-button">
                        <ButtonGroup aria-label="Third group">
                          <Button className="button-radius">+</Button>
                        </ButtonGroup>
                      </div>
                    </div>
                  </Card.Link>
                </Card.Body>
              </Card>
            </Grid>
            <Grid item xs={7} className="full-width-mui">
              <p>Upcoming Consultations</p>
              <Card className="card card-table-width border-color2">
                <Card.Body>
                  <Table>
                    <thead>
                      <tr>
                        <th>Day</th>
                        <th>Date</th>
                        <th>Time</th>
                        <th>Type</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Sunday</td>
                        <td>01/08/2021</td>
                        <td>10:00-12:00</td>
                        <td>Consultation-assessement</td>
                        <td>view</td>
                      </tr>
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Grid>
            <Grid item xs={5} className="full-width-mui">
              <div className="view-consults">
                <ArrowCircleLeftRoundedIcon />
                <p>View Consultations</p>
                <ArrowCircleRightRoundedIcon />
              </div>
              <Card className="card card-table-width border-color2 no-top-margin">
                <Card.Body>
                  <div className="round-check">
                    <CheckCircleRoundedIcon />
                  </div>
                  <div className="con-summary">
                    <h6>Next Consultation Booked</h6>
                    <p>10:00, 01/04/2022</p>
                  </div>
                  <div>
                    <h4>Sunday, 10:00</h4>
                    <p>Upcoming consultations</p>
                  </div>
                </Card.Body>
              </Card>
            </Grid>

        </Grid>
        */}
      </Box>
    </div>
  );
};
export default PatientDashboard;
