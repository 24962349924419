import moment from "moment";

function getPatientAgeGroupDataSeries(data) {
  return [
    data.childPatientsCount,
    data.adultPatientsCount,
    data.adolescentPatientsCount,
  ];
}

function getLast4WeeksDataSeries(formCompletionDates) {
  let series = [
    {
      name: "",
      data: [],
    },
  ];

  if (formCompletionDates.length === 0) {
    return series;
  }
  let last4Weeks = getLast4WeeksAsArray();
  let formsByWeekCount = [];
  for (let index1 in last4Weeks) {
    let week = last4Weeks[index1];
    let count = 0;
    for (let index2 in formCompletionDates) {
      let date = formCompletionDates[index2];
      // Subtracting and adding 1 day to the week start and end dates to check date is between them
      if (
        moment(date).isBetween(
          moment(week.start).subtract(1, "d"),
          moment(week.end).add(1, "d")
        )
      ) {
        count++;
      }
    }
    formsByWeekCount.push(count);
  }
  series[0].data = formsByWeekCount;
  return series;
}

function getLast4WeeksAsArray() {
  let last4WeeksAsArray = [];
  for (let week = 4; week >= 1; week--) {
    last4WeeksAsArray.push({
      start: moment()
        .subtract(7 * week, "d")
        .format("YYYY-MM-DD"),
      end: moment()
        .subtract(7 * (week - 1), "d")
        .subtract(1, "d")
        .format("YYYY-MM-DD"),
    });
  }

  return last4WeeksAsArray;
}

function getLast4WeeksAsXaxisCategories() {
  let last4WeeksAsCategories = [];
  for (let week = 4; week >= 1; week--) {
    last4WeeksAsCategories.push(
      moment()
        .subtract(7 * week, "d")
        .format("M/D") +
        " - " +
        moment()
          .subtract(7 * (week - 1), "d")
          .subtract(1, "d")
          .format("M/D")
    );
  }

  return last4WeeksAsCategories;
}

const HospitalStatsChartsUtils = {
  getPatientAgeGroupDataSeries,
  getLast4WeeksDataSeries,
  getLast4WeeksAsXaxisCategories,
};

export default HospitalStatsChartsUtils;
