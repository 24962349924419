import React, { useState, useCallback, useEffect, useRef } from "react";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import { useForm } from "react-hook-form";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import DoneIcon from "@mui/icons-material/Done";
import SaveIcon from "@mui/icons-material/Save";
import useAPI from "hooks/useAPI.js";
import DateUtils from "utils/DateUtils.js";
import InputErrorMessage from "components/utils/InputErrorMessage.js";
import SchedulingService from "services/SchedulingService";
import PopUpModal from "components/utils/PopUpModal.js";
import ConfirmActionModal from "components/modals/ConfirmActionModal";
import CALENDAR_EVENT_CANCEL_TYPES from "utils/constants/CalendarEventCancelTypes";

const UpdateEventModal = ({ hospitalId, eventId, onCloseHandler }) => {
  const [eventGuestList, setEventGuestList] = useState([]);
  const [eventGuest, setEventGuest] = useState("");
  const [isEventRecurring, setIsEventRecurring] = useState(false);
  const [eventCancelTypeEnum, setEventCancelTypeEnum] = useState(
    CALENDAR_EVENT_CANCEL_TYPES[0].typeEnum
  );

  const cancelEventModalRef = useRef(null);

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({ criteriaMode: "all", mode: "onTouched" });

  const fetchEventAPI = useAPI(
    SchedulingService.getEvents,
    fetchEventHandler,
    false
  );

  const updateEventAPI = useAPI(
    SchedulingService.updateEvent,
    updateEventHandler,
    false
  );

  const cancelEventAPI = useAPI(
    SchedulingService.cancelEvent,
    cancelEventHandler,
    false
  );

  useEffect(() => {
    fetchEvent();
  }, [hospitalId]);

  const fetchEvent = useCallback(() => {
    // Fetch patients only after hospital has been set
    if (hospitalId !== "") {
      fetchEventAPI.execute(hospitalId, eventId);
    }
  }, [fetchEventAPI]);

  function updateEvent(data) {
    data.eventGuests = eventGuestList.map((guest) => guest.email);

    data.startsAt = DateUtils.formatDateTimeToTimestamp(
      data.startDate + " " + data.startTime
    );
    data.endsAt = DateUtils.formatDateTimeToTimestamp(
      data.endDate + " " + data.endTime
    );

    data.isRecurring = false;

    updateEventAPI.execute(hospitalId, eventId, data);
  }

  const cancelEvent = (cancelTypeEnum) => {
    cancelEventAPI.execute(hospitalId, eventId, cancelTypeEnum);
  };

  /* API Success Handlers */

  function fetchEventHandler(response) {
    let data = response.data;

    data.startDate = DateUtils.formatUtcDateTimeToDateInput(data.startsAt);
    data.endDate = DateUtils.formatUtcDateTimeToDateInput(data.endsAt);

    data.startTime = DateUtils.formatUtcDateTimeToTimeInput(data.startsAt);
    data.endTime = DateUtils.formatUtcDateTimeToTimeInput(data.endsAt);

    reset(data);

    setIsEventRecurring(data.isRecurring);

    const eventGuests = data.eventGuests.map((guest) => {
      return {
        type: "guest",
        email: guest.email,
        isDisabled: true,
      };
    });

    setEventGuestList(eventGuests);
  }

  function updateEventHandler(response) {
    onCloseHandler();
  }

  function cancelEventHandler(response) {
    onCloseHandler();
  }

  return (
    <>
      <div className="my-2">
        <div
          className="d-flex justify-content-between align-items-center rounded p-3"
          style={{
            backgroundColor: "#eef3f8",
          }}
        >
          <Typography variant="h3">Update Event</Typography>
          {isEventRecurring ? (
            <div className="btn-group btn-group-sm" role="group">
              <button
                type="button"
                className="btn btn-outline-danger dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Cancel
              </button>
              <ul className="dropdown-menu">
                {CALENDAR_EVENT_CANCEL_TYPES.map((type, index) => {
                  return (
                    <li
                      key={`cancel-type-${index}`}
                      onClick={() => {
                        setEventCancelTypeEnum(type.typeEnum);
                        cancelEventModalRef.current.openModal();
                      }}
                    >
                      <span className="dropdown-item">{type.name}</span>
                    </li>
                  );
                })}
              </ul>
            </div>
          ) : (
            <Button
              type="button"
              size="small"
              color="error"
              variant="outlined"
              onClick={() => {
                cancelEventModalRef.current.openModal();
              }}
            >
              Cancel
            </Button>
          )}
        </div>
        <form className="m-3" onSubmit={handleSubmit(updateEvent)}>
          <div className="row">
            <div className="col col-12 mb-3">
              <label htmlFor="titleInput" className="form-label">
                Title
              </label>
              <input
                type="text"
                className="form-control"
                id="titleInput"
                {...register("title", {
                  required: "Title is required",
                })}
              />
              <InputErrorMessage name="title" errors={errors} />
            </div>
            <div className="col col-12 mb-3">
              <label htmlFor="descriptionInput" className="form-label">
                Description
              </label>
              <textarea
                rows={3}
                className="form-control"
                id="descriptionInput"
                {...register("description", {
                  required: "Description is required",
                })}
              />
              <InputErrorMessage name="description" errors={errors} />
            </div>
            <div className="col col-6 mb-3">
              <label htmlFor="startDateInput" className="form-label">
                Start Date
              </label>
              <input
                type="date"
                className="form-control"
                id="startDateInput"
                {...register("startDate", {
                  required: "Start date is required",
                })}
              />
              <InputErrorMessage name="startDate" errors={errors} />
            </div>
            <div className="col col-6 mb-3">
              <label htmlFor="endDateInput" className="form-label">
                End Date
              </label>
              <input
                type="date"
                className="form-control"
                id="endDateInput"
                {...register("endDate", {
                  required: "End date is required",
                })}
              />
              <InputErrorMessage name="description" errors={errors} />
            </div>
            <div className="col col-3 mb-3">
              <label htmlFor="startTimeInput" className="form-label">
                Start Time
              </label>
              <input
                type="time"
                className="form-control"
                id="startTimeInput"
                {...register("startTime", {
                  required: "Start time is required",
                })}
              />
              <InputErrorMessage name="startTime" errors={errors} />
            </div>
            <div className="col col-3 mb-3">
              <label htmlFor="endTimeInput" className="form-label">
                End Time
              </label>
              <input
                type="time"
                className="form-control"
                id="endTimeInput"
                {...register("endTime", {
                  required: "End time is required",
                })}
              />
              <InputErrorMessage name="endTime" errors={errors} />
            </div>
            <div className="col col-6 mb-3">
              <label className="form-label d-block">Location Type</label>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  id="locationTypeOnline"
                  name="locationType"
                  value="Online"
                  {...register("locationType", {
                    required: "Location type is required",
                  })}
                />
                <label
                  className="form-check-label"
                  htmlFor="locationTypeOnline"
                >
                  Online
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  id="locationTypeOffline"
                  name="locationType"
                  value="Offline"
                  {...register("locationType", {
                    required: "Location type is required",
                  })}
                />
                <label
                  className="form-check-label"
                  htmlFor="locationTypeOffline"
                >
                  Offline
                </label>
              </div>
              <InputErrorMessage name="locationType" errors={errors} />
            </div>
            <div className="col col-7 mb-3">
              <label htmlFor="eventGuestInput" className="form-label">
                Event Guests
              </label>
              <div className="d-flex flex-row">
                <input
                  type="text"
                  className="form-control"
                  id="eventGuestInput"
                  placeholder="Guest email id"
                  value={eventGuest}
                  onChange={(event) => {
                    setEventGuest(event.target.value);
                  }}
                />
                <Button
                  type="button"
                  className="ms-2"
                  size="small"
                  variant="outlined"
                  onClick={() => {
                    if (eventGuest != "") {
                      setEventGuestList((prevState) => {
                        return [
                          ...prevState,
                          {
                            type: "guest",
                            email: eventGuest,
                            isDisabled: false,
                          },
                        ];
                      });
                      setEventGuest("");
                    }
                  }}
                >
                  Add
                </Button>
              </div>
            </div>
            <div className="d-flex flex-wrap mb-3">
              {eventGuestList.map((guest, index) => {
                if (guest.isDisabled) {
                  return (
                    <Chip
                      key={index}
                      label={guest.email}
                      variant="outlined"
                      className="me-2 mb-2"
                    />
                  );
                }
                return (
                  <Chip
                    key={index}
                    label={guest.email}
                    variant="outlined"
                    className="me-2 mb-2"
                    onDelete={() => {
                      setEventGuestList((prevState) => {
                        let index = prevState.findIndex(
                          (guestItem) => guestItem.email === guest.email
                        );
                        prevState.splice(index, 1);
                        return [...prevState];
                      });
                    }}
                  />
                );
              })}
            </div>
          </div>
          <div className="text-end">
            {" "}
            <Button onClick={() => onCloseHandler()} variant="outlined">
              Close
            </Button>
            {updateEventAPI.status === "pending" ? (
              <LoadingButton
                loading
                loadingPosition="start"
                startIcon={<SaveIcon />}
                variant="contained"
                className="ms-2"
              >
                Update
              </LoadingButton>
            ) : (
              <Button
                type="submit"
                startIcon={<DoneIcon />}
                variant="contained"
                className="ms-2"
              >
                Update
              </Button>
            )}
          </div>
        </form>
      </div>
      <PopUpModal ref={cancelEventModalRef} modalWidth={400}>
        <ConfirmActionModal
          content="Do you want to confirm canceling the event?"
          confirmActionHandler={() => {
            cancelEvent(eventCancelTypeEnum);
            cancelEventModalRef.current.closeModal();
            onCloseHandler();
          }}
          onCloseHandler={() => cancelEventModalRef.current.closeModal()}
        />
      </PopUpModal>
    </>
  );
};

export default UpdateEventModal;
