import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

function ConfirmActionModal({ content, confirmActionHandler, onCloseHandler }) {
  return (
    <Box
      className="px-3 py-4"
      sx={{
        maxWidth: "350px",
      }}
    >
      <Typography id="modal-modal-title" variant="h3" color="text">
        {content}
      </Typography>
      <Stack direction="row" spacing={3} className="mt-4">
        <Button onClick={confirmActionHandler} variant="contained">
          Confirm
        </Button>
        <Button onClick={onCloseHandler} variant="outlined">
          Cancel
        </Button>
      </Stack>
    </Box>
  );
}
export default ConfirmActionModal;
