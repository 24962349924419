import { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "react-bootstrap/Card";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import RoomIcon from "@mui/icons-material/Room";
import ArrowCircleLeftRoundedIcon from "@mui/icons-material/ArrowCircleLeftRounded";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import "styles/patient-form.scss";
import PatientFormService from "services/PatientFormService.js";
import FamilyService from "services/FamilyService.js";
import FormUtils from "utils/FormUtils.js";
import EnumUtils from "utils/EnumUtils.js";
import { CardActionArea } from "@mui/material";
import useAPI from "hooks/useAPI.js";

const PatientFormsList = () => {
  const navigate = useNavigate();
  const { patientId } = useParams();
  const [formsList, setFormsList] = useState([]);
  const [patientDetails, setPatientDetails] = useState({
    patientId: "",
    patientName: "",
    hospitalName: "",
    ageGroup: "",
  });

  const getMemberBasicDetailsAPi = useAPI(
    FamilyService.getMemberBasicDetails,
    getMemberBasicDetailsHandler
  );
  const getFormsAPI = useAPI(
    PatientFormService.getRegistrationForms,
    getFormsHandler
  );

  function getMemberBasicDetails() {
    getMemberBasicDetailsAPi.execute(patientId);
  }

  function getForms() {
    getFormsAPI.execute(patientId);
  }

  useEffect(() => {
    getMemberBasicDetails();
    getForms();
  }, []);

  /* API Success Handlers */

  function getMemberBasicDetailsHandler(response) {
    let data = response.data;
    setPatientDetails(data);
  }

  function getFormsHandler(response) {
    setFormsList(response.data);
  }

  return (
    <div className="registration-forms-container">
      <h2>Forms to fill in before consultation</h2>
      <p>
        Please fill in the form below. That way the consultant will be prepared
        for your session.
      </p>
      <Grid container spacing={4}>
        <Grid item xs={9}>
          {getFormsAPI.status === "pending" ? (
            <div className="text-center">
              <CircularProgress
                className="text-center"
                sx={{
                  mt: 50,
                }}
              />
            </div>
          ) : (
            formsList.map((form, index) => (
              <Card
                key={index}
                className="card card-table-width auto-height"
                onClick={() =>
                  navigateToForm(
                    form.registrationFormTypeEnum,
                    form.formStatusTypeEnum,
                    patientId,
                    form.id
                  )
                }
              >
                <CardActionArea>
                  <Card.Body>
                    <div className="blue-font-color display-inline-block">
                      <ArrowRightIcon />
                      <span>
                        {EnumUtils.parse(form.registrationFormTypeEnum)}
                      </span>
                    </div>
                    <div className="display-inline">
                      <span className={`right-align height-2-rem large-fonts`}>
                        {FormUtils.getStatusChip(form.formStatusTypeEnum)}
                      </span>
                    </div>
                    <div className="margin-above">
                      <span>Saved on</span>
                      <span className="green-font">{form.lastUpdatedAt}</span>
                    </div>
                  </Card.Body>
                </CardActionArea>
              </Card>
            ))
          )}
        </Grid>
        <Grid item xs={3}>
          <Card className="card card-table-width border-color2 auto-height">
            <Card.Body>
              <div>
                <p className="purple-font-color">
                  Patient Id: {patientDetails.patientId}
                </p>
                <p className="purple-font-color margin-left-room">
                  <RoomIcon /> {patientDetails.hospitalName}
                </p>
                <p className="large-font">{patientDetails.patientName}</p>
                <p className="medium-font">{patientDetails.ageGroup}</p>
              </div>
            </Card.Body>
          </Card>
        </Grid>
        <Grid item xs={9}>
          {
            // <p className="doc-header">Documents uploaded by the parent</p>
            //         <Card className="full-width">
            //           <Card.Body className="flex">
            //             <div className="margin-btm-1">New Document</div>
            //             <div>
            //               <div className="fileUpload btn btn-primary">
            //                 <label className="upload">
            //                   <input name="Image" type="file" />
            //                   <ImageIcon />
            //                   Choose file
            //                 </label>
            //               </div>
            //               <br />
            //               <button className="submit-btn margin-top-1">Save</button>
            //             </div>
            //           </Card.Body>
            //         </Card>
          }
          <button className="back" onClick={() => navigate(-1)}>
            <ArrowCircleLeftRoundedIcon fontSize="medium" />
            Back
          </button>
        </Grid>
      </Grid>
    </div>
  );
};

function navigateToForm(
  patientFormTypeEnum,
  formStatus,
  patientId,
  registrationFormID
) {
  let viewMode = "write";
  if (formStatus === "SUBMITTED") viewMode = "read";

  if (patientFormTypeEnum === "DEMOGRAPHIC_INFO_CHECKLIST_CHILD") {
    window.open(
      `user-forms/patient-registration/child-demographic-info-checklist.html?patientID=${patientId}&registrationFormID=${registrationFormID}&viewMode=${viewMode}`,
      "_blank"
    );
  } else if (patientFormTypeEnum === "HISTORY_CHILD") {
    window.open(
      `user-forms/patient-registration/child-history.html?patientID=${patientId}&registrationFormID=${registrationFormID}&viewMode=${viewMode}`,
      "_blank"
    );
  } else if (patientFormTypeEnum === "HEMISPHERIC_CHECKLIST_CHILD") {
    window.open(
      `user-forms/patient-registration/child-hemispheric-checklist.html?patientID=${patientId}&registrationFormID=${registrationFormID}&viewMode=${viewMode}`,
      "_blank"
    );
  } else if (patientFormTypeEnum === "DEMOGRAPHIC_INFO_CHECKLIST_ADULT") {
    window.open(
      `user-forms/patient-registration/adult-demographic-info-checklist.html?patientID=${patientId}&registrationFormID=${registrationFormID}&viewMode=${viewMode}`,
      "_blank"
    );
  } else if (patientFormTypeEnum === "HISTORY_ADULT") {
    window.open(
      `user-forms/patient-registration/adult-history.html?patientID=${patientId}&registrationFormID=${registrationFormID}&viewMode=${viewMode}`,
      "_blank"
    );
  } else if (patientFormTypeEnum === "HEMISPHERIC_CHECKLIST_ADULT") {
    window.open(
      `user-forms/patient-registration/adult-hemispheric-checklist.html?patientID=${patientId}&registrationFormID=${registrationFormID}&viewMode=${viewMode}`,
      "_blank"
    );
  } else if (patientFormTypeEnum === "COGNITIVE_STYLE_ASSESSMENT_ADULT") {
    window.open(
      `user-forms/patient-registration/adult-cognitive-style-assessment.html?patientID=${patientId}&registrationFormID=${registrationFormID}&viewMode=${viewMode}`,
      "_blank"
    );
  } else if (patientFormTypeEnum === "SENSORY_FUNCTION_CHECKLIST_ADULT") {
    window.open(
      `user-forms/patient-registration/adult-sensory-function-checklist.html?patientID=${patientId}&registrationFormID=${registrationFormID}&viewMode=${viewMode}`,
      "_blank"
    );
  } else {
    alert("Invalid Patient Registration Form Type");
  }
}

export default PatientFormsList;
