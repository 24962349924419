import { useState } from "react";
import styles from "styles/header.module.css";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import PersonAdd from "@mui/icons-material/PersonAdd";
import FeedIcon from "@mui/icons-material/Feed";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import UserService from "services/UserService.js";
import StringUtils from "utils/StringUtils.js";
import Authenticator from "services/Authenticator.js";
import useAPI from "hooks/useAPI.js";
import { useSelector, useDispatch } from "react-redux";
import { selectAuthUser, updateAuthUser } from "state/slices/authUserSlice";

function PatientHeader() {
  let navigate = useNavigate();

  return (
    <div
      id={styles["header"]}
      className="d-flex w-100 flex-row no-wrap align-items-center justify-content-between"
    >
      <img
        id={styles["logo"]}
        src="assets/logo-color.webp"
        alt="Neuroelpis Logo"
      />
      <div className="d-flex flex-row no-wrap align-items-center justify-content-between">
        <Typography
          className="header-gap"
          sx={{
            minWidth: 100,
            ps: "35px",
          }}
          onClick={() => navigate("/patient")}
        >
          Dashboard
        </Typography>
        {/*
          <Typography className="header-gap" sx={{minWidth: 100}}>
            Book a Class
          </Typography>
          */}

        <UserDropdown />
      </div>
    </div>
  );
}

function UserDropdown() {
  let navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useAPI(UserService.getProfile, getUserProfileHandler, false, true);

  const authUser = useSelector(selectAuthUser);
  const dispatch = useDispatch();

  /* API success handlers */

  function getUserProfileHandler(response) {
    dispatch(updateAuthUser(response.data));
  }

  return (
    <>
      {" "}
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        {" "}
        <IconButton
          onClick={handleClick}
          size="small"
          sx={{
            ml: 2,
          }}
          aria-controls={open ? "account-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
        >
          <Avatar
            className="fs-5 "
            {...StringUtils.stringAvatar(
              `${authUser.firstName} ${authUser.lastName}`
            )}
          />
        </IconButton>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{
          horizontal: "right",
          vertical: "top",
        }}
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom",
        }}
      >
        <MenuItem onClick={() => navigate("/user/profile")}>
          <ListItemIcon>
            <AccountCircleRoundedIcon fontSize="medium" />
          </ListItemIcon>
          Profile
        </MenuItem>
        <Divider />
        <MenuItem onClick={() => navigate("/patient/family")}>
          <ListItemIcon>
            <PersonAdd fontSize="small" />
          </ListItemIcon>
          Family
        </MenuItem>
        <MenuItem onClick={() => navigate("/patient/assessments-and-forms")}>
          <ListItemIcon>
            <FeedIcon fontSize="small" />
          </ListItemIcon>
          Registration Forms
        </MenuItem>
        <Divider />
        <MenuItem onClick={() => navigate("/user/settings")}>
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          Settings
        </MenuItem>
        <MenuItem onClick={() => Authenticator.logout()}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </>
  );
}

export default PatientHeader;
