import React, { useState, useEffect, useCallback, useRef } from "react";
import styles from "styles/schedule-management.module.css";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import timeGridPlugin from "@fullcalendar/timegrid";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import Tooltip from "@mui/material/Tooltip";
import styled from "@emotion/styled";
import OrganizationService from "services/OrganizationService";
import SchedulingService from "services/SchedulingService";
import DateUtils from "utils/DateUtils.js";
import useAPI from "hooks/useAPI.js";
import PopUpModal from "components/utils/PopUpModal.js";
import Button from "@mui/material/Button";
import AddEventModal from "./AddEventModal";
import UpdateEventModal from "./UpdateEventModal";
import { useOutletContext } from "react-router-dom";

export const StyleWrapper = styled.div`
  .fc-toolbar-title {
    font-size: 18px;
    letter-spacing: 0px;
    color: #333333;
  }
  .fc-button-primary {
    background-color: #ffffff;
    color: #333333;
    border: none;
  }
  .fc .fc-button-primary:not(:disabled).fc-button-active {
    background-color: #005eab;
    border-radius: 4px;
  }
  .fc-button-primary:hover {
    background-color: #eff8ff;
    color: #333333;
  }
  .fc-button-primary:active:hover {
    background-color: #ffffff;
    color: #333333;
  }
  .fc-timegrid-event {
    border-radius: 8px;
  }
  .fc .fc-col-header-cell-cushion {
    font: normal normal normal 14px/19px Open Sans;
    letter-spacing: 0px;
    color: #333333;
    text-decoration: none;
  }
`;

function Calendar() {
  const [hospitalId] = useOutletContext();
  const [calendarEvents, setCalendarEvents] = useState([]);

  const [doctorList, setDoctortList] = useState([]);
  const [editEventId, setEditEventId] = useState();
  const [calendarViewDates, setCalendarViewDates] = useState(null);

  const addEventModalRef = useRef(null);
  const updateEventModalRef = useRef(null);

  const [addEventModalData, setAddEventModalData] = useState({
    startDate: "",
    startTime: "",
  });

  const fetchDoctorsAPI = useAPI(
    OrganizationService.getHospitalDoctors,
    fetchDoctorsHandler,
    false
  );

  const fetchCalendarEventsAPI = useAPI(
    SchedulingService.getEventsByHospital,
    fetchEventsHandler,
    false
  );

  useEffect(() => {
    if (doctorList.length === 0) {
      fetchDoctors();
    }

    fetchCalendarEvents();
  }, [hospitalId, calendarViewDates]);

  const fetchDoctors = useCallback(() => {
    fetchDoctorsAPI.execute(hospitalId);
  }, [fetchDoctorsAPI]);

  const fetchCalendarEvents = useCallback(
    (doctorId = "", patientId = "") => {
      // Fetch patients only after hospital has been set and calendarViewDates is not null
      if (calendarViewDates) {
        fetchCalendarEventsAPI.execute(
          hospitalId,
          calendarViewDates.start,
          calendarViewDates.end,
          doctorId,
          patientId
        );
      }
    },
    [fetchCalendarEventsAPI]
  );

  /* API Success Handlers */
  function fetchDoctorsHandler(response) {
    let doctors = [];
    response.data.map((doctor) =>
      doctors.push({
        ...doctor,
      })
    );

    setDoctortList(doctors);
  }

  function fetchEventsHandler(response) {
    let data = response.data;
    let events = [];

    data.map((event) => {
      if (event.statusType !== "CANCELED") {
        events.push({
          id: event.id,
          title: event.title,
          description: event.description,
          start: DateUtils.formatDateToCalendarInput(event.startsAt),
          end: DateUtils.formatDateToCalendarInput(event.endsAt),
          color: getRandomColor(),
        });
      }
    });
    setCalendarEvents(events);
  }

  function onDoctorFilterChange(event) {
    let doctorId = event.target.value;
    if (doctorId == 0) {
      fetchCalendarEvents();
    } else {
      fetchCalendarEvents(doctorId);
    }
  }

  function handleCalendarDateChange(dateInfo) {
    setCalendarViewDates({
      start: dateInfo.startStr.split("T")[0],
      end: dateInfo.endStr.split("T")[0],
    });
  }

  return (
    <div>
      <Paper
        elevation={0}
        className="d-flex flex-row justify-content-between align-items-center px-3 py-3"
        id={styles["header"]}
      >
        <select
          id={styles["scope-select-input"]}
          className="form-select"
          aria-label="Scope"
          defaultValue="0"
          onChange={onDoctorFilterChange}
        >
          <option value="0">All Events</option>
          {doctorList.map((doctor, index) => {
            return (
              <option key={index} value={doctor.id}>
                {doctor.firstName + " " + doctor.lastName}
              </option>
            );
          })}
        </select>
        {
          //   <Stack direction="row" spacing={2}>
          //   <Chip
          //     avatar={
          //       <Avatar
          //         sx={{ backgroundColor: "#018dff", color: "white !important" }}
          //       >
          //         5
          //       </Avatar>
          //     }
          //     label="Scheduled"
          //     variant="outlined"
          //     color="info"
          //     size="small"
          //     sx={{ backgroundColor: "white" }}
          //   />
          //   <Chip
          //     avatar={
          //       <Avatar
          //         sx={{ backgroundColor: "#018dff", color: "white !important" }}
          //       >
          //         4
          //       </Avatar>
          //     }
          //     label="Checked Out"
          //     variant="outlined"
          //     color="info"
          //     size="small"
          //     sx={{ backgroundColor: "white" }}
          //   />
          // </Stack>
        }
        <Button
          id={styles["table-add-record-btn"]}
          className="float-end"
          onClick={() => {
            addEventModalRef.current.openModal();
          }}
          startIcon={<img src="assets/plus-icon.svg" alt="Add icon" />}
          variant="contained"
        >
          Add Event
        </Button>
      </Paper>
      <Paper elevation={0} className="px-3 py-3 mt-3">
        <StyleWrapper>
          <FullCalendar
            plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin]}
            initialView="timeGridWeek"
            slotDuration="00:30:00"
            slotMinTime="06:00"
            slotMaxTime="22:00"
            height="600px"
            allDaySlot={false}
            slotLabelInterval={{ hours: 1, minutes: 0 }}
            headerToolbar={{
              start: "dayGridMonth,timeGridWeek,timeGridDay",
              center: "title",
            }}
            slotLabelFormat={{
              hour: "2-digit",
              minute: "2-digit",
              omitZeroMinute: true,
              meridiem: "short",
            }}
            eventTimeFormat={{
              hour: "2-digit",
              minute: "2-digit",
              meridiem: false,
              hour12: false,
            }}
            events={calendarEvents}
            nowIndicator={true}
            eventOverlap={false}
            slotEventOverlap={false}
            eventClick={function (arg) {}}
            datesSet={handleCalendarDateChange}
            dateClick={(arg) => {
              let date = arg.dateStr;
              let day = DateUtils.convertTimestampToDateInput(date);
              let time = DateUtils.convertTimestampToTimeInput(date);

              setAddEventModalData({
                startDate: day,
                endDate: day,
                startTime: time,
              });
              addEventModalRef.current.openModal();
            }}
            eventContent={function (arg) {
              let times = arg.timeText.split(" - ");

              let startTime = DateUtils.formatTime(times[0]);
              let endTime = times[1]
                ? " - " + DateUtils.formatTime(times[1])
                : "";

              return (
                <Tooltip title={arg.event.title} arrow>
                  <div
                    className="fc-event-main-frame p-1"
                    onClick={() => {
                      setEditEventId(arg.event.id);
                      updateEventModalRef.current.openModal();
                    }}
                  >
                    {arg.timeText && (
                      <div className="fc-event-time">
                        <Typography variant="h6">{arg.event.title}</Typography>
                        <Typography variant="caption">
                          {startTime + endTime}
                        </Typography>
                      </div>
                    )}
                    <div className="fc-event-title-container mt-1">
                      <div className="fc-event-title fc-sticky d-flex flex-row mb-1">
                        <Typography variant="caption">
                          {arg.event.description || <>&nbsp;</>}
                        </Typography>
                      </div>
                    </div>
                  </div>
                </Tooltip>
              );
            }}
          />
        </StyleWrapper>
      </Paper>
      <PopUpModal ref={addEventModalRef} modalWidth={700}>
        <AddEventModal
          hospitalId={hospitalId}
          data={addEventModalData}
          onCloseHandler={() => {
            addEventModalRef.current.closeModal();
            fetchCalendarEvents();
          }}
        />
      </PopUpModal>
      <PopUpModal ref={updateEventModalRef} modalWidth={700}>
        <UpdateEventModal
          hospitalId={hospitalId}
          eventId={editEventId}
          onCloseHandler={() => {
            updateEventModalRef.current.closeModal();
            fetchCalendarEvents();
          }}
        />
      </PopUpModal>
    </div>
  );
}

function getRandomColor() {
  const colors = [
    "#0882ff",
    "#ef6b9e",
    "#efd267",
    "#14607f",
    "#44bd83",
    "#1d7a73",
    "#925e27",
    "#20dabc",
    "#7d7575",
  ];
  let min = 0;
  let max = colors.length;
  let random = Math.random() * (max - min) + min;
  random = Math.floor(random);
  return colors[random];
}

export default Calendar;
