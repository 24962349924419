import axios from "axios";

class ZipcodeService {
  static getLocationDetails([zipCode]) {
    const options = {
      method: "GET",
      url: "https://maps.googleapis.com/maps/api/geocode/json",
      params: {address: zipCode, key: process.env.REACT_APP_G_MAPS_API_KEY}
    };

    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios.request(options);
        resolve(response);
      } catch (error) {
        reject(error.response);
      }
    });
  }
}

export default ZipcodeService;
