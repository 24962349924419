import {useState} from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";

function TableRowActionsCell(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (<Stack direction="row" spacing={1}>
    {
      props.actions.startItems.map((item, index) => {
        return (<IconButton key={"row-item-" + index} size="small" onClick={item.onClickHandler}>
          {item.component}
        </IconButton>);
      })
    }
    {
      (props.actions.menuItems.length === 0)
        ? null
        : <div>
            <IconButton aria-label="more" id="kebab-icon-btn" aria-controls={open
                ? "table-row-actions-menu"
                : undefined} aria-expanded={open
                ? "true"
                : undefined} aria-haspopup="true" onClick={handleClick}>
              <img src="assets/kebab-grey-icon.svg" alt="Table row actions menu icon"/>
            </IconButton>
            <Menu anchorEl={anchorEl} id="table-row-actions-menu" MenuListProps={{
                "aria-labelledby" : "kebab-icon-btn"
              }} open={open} onClose={handleClose} onClick={handleClose} PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  p: 0,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1
                  },
                  "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0
                  }
                }
              }} transformOrigin={{
                horizontal: "right",
                vertical: "top"
              }} anchorOrigin={{
                horizontal: "right",
                vertical: "bottom"
              }}>
              {
                props.actions.menuItems.map((item, index) => {
                  return (<MenuItem key={"row-menu-item-" + index} onClick={item.onClickHandler}>
                    {item.label}
                  </MenuItem>);
                })
              }
            </Menu>
          </div>
    }
  </Stack>);
}

export default TableRowActionsCell;
