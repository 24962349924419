import React, { Component } from "react";
import { withSnackbar } from "notistack";
import * as Sentry from "@sentry/react";
import SnackbarConstants from "utils/constants/Snackbar.js";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
    Sentry.captureException(error);
    console.log(error, errorInfo);
  }

  render() {
    // if (this.state.hasError) {
    //   // You can render any custom fallback UI
    //   return <h1>Something went wrong.</h1>;
    // }

    // Show error snackbar
    if (this.state.hasError) {
      this.props.enqueueSnackbar(
        "Oops! Something went wrong",
        SnackbarConstants.error
      );
    }

    return this.props.children;
  }
}

export default withSnackbar(ErrorBoundary);
