import React, { useState, useEffect, useCallback, useRef } from "react";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import EditIcon from "@mui/icons-material/Edit";
import PopUpModal from "components/utils/PopUpModal.js";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import styles from "styles/patients.module.css";
import SchedulingService from "services/SchedulingService";
import DateUtils from "utils/DateUtils.js";
import EnumUtils from "utils/EnumUtils";
import Chip from "@mui/material/Chip";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TableRowActionsCell from "components/utils/TableRowActionsCell.js";
import MUIDataTable from "mui-datatables";
import { ThemeProvider } from "@mui/material/styles";
import DATA_TABLE_THEME from "themes/DataTableTheme.js";
import CustomTabPanel from "components/utils/CustomTabPanel";
import useAPI from "hooks/useAPI.js";
import AddAppointmentModal from "./AddAppointmentModal";
import UpdateAppointmentModal from "./UpdateAppointmentModal";
import { useOutletContext } from "react-router-dom";

const Appointments = () => {
  const [hospitalId] = useOutletContext();
  const [appointments, setAppointments] = useState([]);

  const [editAppointmentId, setEditAppointmentId] = useState();
  const [editCalendarEventId, setEditCalendarEventId] = useState();
  const [pagination, setPagination] = useState({
    page: 0,
    size: 10,
    total: 0,
  });

  const [tabValue, setTabValue] = useState(0);

  const addAppointmentModalRef = useRef(null);
  const updateAppointmentModalRef = useRef(null);

  const fetchAppointmentsAPI = useAPI(
    SchedulingService.getAppointmentEventsByHospital,
    fetchAppointmentsHandler,
    false
  );

  const dataTableOptions = {
    filter: false,
    responsive: "standard",
    selectableRows: "none",
    print: false,
    serverSide: true,
    jumpToPage: true,
    search: false,
    count: pagination.total,
    page: pagination.page,
    rowsPerPage: pagination.size,
    onChangePage: (currentPage) => {
      pagination.page = currentPage;
      setPagination(pagination);
      fetchAppointments();
    },
    onChangeRowsPerPage: (rowsPerPage) => {
      pagination.size = rowsPerPage;
      setPagination(pagination);
      fetchAppointments();
    },
  };

  useEffect(() => {
    fetchAppointments();
  }, [hospitalId, tabValue]);

  const fetchAppointments = useCallback(() => {
    fetchAppointmentsAPI.execute(
      hospitalId,
      tabValue === 0,
      tabValue === 1,
      pagination.page,
      pagination.size
    );
  }, [fetchAppointmentsAPI]);

  /* API Success Handlers */
  function fetchAppointmentsHandler(response) {
    let data = response.data;

    pagination.total = data.totalElements;
    setPagination(pagination);

    let appointmentList = [];
    data.content.map((appointment, index) =>
      appointmentList.push(transformJsonData(appointment, index + 1))
    );
    setAppointments(appointmentList);
  }

  return (
    <div>
      <Button
        id={styles["table-add-record-btn"]}
        className="float-end"
        onClick={() => {
          addAppointmentModalRef.current.openModal();
        }}
        startIcon={<img src="assets/plus-icon.svg" alt="Add icon" />}
        variant="contained"
      >
        Create Appointment
      </Button>
      {fetchAppointmentsAPI.status === "pending" ? (
        <div className="text-center">
          <CircularProgress
            className="text-center"
            sx={{
              mt: 50,
            }}
          />
        </div>
      ) : (
        <>
          <Tabs
            value={tabValue}
            onChange={(event, newValue) => {
              setTabValue(newValue);
            }}
            aria-label="appointment event tabs"
          >
            <Tab
              label="Upcoming"
              id={`appointment-event-tab-0`}
              aria-controls={`appointment-event-tabpanel-0`}
            />
            <Tab
              label="Past"
              id={`appointment-event-tab-1`}
              aria-controls={`appointment-event-tabpanel-1`}
            />
          </Tabs>
          <CustomTabPanel value={tabValue} index={0}>
            <ThemeProvider theme={DATA_TABLE_THEME}>
              <MUIDataTable
                title={"Upcoming Appointments"}
                data={appointments}
                columns={dataTableColumns}
                options={dataTableOptions}
              />
            </ThemeProvider>
          </CustomTabPanel>
          <CustomTabPanel value={tabValue} index={1}>
            <ThemeProvider theme={DATA_TABLE_THEME}>
              <MUIDataTable
                title={"Past Appointments"}
                data={appointments}
                columns={dataTableColumns}
                options={dataTableOptions}
              />
            </ThemeProvider>
          </CustomTabPanel>
        </>
      )}
      <PopUpModal ref={addAppointmentModalRef} modalWidth={700}>
        <AddAppointmentModal
          hospitalId={hospitalId}
          onCloseHandler={() => {
            addAppointmentModalRef.current.closeModal();
            fetchAppointments();
          }}
        />
      </PopUpModal>
      <PopUpModal ref={updateAppointmentModalRef} modalWidth={700}>
        <UpdateAppointmentModal
          hospitalId={hospitalId}
          appointmentId={editAppointmentId}
          calendarEventId={editCalendarEventId}
          onCloseHandler={() => {
            updateAppointmentModalRef.current.closeModal();
            fetchAppointments();
          }}
        />
      </PopUpModal>
    </div>
  );

  function transformJsonData(appointment, index) {
    return {
      id: appointment.id,
      index,
      patientName: (
        <PatientLink
          patientId={appointment.patientId}
          patientName={appointment.patientName}
        />
      ),
      doctorName: appointment.doctorName,
      date: DateUtils.formatDateTimeToDate(appointment.calendarEvent.startsAt),
      time:
        DateUtils.formatDateTimeToTime(appointment.calendarEvent.startsAt) +
        " - " +
        DateUtils.formatDateTimeToTime(appointment.calendarEvent.endsAt),
      locationType: appointment.calendarEvent.locationType,
      status: <StatusChip status={appointment.calendarEvent.statusType} />,
      actions: (
        <ActionsCell
          appointmentId={appointment.id}
          calendarEventId={appointment.calendarEvent.id}
        />
      ),
    };
  }

  function ActionsCell({ appointmentId, calendarEventId }) {
    const actions = {
      startItems: [
        {
          component: <EditIcon fontSize="inherit" color="secondary" />,
          onClickHandler: () => {
            setEditAppointmentId(appointmentId);
            setEditCalendarEventId(calendarEventId);
            updateAppointmentModalRef.current.openModal();
          },
        },
      ],
      menuItems: [],
    };

    return <TableRowActionsCell actions={actions} />;
  }
};

const dataTableColumns = [
  {
    name: "patientName",
    label: "Patient",
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: "doctorName",
    label: "Doctor",
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: "date",
    label: "Date",
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: "time",
    label: "Time",
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: "locationType",
    label: "Location",
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: "status",
    label: "Status",
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: "actions",
    label: "Actions",
    options: {
      filter: false,
      sort: false,
      download: false,
    },
  },
];

function PatientLink({ patientId, patientName }) {
  let navigate = useNavigate();
  return (
    <Link
      onClick={() =>
        navigate("/hospital/emr-management/patients/" + patientId + "/profile")
      }
      component="button"
      underline="hover"
    >
      {patientName}
    </Link>
  );
}

function StatusChip({ status }) {
  if (status == "SCHEDULED") {
    return (
      <Chip
        label={EnumUtils.parse(status)}
        color="success"
        sx={{ lineHeight: "12px" }}
      />
    );
  } else if (status == "RESCHEDULED") {
    return (
      <Chip
        label={EnumUtils.parse(status)}
        color="secondary"
        sx={{ lineHeight: "12px" }}
      />
    );
  } else if (status == "CANCELED") {
    return (
      <Chip
        label={EnumUtils.parse(status)}
        color="error"
        sx={{ lineHeight: "12px" }}
      />
    );
  } else {
    return (
      <Chip
        label={EnumUtils.parse(status)}
        color="info"
        sx={{ lineHeight: "12px" }}
      />
    );
  }
}

export default Appointments;
