import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import {useState} from "react";

function Tabs(props) {
  const tabItems = props.itemNames;
  const [activeItem, setActiveItem] = useState(tabItems[0]);
  const tabViews = props.views;

  function TabPanel() {
    return (
      <Stack spacing={2} direction="row">
        {tabItems.map((item, i) => (
          <Button
            key={i}
            size="medium"
            color="secondary"
            variant={getTabButtonVariant(item)}
            onClick={() => changeTabview(item)}
          >
            <Typography variant="subtitle2">{item}</Typography>
          </Button>
        ))}
      </Stack>
    );

    function getTabButtonVariant(itemName) {
      if (itemName === activeItem) return "contained";
      return "outlined";
    }

    function changeTabview(navItem) {
      setActiveItem(navItem);
    }
  }

  function TabView() {
    return <div className="mt-3">{tabViews[getActiveTabIndex()]}</div>;
  }

  function getActiveTabIndex() {
    return tabItems.indexOf(activeItem);
  }

  return (
    <div>
      <TabPanel />
      <TabView />
    </div>
  );
}

export default Tabs;
