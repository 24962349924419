import "styles/App.css";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { Outlet } from "react-router-dom";

function AccountContainer() {
  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env.REACT_APP_G_RECAPTCHA_SITE_KEY}
    >
      <div className="container d-flex justify-content-center justify-content-xl-between align-items-center flex-wrap">
        <img
          id="hospital-illustration-img"
          src="../../assets/melillo_method_image.png"
          alt="Hospital Illustration"
        />
        <Outlet />
      </div>
    </GoogleReCaptchaProvider>
  );
}

export default AccountContainer;
