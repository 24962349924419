import { useState, forwardRef, useImperativeHandle } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";

let style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 2,
  p: "5px 15px",
  overflowX: "hidden",
  overflowY: "auto",
  maxHeight: "85%",
};

const PopUpModal = forwardRef((props, ref) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // Set Modal width sent via props
  style.minWidth = props.modalWidth;

  useImperativeHandle(ref, () => ({
    openModal(message, type) {
      handleOpen();
    },
    closeModal(message, type) {
      handleClose();
    },
  }));

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>{props.children}</Box>
      </Modal>
    </div>
  );
});

export default PopUpModal;
