import "styles/App.css";
import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import Header from "components/headers/Header.js";
import Footer from "components/footers/Footer.js";
import withRouter from "components/utils/withRouter";

function Home(props) {
  useEffect(() => {
    if (props.location.pathname === "/") {
      window.location = "/account";
    }
  }, [props.location.pathname]);

  return (
    <div className="d-flex justify-content-between flex-row no-wrap">
      <div className="flex-grow-1">
        <Header />
        <div id="home-container">
          <Outlet />
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default withRouter(Home);
