import ApiBaseService from "services/ApiBaseService";

class AuthService {
  static verifyCredentials(data, captchaToken) {
    const headers = {
      "Captcha-Token": captchaToken,
    };

    return ApiBaseService.request(
      "/login/verify-credentials",
      "POST",
      data,
      headers
    );
  }

  static authenticate(data, captchaToken) {
    const headers = {
      "Captcha-Token": captchaToken,
    };
    return ApiBaseService.request("/login/authenticate", "POST", data, headers);
  }

  static resendOTP(data, captchaToken) {
    const headers = {
      "Captcha-Token": captchaToken,
    };
    return ApiBaseService.request("/login/otp", "POST", data, headers);
  }
}

export default AuthService;
