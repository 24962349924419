import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

function AuditFieldsModal({ data }) {
  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={3}
        p={4}
      >
        <div>
          <Typography
            id="modal-modal-title"
            sx={{
              textDecoration: "underline",
            }}
            variant="body1"
            color="text"
          >
            Created At
          </Typography>
          <Typography
            id="modal-modal-description"
            className="mt-1"
            variant="subtitle2"
            color="text.dark"
          >
            {data.createdAt}
          </Typography>
        </div>
        <div>
          <Typography
            id="modal-modal-title"
            sx={{
              textDecoration: "underline",
            }}
            variant="body1"
            color="text"
          >
            Last Updated At
          </Typography>
          <Typography
            id="modal-modal-description"
            className="mt-1"
            variant="subtitle2"
            color="text.dark"
          >
            {data.lastUpdatedAt}
          </Typography>
        </div>
      </Stack>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={3}
        p={4}
      >
        <div>
          <Typography
            id="modal-modal-title"
            sx={{
              textDecoration: "underline",
            }}
            variant="body1"
            color="text"
          >
            Created By
          </Typography>
          <Typography
            id="modal-modal-description"
            className="mt-1"
            variant="subtitle2"
            color="text.dark"
          >
            {data.createdBy}
          </Typography>
        </div>
        <div>
          <Typography
            id="modal-modal-title"
            sx={{
              textDecoration: "underline",
            }}
            variant="body1"
            color="text"
          >
            Last Updated By
          </Typography>
          <Typography
            id="modal-modal-description"
            className="mt-1"
            variant="subtitle2"
            color="text.dark"
          >
            {data.lastUpdatedBy}
          </Typography>
        </div>
      </Stack>
    </>
  );
}

export default AuditFieldsModal;
