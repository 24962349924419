import "styles/App.css";
import AppRoutes from "routes/AppRoutes";
import {ThemeProvider} from "@mui/material/styles";
import BASE_THEME from "themes/BaseTheme";
import {SnackbarProvider} from "notistack";
import SnackbarConstants from "utils/constants/Snackbar.js";
import ErrorBoundary from "components/utils/ErrorBoundary";

function App() {
  
  return (
    <div className="App">
      <ThemeProvider theme={BASE_THEME}>
        <SnackbarProvider
          maxSnack={SnackbarConstants.maxSnack}
          autoHideDuration={SnackbarConstants.autoHideDuration}
          anchorOrigin={SnackbarConstants.anchorOrigin}
        >
          <ErrorBoundary><AppRoutes /></ErrorBoundary>
        </SnackbarProvider>
      </ThemeProvider>
    </div>
  );
}

export default App;
