import { useState, useEffect, useCallback } from "react";
import { Outlet, useLocation } from "react-router-dom";
import BreadCrumb from "components/utils/BreadCrumb.js";
import withRouter from "components/utils/withRouter.js";
import PathUtils from "utils/PathUtils.js";
import { useOutletContext } from "react-router-dom";

function EMRManagement() {
  const [breadCrumbPathElements, setBreadCrumbPathElements] = useState([
    "Home",
    "EMR Management",
    "Patients",
  ]);

  const location = useLocation();
  const [hospitalId] = useOutletContext();

  const updateBreadCrumbPath = useCallback(() => {
    let updatedBreadCrumbPathElements = PathUtils.extractBreadCrumbTokens(
      location.pathname
    );
    setBreadCrumbPathElements(updatedBreadCrumbPathElements);
  }, [location]);

  useEffect(() => {
    updateBreadCrumbPath();
  }, [location, updateBreadCrumbPath]);

  return (
    <div>
      <BreadCrumb pathElements={breadCrumbPathElements} />
      <Outlet context={[hospitalId]} />
    </div>
  );
}

export default withRouter(EMRManagement);
