import ApiBaseService from "services/ApiBaseService.js";

class OrganizationAdminService {
  static getUsers(patientId) {
    return ApiBaseService.request(`organizations/admin/users`, "GET");
  }

  static addUser([data]) {
    return ApiBaseService.request(`organizations/admin/users`, "POST", data);
  }

  static updateUser([userId, data]) {
    return ApiBaseService.request(
      `organizations/admin/users/${userId}`,
      "PUT",
      data
    );
  }

  static getUserRoleTypes() {
    return ApiBaseService.request(
      `organizations/admin/users/role-types`,
      "GET"
    );
  }

  static lockUser([userId]) {
    return ApiBaseService.request(
      `organizations/admin/users/${userId}/lock`,
      "POST"
    );
  }

  static unlockUser([userId]) {
    return ApiBaseService.request(
      `organizations/admin/users/${userId}/unlock`,
      "POST"
    );
  }

  static blockUser([userId]) {
    return ApiBaseService.request(
      `organizations/admin/users/${userId}/block`,
      "POST"
    );
  }
}

export default OrganizationAdminService;
