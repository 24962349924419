import { useState, useEffect, useRef } from "react";
import Paper from "@mui/material/Paper";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import styles from "styles/patients.module.css";
import PATIENT_MAIN_NAV_BAR_ITEMS from "utils/constants/PatientSideNavBarItems.js";
import { useNavigate, Outlet } from "react-router-dom";
import Badge from "@mui/material/Badge";
import { useParams } from "react-router";
import PatientService from "services/PatientService.js";
import StringUtils from "utils/StringUtils.js";
import EditIcon from "@mui/icons-material/Edit";
import Box from "@mui/material/Box";
import SideNavBar from "components/navs/SideNavBar.js";
import useAPI from "hooks/useAPI.js";
import { useOutletContext } from "react-router-dom";

function PatientDetail() {
  const [hospitalId] = useOutletContext();

  return (
    <div className="d-flex flex-row flex-nowrap justify-content-around">
      <div className="me-3">
        <PatientInfoCard hospitalId={hospitalId} />
        <SideNavBar
          items={PATIENT_MAIN_NAV_BAR_ITEMS}
          basePathElement={"patients"}
        />
      </div>
      <div className="flex-grow-1">
        <Outlet context={[hospitalId]} />
      </div>
    </div>
  );
}

function PatientInfoCard({ hospitalId }) {
  const theme = useTheme();
  const navigate = useNavigate();
  const inputFileRef = useRef(null);
  const { patientId } = useParams();

  const [patientDetails, setPatientDetails] = useState({
    fullName: "",
    email: "",
    patientId: "",
    profilePhoto: "",
  });

  const getPatientDetailsAPI = useAPI(
    PatientService.getPatient,
    getPatientDetailsHandler
  );
  const uploadProfilePhotoAPI = useAPI(
    PatientService.updatePatientProfilePhoto,
    updatePatientProfilePhotoHandler,
    true
  );

  function getPatientDetailsHandler(response) {
    let data = response.data;
    setPatientDetails({
      fullName: data.firstName + " " + data.lastName,
      email: data.email,
      patientId: data.id,
      profilePhoto: data.profilePhotoUrl,
    });
  }

  useEffect(() => {
    getPatientDetailsAPI.execute(hospitalId, patientId);
  }, []);

  function uploadProfilePhoto(file) {
    var formData = new FormData();
    formData.append("document", file);
    uploadProfilePhotoAPI.execute(hospitalId, patientId, formData);
  }

  function updatePatientProfilePhotoHandler(response) {
    navigate(0);
  }

  const onFileChange = (e) => {
    /* Selected files data can be collected here. */
    uploadProfilePhoto(e.target.files[0]);
  };
  const onEditIconClick = () => {
    /* Collecting node-element and performing click */
    inputFileRef.current.click();
  };

  return (
    <Paper
      id={styles["patient-details-card"]}
      elevation={0}
      className="px-3 py-3 mb-3"
    >
      <div className="row">
        <div className="col">
          <Badge
            overlap="circular"
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            badgeContent={
              <Box
                sx={{
                  p: 0.9,
                  width: 20,
                  height: 20,
                  borderRadius: 10,
                  backgroundColor: "primary.main",
                  "&:hover": {
                    backgroundColor: "secondary.main",
                  },
                }}
                onClick={onEditIconClick}
              >
                {" "}
                <EditIcon
                  fontSize="inherit"
                  sx={{
                    color: "white",
                  }}
                />
                <input
                  type="file"
                  ref={inputFileRef}
                  onChangeCapture={onFileChange}
                  hidden="hidden"
                />
              </Box>
            }
          >
            <Avatar
              id={styles["patient-profile-img"]}
              alt="Patient profile photo"
              src={patientDetails.profilePhoto}
              {...StringUtils.stringAvatar(patientDetails.fullName)}
            />
          </Badge>
        </div>
        <div
          className="col-7 ps-0"
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          <Typography variant="h5" mb={-1}>
            {patientDetails.fullName}
          </Typography>
          <Typography variant="caption">{patientDetails.email}</Typography>
          <Typography variant="h6" mt={1} color={theme.palette.text.main}>
            {patientDetails.patientId}
          </Typography>
        </div>
      </div>
    </Paper>
  );
}

export default PatientDetail;
