import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import Divider from "@mui/material/Divider";

function TermsOfService() {
  const theme = useTheme();
  return (
    <div className="container">
      <Typography variant="h1" color={theme.palette.text.secondary}>
        Terms of Service
      </Typography>
      <Divider className="mt-2" />
      <div>
        <i>Effective date: February 1, 2023</i>
        <br></br>
        <br></br>
        Welcome to the website (“website” or “site”) of <i>ACSESTECH LLC</i>
        (collectively “the Company,” “<i>ACSESTECH LLC</i>,” “Company” “we,”
        “our,” or “us”). <i>ACSESTECH LLC</i> is the owner of a certain
        proprietary web based application called “Neuroelpis.com” which is
        medical practice management software (the “App”). The website and App
        are collectively referred to as the “Services.” “Website Users” “Medical
        Patients” “Hospital Employees” and “Users” of the App or Services are
        collectively referred to as “You” “Your” or “User.”
        <br></br>
        <br></br>
        Please read the Terms of Service carefully before you start to use the
        Website or Services. By using the Website or Services, you accept and
        agree to be bound and abide by these Terms of Service and our Privacy
        Policy incorporated herein by reference (“Privacy Policy“) available at{" "}
        <a href="/privacy-policy">here</a>. If you do not want to agree to these
        Terms of Service or the Privacy Policy, you must not access or use the
        Website or Services.
        <br></br>
        <br></br>
        The following terms and conditions, together with any documents they
        expressly incorporate by reference (collectively, these “Terms of
        Service”), govern your (“Your,” “You,” or “User”) access to and use of
        this website (the “Website”) and use of the Services.
        <br></br>
        <br></br>
        This Website and Services are offered and available to users who are 18
        years old or older. By using this Website or Services you represent and
        warrant that you are of legal age to form a binding contract with the
        Company. If you are not 18 years old or older, you must not access or
        use the Website or Services. The App, website and Services are not a
        healthcare or medical app or site nor do the App or Services provide or
        offer any medical advice, counseling or treatment whatsoever for any
        type of medical diseases, ailments or injuries suffered by you. The App,
        website, services and Content are not intended to be a substitute for
        professional medical advice, diagnosis, or treatment. Always seek the
        advice of a medical doctor or other qualified health provider with any
        questions you may have regarding a medical condition. We also do not
        endorse or recommend any medical doctors or other qualified health
        providers who you might find while using the App, website or Services.
        <br></br>
        <br></br>
        IF YOU THINK YOU MAY HAVE A MEDICAL EMERGENCY PLEASE IMMEDIATELY GO TO
        THE NEAREST EMERGENCY HOSPITAL.
        <br></br>
        <br></br>
        ARBITRATION NOTICE AND CLASS ACTION WAIVER: EXCEPT FOR CERTAIN TYPES OF
        DISPUTES DESCRIBED IN THE ARBITRATION SECTION BELOW, YOU AGREE THAT
        DISPUTES BETWEEN YOU AND US WILL BE RESOLVED BY BINDING, INDIVIDUAL
        ARBITRATION AND YOU WAIVE YOUR RIGHT TO PARTICIPATE IN A CLASS ACTION
        LAWSUIT OR CLASS. BE ADVISED THAT <i>ACSESTECH LLC</i> DOES NOT CONDUCT
        ANY CRIMINAL BACKGROUND CHECKS ON ANYONE THAT USES THE APP OR SERVICES
        AND YOU AGREE THAT <i>ACSESTECH LLC</i> IS NOT RESPONSIBLE OR LIABLE FOR
        THE CONDUCT OR ACTIONS OF ANYONE THAT USES THE APP OR SERVICES THAT YOU
        MAY COME IN CONTACT WITH IN SEEKING TO USE THE APP OR SERVICES. You are
        solely responsible for all of your interactions with all Users of the
        App and Services that you contact, or that contact you. Please use
        caution with regard to the information you elect to share when you use
        the App or Services or in any communication you engage in with anyone
        that uses the App or Services. You should only list the contact
        information that you are comfortable disclosing to Us and other Users of
        the App and Services. When using the App and Services, you are
        responsible for ensuring that your use of the App and Services is in
        compliance with all applicable laws in the states you reside or do
        business in. WE OFFER NO GUARANTEES OR WARRANTIES THE RESULTS OF USING
        THE APP OR SERVICES WILL MEET ANY OF YOUR REQUIREMENTS. YOUR USE OF THE
        APP AND SERVICES IS SOLELY AT YOUR OWN RISK. YOU ARE SOLELY RESPONSIBLE
        FOR INPUTTING ANY PERTINENT INFORMATION ABOUT THE MEDICAL HEALTH OR
        CONDITION RELATED TO YOU INTO THE SERVICES AND ALSO CONVEYING THIS
        INFORMATION DIRECTLY TO ANY MEDICAL DOCTOR OR OTHER QUALIFIED HEALTH
        PROVIDER YOU COMMUNICATE WITH WHILE USING THE APP OR SERVICES.<br></br>
        <br></br>
        <b>HIPAA/HITECH COMPLIANCE</b>
        <br></br>
        <br></br>
        You hereby agree to fully comply with all provisions of the Health
        Insurance Portability and Accountability Act (“HIPAA”) as outlined in
        the applicable sections of US 45 CFR Subparts 160 and 164. You
        acknowledge that you have sole responsibility to determine any
        obligations you have under HIPAA, including any potential need to
        execute a Business Associate Agreement with us. You agree that willful
        violations under HIPAA constitute a material breach of these Terms. If
        you are an employee or independent contractor of any medical facility or
        hospital and have been given access to the Services you are required
        under HIPAA not to disclose any patient protected health information
        (PHI) to any individual or business entity if any medical patient has
        not consented in writing under HIPAA to the disclosure of such
        information to any such third parties.
        <br></br>
        <br></br>
        <b>1. Changes to the Terms of Service</b>
        <br></br>
        <br></br> We may revise and update these Terms of Service from time to
        time at our sole discretion. All changes are effective immediately when
        we post them. Your continued use of the Website and Services following
        the posting of revised Terms of Service means that you accept and agree
        to the changes. You are expected to check this page from time to time so
        that you are aware of any changes, as they are binding on you. <br></br>
        <br></br>
        <b>2. Accessing the Website and Account Security</b>
        <br></br>
        <br></br>
        We reserve the right to withdraw or amend this Website, and any service
        or material we provide on the Website, at our sole discretion without
        notice. We will not be liable if for any reason all or any part of the
        Website is unavailable at any time or for any period. From time to time,
        we may restrict access to some parts of the Website, or the entire
        Website, to users, including registered users. You are responsible for
        <br></br>
        <br></br>• Making all arrangements necessary for you to have access to
        the Website. <br></br>• Ensuring that all persons who access the Website
        through your internet connection are aware of these Terms of Service and
        comply with them. To access the Services and Website or some of the
        resources it offers, you may be asked to provide certain registration
        details or other information. It is a condition of your use of the
        Website that all the information you provide on the Website is correct,
        current, and complete. You agree that all information you provide to
        register with this Website or otherwise, including but not limited to
        through the use of any interactive features on the Website, is governed
        by our Privacy Policy, and you consent to all actions we take with
        respect to your information consistent with our Privacy Policy.
        <br></br>
        <br></br>
        We may, without notice to you, at any time, revise these Terms of
        Service and any other information contained in this Website. We may also
        make improvements or changes in the products, services, or programs
        described in this Website at any time without notice.
        <br></br>
        <br></br>
        <b>3. General</b>
        <br></br>
        <br></br>
        This Website contains proprietary notices and copyright information, the
        terms of which must be observed and followed.
        <br></br>
        <br></br>
        <b>4. Limited License and Acceptance</b>
        <br></br>
        <br></br>
        Subject to your continued compliance with the Terms of Use, including
        without limitation the timely payment of all applicable fees, we grant
        you a non-exclusive, non-transferable, non-sublicensable, fully
        revocable, limited license to access and use our Services (whether
        through the Website, or by downloading and installing the Mobile App,
        including any updates and bug fixes). Your use is limited for your
        personal, noncommercial use only. Your access to use of some of the
        Services is conditioned upon your payment of all fees due and owing to
        Us by you to access the Services. The Services, or any portion thereof,
        may not be reproduced, duplicated, copied, modified, sold, resold,
        distributed, visited, or otherwise exploited for any commercial purpose
        without the express written consent of Us. Furthermore, except for the
        limited rights granted in this Section, you will not (and will not
        encourage or assist any third party to): <br></br>(i) modify, alter,
        tamper with, repair or otherwise create derivative works of the Services
        or any software or technology included in or used or distributed by Us
        to provide the Services; or <br></br>(ii) reverse engineer, disassemble
        or decompile the Services, or attempt to discover or recreate the source
        code for the Services.
        <br></br>
        <br></br>
        You may only use a crawler to crawl this Website as permitted by this
        Website’s robots.txt protocol, and We may block any crawlers in Our sole
        discretion. The use authorized under this agreement is non-commercial in
        nature (e.g., you may not sell the content you access on or through this
        Web site.) All other use of this site is prohibited. All rights not
        expressly granted herein are reserved. You may not copy, modify,
        distribute, sell, or lease any part of our Website or Services, nor may
        you reverse engineer or attempt to extract the source code of that
        software, unless applicable laws prohibit these restrictions or you have
        our written permission to do so.
        <br></br>
        <br></br>
        You agree that at any time in our sole discretion, without notice to you
        and without liability of any kind, we may make improvements and/or
        changes to this Website, restrict or terminate your access to or use of
        any part or all of the Website, or refuse, move, or remove any material
        that you submitted to the Website. Any software that we provide you may
        automatically download and install upgrades, updates, or other new
        features. You may be able to adjust these automatic downloads through
        your device's settings. You may not copy, modify, distribute, sell, or
        lease any part of our Services, nor may you reverse engineer or attempt
        to extract the source code of that software, unless applicable laws
        prohibit these restrictions or you have our written permission to do so.
        Except for the limited permission in the preceding paragraphs, the
        Company does not grant you any express or implied rights or licenses
        under any patents, trademarks, copyrights, or other proprietary or
        intellectual property rights. You may not mirror any of the content from
        this Website on another Website or in any other media. Any software and
        other materials that are made available for downloading, access, or
        other use from this Website with their own license terms will be
        governed by such terms, conditions, and notices. Your failure to comply
        with such terms or any of the terms on this Website will result in
        automatic termination of any rights granted to you, without prior
        notice, and you must immediately destroy all copies of downloaded
        materials in your possession, custody or control.
        <br></br>
        <br></br>
        <b>5. Use With Your Mobile Device</b>
        <br></br>
        <br></br>
        Use of the Website and Services may be available through a compatible
        mobile device (“Device”), Internet access and may require software. You
        agree that you are solely responsible for these requirements, including
        any applicable changes, updates and fees as well as the terms of your
        agreement with your mobile device and telecommunications provider.
        <br></br>
        <br></br>We MAKES NO WARRANTIES OR REPRESENTATIONS OF ANY KIND, EXPRESS,
        STATUTORY OR IMPLIED AS TO:<br></br>
        <br></br>
        A. THE AVAILABILITY OF TELECOMMUNICATION SERVICES FROM YOUR PROVIDER AND
        ACCESS TO THE SERVICES AT ANY TIME OR FROM ANY LOCATION; B. ANY LOSS,
        DAMAGE, OR OTHER SECURITY INTRUSION OF THE TELECOMMUNICATION SERVICES;
        AND C. ANY DISCLOSURE OF INFORMATION TO THIRD PARTIES OR FAILURE TO
        TRANSMIT ANY DATA, COMMUNICATIONS OR SETTINGS CONNECTED WITH THE
        SERVICES. <br></br>
        <br></br>5.1 When using Your Devices hereunder: <br></br>
        <br></br>(i) you are responsible for the acquisition, cost and
        maintenance of Your Devices as well as any necessary wireless data plan;
        and <br></br>(ii) We shall make available the App for installation on
        Your Device. We hereby grant you a personal, non-exclusive,
        non-transferable license to install and use the App on Your Device
        solely for the purpose of seeking to use the Services. You agree to not
        provide, distribute or share, or enable the provision, distribution or
        sharing of, the App (or any data associated therewith) with any third
        party. You agree that: <br></br>(i) use of the App on Your Device
        requires an active data plan with a wireless carrier associated with
        Your Device, which data plan will be provided by you at your own
        expense; and <br></br>(ii) use of the App on Your Device as an interface
        with the Services may consume very large amounts of data through the
        data plan. WE SHALL NOT BE RESPONSIBLE OR LIABLE FOR ANY FEES, COSTS, OR
        OVERAGE CHARGES ASSOCIATED WITH ANY DATA PLAN. <br></br>
        <br></br>5.2 Downloading Mobile App. <br></br>
        <br></br>At some point in time, you may be able to access a Mobile App
        which can be downloaded from an applications store or applications
        distribution Services, such as the Apple Mobile App Store or Google
        Play, (the “Mobile App Provider”). You acknowledge and agree that:{" "}
        <br></br>(i) these Terms & Conditions are entered into between us, and
        not with the Mobile App Provider, and that we are solely responsible for
        the Mobile App (not the Mobile App Provider); <br></br>
        (ii) the Mobile App Provider has no obligation to furnish any
        maintenance and support services with respect to the Mobile App;{" "}
        <br></br>(iii) the Mobile App Provider is not responsible for addressing
        any claims you have or any claims of any third party relating to the
        Mobile App or your possession and use of the Mobile App, including, but
        not limited to: <br></br>(a) product liability claims; <br></br>(b) any
        claim that the Mobile App fails to conform to any applicable legal or
        regulatory requirement; and <br></br>(c) claims arising under consumer
        protection or similar legislation;
        <br></br>(iv) in the event of any third party claim that the Mobile App
        or your possession and use of that Mobile App infringes that third
        party’s intellectual property rights, we will be responsible for the
        investigation, defense, settlement and discharge of any such
        intellectual property infringement claim to the extent required by these
        Terms & Conditions; <br></br>(v) the Mobile App Provider and its
        subsidiaries are third party beneficiaries of these Terms & Conditions
        as it relates to your license of the Mobile App, and that, upon your
        acceptance of these Terms & Conditions, the Mobile App Provider will
        have the right (and will be deemed to have accepted the right) to
        enforce these Terms & Conditions as related to your license of the
        Mobile App against you as a third party beneficiary thereof; and{" "}
        <br></br>(vi) you must also comply with all applicable third party terms
        of service when using the Services. 5.3 Notice Regarding Apple. You
        acknowledge that these Terms are between you and <i>ACSESTECH LLC</i>{" "}
        only, not with Apple, and Apple is not responsible for any App or the
        content thereof. Apple has no obligation whatsoever to furnish any
        maintenance and support services with respect to any App. In the event
        of any failure of any App to conform to any applicable warranty, then
        you may notify Apple and request that Apple refund the purchase price
        for the relevant App to you; and, to the maximum extent permitted by
        applicable law, Apple has no other warranty obligation whatsoever with
        respect to any App. Apple is not responsible for addressing any claims
        by you or any third party relating to any App or your possession and/or
        use of any App, including, but not limited to:
        <br></br>(i) product liability claims; <br></br>(ii) any claim that any
        App fails to conform to any applicable legal or regulatory requirement;
        and <br></br>(iii) claims arising under consumer protection or similar
        legislation. Apple is not responsible for the investigation, defense,
        settlement, and discharge of any third party claim that any App or your
        possession and use of any App infringes that third party’s intellectual
        property rights. You will comply with any applicable third-party terms,
        when using any App. Apple, and Apple’s subsidiaries, are third-party
        beneficiaries of this section of these Terms, and upon your acceptance
        of these Terms, Apple will have the right (and will be deemed to have
        accepted the right) to enforce this section of these Terms against you
        as a third-party beneficiary of these Terms. You are responsible for any
        mobile charges that you may incur for using our Services, including
        text-messaging and data charges. If you're unsure what those charges may
        be, you should ask your service provider before using the Services. You
        hereby represent and warrant that <br></br>(a) you are not located in a
        country that is subject to a U.S. Government embargo, or that has been
        designated by the U.S. Government as a “terrorist supporting” country;
        and <br></br>(b) you are not listed on any U.S. Government list of
        prohibited or restricted parties. If We provide a translation of the
        English language version of these Terms, the translation is provided
        solely for convenience, and the English version will prevail. 5.4
        Third-Party Software. The software you download in connection with any
        App consists of a package of components, including certain third-party
        software provided under separate license terms. Your use of the
        third-party software in conjunction with the App in a manner consistent
        with the terms of these Terms is permitted, however, you may have
        broader rights under the applicable third-party terms and nothing in
        these Terms is intended to impose further restrictions on your use of
        the third-party software.
        <br></br>
        <br></br>
        <b>6. USER REGISTRATION</b>
        <br></br>
        <br></br>
        You may be required to register to use the Site and/or Services. You
        agree to keep your password confidential and will be responsible for all
        use of your account and password. You may not share your login
        credentials with anyone under any circumstances. We reserve the right to
        remove, reclaim, or change a username you select if we determine, in our
        sole discretion, that such username is inappropriate, obscene, or
        otherwise objectionable. If you have registered to use the Site or
        Services for your business then you shall also be jointly and separately
        responsible to ensure all subordinate users created under your account,
        by you or by another authorized user, comply with these Terms.
        Furthermore, you shall provide us immediate notice should you discover
        that any other users have violated these Terms. You also shall ensure
        that all user accounts provide the real legal name of all individuals as
        provided by their government-issued ID or passport. Nicknames and
        aliases used by users may also be supplemented by using the "Nickname"
        field provided within the <i>ACSESTECH LLC</i> Service, so long as the
        names are appropriate and commonly used by the user.
        <br></br>
        <br></br>
        <b>7. USER IDENTIFY VERIFICATION</b>
        <br></br>
        <br></br>
        You agree that you and any other user registered to use the service and
        associated with your account may be required to complete an ID
        verification through a service provided by Stripe should there be any
        changes in compliance requirements or any concerns regarding the user's
        access to the site and service. This may include, but a not limited to;
        suspicious access/logins, questionable user names or profile photos, or
        third-party reports about users' identity. The standard fee shall be
        accessed for each ID verification whether the verification process is
        initiated by you, another user of your account, or by{" "}
        <i>ACSESTECH LLC</i>.<br></br>
        <br></br>
        <b>8. HELP & SUPPORT POLICY</b>
        <br></br>
        <br></br>
        You agree that you and any other user registered to use the service
        shall use the appropriate designed channels for customer service which
        primarily consists of specific group emails and online help portals, to
        which the group emails are linked to. The use of direct emails for
        specific employees of <i>ACSESTECH LLC</i> is generally not considered.
        a proper channel for customer service. You agree that any such emails
        are subject to be filtered, unread, archived, and ultimately not
        responded to.
        <br></br>
        <br></br>
        <b>9. BUSINESS REQUIREMENTS & LEGAL COMPLIANCE</b>
        <br></br>
        <br></br>
        You are required to ensure your organization and business comply with
        all applicable local, state, and federal laws. In particular, you may
        only use the service in connection with services physically provided in
        the States (and local jurisdictions where required) in which you are
        legally registered to do business. Furthermore, you are required to
        obtain and provide to us any and all relevant documentation upon
        request. You understand that we may impose limitations, suspend, or
        terminate the service for any reasonable or timely failure to respond to
        such requests that prove compliance with the above. This includes
        providing National Provider ID Number(s) issued by NPPES, required State
        Business Registration and License Numbers/IDs, and ensuring the accuracy
        of any such records. You also hereby agree to notify us of any changes
        regarding any owners or shareholders who hold 5% or more of your
        company, as well as to notify us of any changes to the type/structure of
        your business (ie. LLC, Corporation, state of incorporation, etc). You
        also agree that should any director, officer, employee, agent, or
        contractor, be found to have been sanctioned as part of the OIG
        Exclusion, SAM, or State Medicaid Exclusions lists, you shall
        immediately provide written notice to <i>ACSESTECH LLC</i>.<br></br>
        <br></br>
        <b>
          10. USE AND DISCLOSURE REQUIREMENTS TO PREVENT FRAUD, WASTE, AND ABUSE
        </b>
        <br></br>
        <br></br>
        In order to comply and ensure the <i>ACSESTECH LLC</i> services are used
        in a manner that does not involve fraud, waste, or abuse as outlined
        under respective state laws and the federal False Claims Act, you are
        required to provide and accurately update us on all fee and rates
        related to your transportation services. This means any use of the
        service that is directly or indirectly connected to any federal program
        (ie Medicaid, Medicare, Tricare, etc.) requires that all rate
        information be provided and properly configured within the{" "}
        <i>ACSESTECH LLC</i> system. Any direct source (ie Private Pay) and
        other funding sources are then also required so as to ensure that any
        rates with non-government contracts are not higher than your direct
        private market rates. Generally, having higher rates with government
        programs than your own direct source rates is a violation of state and
        federal law within most transportation programs. This ensures{" "}
        <i>ACSESTECH LLC</i> is not involved in facilitating an operator in
        violation of these provisions.
        <br></br>
        <br></br>
        <b>11. SHARING SERVICES WITH NON-PARTIES</b>
        <br></br>
        <br></br>
        Under no circumstances are you permitted to allow any access to the
        services provided to you to any other business entity or their
        employees/agents, or individuals who are not employees of your
        organization, without the express written permission from our Chief
        Executive Officer or General Counsel (or outside counsel). This also
        applies to the use of contractors under 1099, as they are legally
        considered another entity outside of your company, and could be subject
        to requirements under HIPAA/HITECH for both you and us as it pertains to
        a required Business Associate Agreement. If you use or wish to use any
        such services then proper arrangements and review are required to as to
        ensure our respective compliance requirements under HIPAA/HITECH.
        VIOLATION OF THIS PROVISION SHALL CONSTITUTE A MATERIAL BREACH OF THIS
        AGREEMENT AND SUBJECT YOU TO A SERVICE VIOLATION FEE OR TO LIQUIDATED
        DAMAGES OF NO LESS THAN $2,500, OR NO LESS THAN $5,000 IF THE VIOLATION
        INVOLVED WILLFUL NEGLIGENCE, IN ADDITION TO ACTUAL DAMAGES, ATTORNEY AND
        COURT COSTS, ETC.
        <br></br>
        <br></br>
        <b>12. SHARED MANAGEMENT</b>
        <br></br>
        <br></br>
        If your business comingles services provided by similar or shared
        management and administration, such information shall also be disclosed
        to us and would be subject to the provisions of SHARING SERVICES WITH
        NON-PARTIES, requiring express written approval from our Chief Executive
        Officer or General Counsel (or outside counsel).
        <br></br>
        <br></br>
        <b>13. Disclaimer</b>
        <br></br>
        <br></br>
        From time to time, this Website may contain technical inaccuracies or
        typographical errors, and we do not warrant the accuracy of any posted
        information. Please confirm you are using the most up-to-date pages on
        this Website, and confirm the accuracy and completeness of information
        before using it to make decisions relating to services, products, or
        other matters described in this Website.
        <br></br>
        <br></br>
        If any term in this Terms of Service is found by competent judicial
        authority to be unenforceable in any respect, the validity of the
        remainder of this Terms of Service will be unaffected, provided that
        such unenforceability does not materially affect the parties’ rights
        under this Terms of Service.
        <br></br>
        <br></br>
        <b>14. Text Messaging</b>
        <br></br>
        <br></br>
        <i>ACSESTECH LLC</i> may send you recurring, automatic “Text Messages”.
        Your consent to receive Text Messages is not a condition of using the
        App or Services, and you can cancel this service at any time. You will
        receive these Text Messages if you provide your consent when you provide
        <i>ACSESTECH LLC</i> with your phone number when signing up for or
        registering to use the App or Services. Once your consent is provided,{" "}
        <i>ACSESTECH LLC</i>
        will send informational Text Messages, until you opt out. See below for
        details on how to opt out.
        <br></br>
        <br></br>
        In addition, if you provide further consent (for example, by checking
        the opt-in checkbox), you will receive Text Messages from{" "}
        <i>ACSESTECH LLC</i>
        on behalf of <i>ACSESTECH LLC</i> and /or other organizations that
        ACSESTECH LLC believes may be of interest to you such as non-emergency
        medical transport NEMT provider companies that may pick you up and
        transport you to non-emergency healthcare providers.
        <br></br>
        <br></br>
        You may receive Text Messages even if your phone number is on a
        corporate, state, or national Do Not Call list or registry. You
        understand that you are not required to provide such further consent to
        receive Text Messages as a condition of using the Site, and you can
        opt-out of receiving Text Messages at any time by texting STOP. For
        Help, text HELP or contact us at: <i>SWAPNA.ATMAKURI@ACSESTECH.COM</i>
        <br></br>
        <br></br>
        Message and data rates may apply. Neither <i>ACSESTECH LLC</i> nor the
        participating carriers guarantee that Text Messages will be delivered.
        <br></br>
        <br></br>
        <b>15. Your Compliance with Texting/SMS Laws</b>
        <br></br>
        <br></br>
        You represent, warrant, and agree that: <br></br>(i) you or your
        business shall not send text messages to anyone who uses the App or
        Services on their mobile devices without their explicit written consent;{" "}
        <br></br>(ii) You or your business shall obtain written consent from
        anyone who uses the App either <br></br>(a) by anyone who uses the site
        texting a keyword from their mobile device to join an SMS database;{" "}
        <br></br>(b) anyone who uses the App filling out a paper form or You or
        your business sending an online form to anyone who uses the site which
        explicitly states that they are subscribing to receive text messages
        from you once they provide their phone number. <br></br>(c) As anyone
        who uses the App opt in to SMS marketing campaigns created by You or
        your business, your business shall send clear, conspicuous disclosure of
        the text messages to them that they will receive. <br></br>(d) You or
        your business shall send instructions to anyone who uses the site on how
        to opt out from receiving messages (STOP instructions), as well as how
        they can get help information (HELP instructions). <br></br>(e) Your
        business is required to have a privacy policy conspicuous disclosed on
        your business’ website containing a Text/SMS policy.
        <br></br>
        <br></br>
        <b>16. Tracking Communications</b>
        <br></br>
        <br></br>
        In order to ensure appropriate charging of Fees and to enforce these
        Terms, We may track the occurrence of communications between users that
        occur off of the Services via email, phone call, SMS text message,
        third-party websites, or any other means. We may use a third-party
        Service Pro to track these text messages. We track these text messages
        for fraud prevention, to ensure appropriate charging of Fees, to enforce
        these Terms, and for quality and training purposes. As part of this
        process, We will receive in real time and store data about your text
        message, including the date and time of the text message, your phone
        number, and the content of the text message.
        <br></br>
        <br></br>
        <b>17. The Content of Others</b>
        <br></br>
        <br></br>
        Much of the content (“content”) on our Services is produced by users and
        other third parties (“third party content”) and may contain content or
        materials (“materials”) from publishers or other Users. Whether that
        content is posted publicly or sent privately, the content is the sole
        responsibility of the person or organization that submitted it. Although
        <i>ACSESTECH LLC</i> reserves the right to review all content that
        appears on the Services and to remove any content that violates these
        Terms, we do not necessarily review all of it. So we cannot—and do
        not—take responsibility for any content that others provide through the
        Services. Any opinions, advice, statements, services, offers, or other
        information or content expressed or made available by Users and third
        parties, including information providers, or any other users of the
        Website, are those of the respective author(s) or distributor(s) and not
        of <i>ACSESTECH LLC</i>. You are responsible for your use of the website
        and Services and for any Content you provide, including compliance with
        applicable laws, rules, and regulations. As such, you should only
        provide Content that you are comfortable sharing with others. Any use or
        reliance on any Content or materials posted via the website or Services
        or obtained by you through the website or Services is at your own risk.
        We do not endorse, support, represent or guarantee the completeness,
        truthfulness, accuracy, or reliability of any Content or communications
        posted via the website or Services or endorse any opinions expressed via
        the website or Services. You understand that by using the website or
        Services, you may be exposed to Content that might be offensive,
        harmful, inaccurate or otherwise inappropriate, or in some cases,
        postings that have been mislabeled or are otherwise deceptive. All
        Content is the sole responsibility of the person who originated such
        Content. We may not monitor or control the Content posted via the
        website or Services, and we cannot take responsibility for such Content.
        <br></br>
        <br></br>
        We will respond to notices of alleged copyright infringement that comply
        with applicable law and are properly provided to us, as described in our
        Copyright policy. Through these Terms, we make clear that we do not want
        the Services put to bad uses. But because we do not review all content,
        we cannot guarantee that content on the Services will always conform to
        our Terms. You may be able to post User Content. You must not post any
        User Content that is threatening, abusive, unlawful, misleading,
        fraudulent, discriminatory, libelous, defamatory, obscene or otherwise
        objectionable, or that contains ethnic, sexual, racial or other
        discriminating slurs. You must also not post any User Content that
        contains proprietary information, trade secrets, confidential
        information, solicitations, advertisements, chain letters, pyramid
        schemes, investment opportunities, or other unsolicited commercial
        communication (except as otherwise expressly permitted by us in
        writing), or encourages or causes spamming or flooding. You are
        prohibited from posting any User Content containing state, government or
        federal identification information or numbers (whether your own or of
        another person), such as national identification number, social security
        number, passport number, insurance number, driver’s license number,
        immigration number, or any other similar number, code, or identifier.
        Posting such identification information could possibly cause identity
        theft. The Company may remove any such identification information, but
        we are under no obligation to do so and have no responsibility and
        disclaim all liability or damages for any user posting of such
        identification information. The Company reserves the right to disclose
        all User Content and other relevant or related information, and the
        circumstances surrounding their transmission, to any third party in
        connection with operating the Website; to protect itself, its
        affiliates, its partners and its users or visitors; and to comply with
        legal obligations or governmental requests. What this means is that we
        may honor law enforcement or court-mandated requests such as subpoenas
        or search warrants to reveal a user’s electronic address and identity,
        or other properly requested information. Although we have no obligation
        to do so, we may monitor User Content, and reserve the right to delete
        any User Content or portion thereof that, at the Company’s sole
        discretion, violates the above rules, including any User Content that is
        unrelated to the specific area of the Website on which it is posted, or
        that is an advertisement or other commercial message, or that the
        Company determines at its sole discretion to be inappropriate. If you
        believe that any User Content violates this Agreement or our policies,
        we encourage you to report the content or conduct to us so that we may
        have the opportunity to consider whether to remove the content or not.
        Just so there is no ambiguity in these terms and conditions, the Company
        does not have any obligation to remove any User Content, and whether
        User Content is deemed to be inappropriate or violates any of the
        Company’s policies will always remain within the sole discretion of the
        Company.
        <br></br>
        <br></br>Please take note of the following: Section 230 of the U.S.
        Communications Decency Act provides that: <br></br>(1) Treatment of
        publisher or speaker No provider or user of an interactive computer
        service shall be treated as the publisher or speaker of any information
        provided by another information content provider. <br></br>(2) Civil
        liability
        <br></br>
        <br></br>No provider or user of an interactive computer service shall be
        held liable on account of- <br></br>(A) any action voluntarily taken in
        good faith to restrict access to or availability of material that the
        provider or user considers to be obscene, lewd, lascivious, filthy,
        excessively violent, harassing, or otherwise objectionable, whether or
        not such material is constitutionally protected; or <br></br>(B) any
        action taken to enable or make available to information content
        providers or others the technical means to restrict access to material
        described in paragraph (A).
        <br></br>
        <br></br>
        <b>18. License to User Content and Feedback</b>
        <br></br>
        <br></br>
        You retain your rights to any Content you submit, post or display on or
        through the website or Services. What’s yours is yours — you own your
        Content (and your photos and videos are part of the Content). By
        submitting, posting or displaying Content on or through the Website or
        Services, you grant us a worldwide, non-exclusive, royalty-free license
        (with the right to sublicense) to use, copy, reproduce, process, adapt,
        modify, publish, transmit, display and distribute such Content in any
        and all media or distribution methods (now known or later developed).
        This license authorizes us to make your Content available to the rest of
        the world and to let others do the same. You agree that this license
        includes the right for <i>ACSESTECH LLC</i> to provide, promote, and
        improve the Website or Services and to make Content submitted to or
        through the Website or Services available to other companies,
        organizations, or individuals for the syndication, broadcast,
        distribution, promotion, or publication of such Content on other media
        and services, subject to our terms and conditions for such Content use.
        Such additional uses by <i>ACSESTECH LLC</i>, or other companies,
        organizations or individuals, may be made with no compensation paid to
        you, or to any of your relatives, spouses, or your heirs with respect to
        the Content that you submit, post, transmit or otherwise make available
        through the Website or Services. You represent and warrant that you have
        all the rights, power, and authority necessary to grant the rights
        granted herein to any Content that you submit. Only to the extent as
        permitted by law, if you post content or submit material to the Company,
        including photographs or material, you grant Us a nonexclusive,
        worldwide, perpetual, (revocable only as described below), fully paid,
        royalty-free, sublicensable right and license to use, sell, make,
        sublicense, reproduce, distribute, perform, display, prepare derivative
        works from and otherwise exploit all such content and materials for the
        purpose of publishing material on the Company’s website or its publisher
        partners, maintaining the Company website, and promoting the Company
        without restriction. You further grant to the Company, its affiliates,
        and sublicensees the right to use your user name and/or trademarks and
        logos in connection with any such User Content or the Company’s
        marketing materials or content that we might publish or display on the
        Website. As a user of the Website or Services, you represent, warrant,
        and acknowledge that: <br></br>(i) you own the User Content that you
        submit, display, post or otherwise make available on or through the
        Website or Services, or that you otherwise have the right to grant the
        license set forth in this section, <br></br>(ii) that all such User
        Content, and its submission, display, posting, or availability on or
        through the Website or Services does not violate any applicable law or
        the privacy rights, publicity rights, copyrights, contract rights,
        intellectual property rights or any other rights of any person or
        entity, and <br></br>(iii) such submission, posting, display, or
        availability on or through the Website or services does not result in or
        cause any breach of contract between you and any third party. You
        further agree to pay for all royalties, fees, damages, and any other
        monies of any kind owed to any person or entity by reason of such User
        Content. You agree to defend, indemnify and hold harmless the Company
        and its affiliates and sublicensees from all claims of any kind
        resulting from any such User Content. If you desire to request to revoke
        the license granted by you in this Section for any such User Content,
        you must send a certified letter of request to <i>ACSESTECH LLC</i>, 919
        NORTH MARKET STREET, STE#950, WILMINGTON, DE 19801 with a copy of your
        passport or national identity card (for identity verification purposes)
        and request removal of such User Content. Your certified letter of
        request to remove such User Content must include <br></br>(a) your name,
        address, telephone number, and email address; <br></br>(b) the signature
        of the applicable rights holder for such User Content or a person
        authorized to act on behalf of the rights holder;
        <br></br>(c) identification of the User Content for which the license is
        to be revoked, as well as all necessary information reasonably
        sufficient to allow the Company to locate and remove such User Content
        on the Website or Services; <br></br>(d) a written statement certifying
        that you have a good faith belief that you are the rights holder or
        authorized by the rights holder to revoke the license for the designated
        User Content; and <br></br>(e) a statement that the information in the
        request to remove the user content is accurate, and under penalty of
        perjury, that you are the rights holder or are authorized to act on
        behalf of the rights holder with respect to such User Content that you
        desire to be removed from the Website or Services. Only if you desire to
        do so voluntarily, you may offer feedback to the Company about the
        functionality and performance of the Website, including, without
        limitation, identifying potential errors, ways to modify the Website,
        make improvements, fix bugs, or enhance the Website or Services
        (“Feedback”). By providing Feedback, you hereby grant to the Company a
        nonexclusive, worldwide, perpetual, irrevocable, royalty-free,
        sublicensable right and license to use, sell, sublicense, reproduce,
        perform, distribute, display, prepare derivative works from and
        otherwise exploit all such Feedback for any purpose without restriction.
        You agree that the Company may disclose any or all Feedback to any third
        party in any manner, and you agree that the Company may sublicense any
        or all Feedback in any form to any third party without restriction. By
        providing any Feedback, you agree that your provision of such Feedback
        is unsolicited, gratuitous and without restriction, and will not put the
        Company under any confidentiality, fiduciary, or any other obligation,
        and that the Company is free to use such Feedback without any additional
        compensation to you, and that we are free to disclose such Feedback on a
        non-confidential basis, or otherwise, to anyone. You further acknowledge
        that, by accepting any Feedback, we do not waive any rights to use
        similar or related ideas previously known to the Company, developed or
        created by its employees, or derived from sources other than you.
        <br></br>
        <br></br>
        <b>19. Confidential information</b>
        <br></br>
        <br></br>
        The Company does not want to receive confidential or proprietary
        information from you through our Website. Please note that any
        information or material sent to the Company will be deemed NOT to be
        confidential. By sending the Company any information or material, you
        grant the Company an unrestricted, irrevocable license to copy,
        reproduce, publish, upload, post, transmit, distribute, publicly
        display, perform, modify, create derivative works from, and otherwise
        freely use, those materials or information. You also agree that the
        Company is free to use any ideas, concepts, know-how, or techniques that
        you send us for any purpose. However, we will not release your name or
        otherwise publicize the fact that you submitted materials or other
        information to us unless: <br></br>(a) we obtain your permission by
        email to use your name; or <br></br>(b) we first notify you that the
        materials or other information you submit to a particular part of this
        Website will be published or otherwise used with your name on it; or{" "}
        <br></br>(c) we are required to do so by law. Personally-identifiable
        information that you submit to the Company for the purpose of receiving
        products or services will be handled in accordance with our privacy
        policies.
        <br></br>
        <br></br>
        <b>20. Global availability</b>
        <br></br>
        <br></br>
        Information the Company publishes on the Internet may contain references
        or cross references to the Company’s products, programs and services
        that are not announced or available in your country. Such references do
        not imply that the Company intends to announce or make available such
        products, programs, or services in your country.
        <br></br>
        <br></br>
        <b>21. Links to Third Party Sites</b>
        <br></br>
        <br></br>
        The Website and Services may contain links to other websites (“Linked
        Site(s)” or “third party sites”). The Linked Sites are not under the
        control of the Company and the Company is not responsible for the
        contents of any Linked Site, including, without limitation, any link
        contained in a Linked Site, or any changes or updates to a Linked Site.
        The Company is not responsible for webcasting or any other form of
        transmission received from any Linked Site. The Company is providing
        these links to you only as a convenience, and the inclusion of any link
        does not imply endorsement by the Company of the site or any association
        with its operators. IN NO EVENT SHALL THE COMPANY OR ITS AFFILIATES BE
        LIABLE FOR ANY DAMAGES SUFFERED BY A USER (WHETHER CONSEQUENTIAL,
        DIRECT, INCIDENTAL, EXEMPLARY, INDIRECT, PUNITIVE, SPECIAL, OR
        OTHERWISE) ARISING OUT OF, OR IN ANY WAY CONNECTED WITH, ANY LINKED
        SITE, THIRD PARTY WEBSITE, OR RELATED SERVICES, REGARDLESS OF WHETHER
        SUCH DAMAGES ARE BASED ON CONTRACT, STRICT LIABILITY, TORT, OR OTHER
        THEORIES OF LIABILITY, AND ALSO REGARDLESS OF WHETHER{" "}
        <i>ACSESTECH LLC</i> WAS GIVEN ACTUAL OR CONSTRUCTIVE NOTICE THAT SUCH
        DAMAGES WERE POSSIBLE.
        <br></br>
        <br></br>
        The Website utilizes Google Maps, Google Translate and related content
        licensed to us by Google and its licensors. By using the Google Maps and
        Google Translate features available on the Website, you are agreeing to
        be bound by the applicable policies, Terms of Use of Google.
        <br></br>
        <br></br>
        <b>22. Linking to this Website</b>
        <br></br>
        <br></br>
        The Company consents only to links to this Website in which the link and
        the pages that are activated by the link do not: <br></br>(a) create
        frames around any page on this Website or use other techniques that
        alter in any way the visual presentation or appearance of any content
        within this Website; <br></br>(b) misrepresent your relationship with
        the Company; <br></br>(c) imply that the Company approves or endorses
        you, your website, or your service or product offerings; or <br></br>(d)
        present false or misleading impressions about the Company or otherwise
        damage the goodwill associated with the the Company name or trademarks.
        As a further condition to being permitted to link to this Website, you
        agree that the Company may at any time, at its sole discretion,
        terminate permission to link to this Website. In such event, you agree
        to immediately remove all links to this Website and to cease any related
        use of the Company trademarks.
        <br></br>
        <br></br>
        <b>23. Acts Against the Services</b>
        <br></br>
        <br></br>
        You shall not attempt or engage in potentially harmful acts that are
        directed against the Services including, without limitation, any one or
        more of the following: <br></br>(a) using the Services in contravention
        of any other agreement to which you are a party, including, without
        limitation, any employment agreement to which you may be a party;{" "}
        <br></br>(b) causing, allowing, or assisting any other person to
        impersonate you; <br></br>(c) sharing your password or login credentials
        with any other person; <br></br>(d) logging onto a server or account
        that you are not authorized to access; <br></br>(e) creating more than
        one account, forging usernames, manipulating identifiers, or otherwise
        impersonating any other person or misrepresenting your identity or
        affiliation with any person or entity;
        <br></br>(f) posting content that contains pornography, graphic
        violence, threats, hate speech, or incitements to violence; <br></br>(g)
        violating or attempting to violate any security features of the
        Services; <br></br>(h) emulating or faking usage of the Services;{" "}
        <br></br>(i) using manual or automated software, devices, scripts,
        robots, or other means or processes to access, “scrape,” “crawl,” or
        “spider” any pages contained in the Website; <br></br>(j) introducing
        viruses, worms, software, Trojan horses, or other similar harmful code
        into the Services; <br></br>(k) interfering or attempting to interfere
        with the use of the Services by any other user, host, or network,
        including, without limitation, by means of submitting a virus,
        overloading, “flooding,” “spamming,” “mail bombing,” “pinging,” or
        “crashing” the Services; <br></br>(l) causing, allowing, or assisting
        machines, bots, or automated services to access or use the Services
        without the express written permission of <i>ACSESTECH LLC</i>;{" "}
        <br></br>(m) tampering with the operation, functionality, or the
        security of the Services; <br></br>(n) attempting to override or
        circumvent any security or usage rules embedded into the Services that
        permit digital materials to be protected; <br></br>(o) attempting to
        probe, scan, or test the vulnerability of the Services, or any
        associated system or network, or breach any security or authentication
        measures; <br></br>(p) misusing, tricking, disrupting, or otherwise
        interfering with the functioning of the Services; <br></br>(q)
        harvesting or collecting email addresses or other contact information of
        other users or clients from the Services by electronic or other means;
        <br></br>(r) reverse engineering, decompiling, disassembling,
        deciphering, or otherwise attempting to derive the source code for any
        underlying intellectual property used to provide the Services; <br></br>
        (s) engaging in “framing,” “mirroring,” or otherwise simulating the
        appearance or function of the Services; <br></br>(t) forging any TCP/IP
        packet header or any part of the header information in any e-mail or
        newsgroup posting; <br></br>(u) deep-linking to any portion of this
        Services without our express written permission; <br></br>(v) acting
        illegally or maliciously against the business interests or reputation of{" "}
        <i>ACSESTECH LLC</i>; <br></br>(w) hyperlinking to the Services from any
        other website without our initial and ongoing consent;
        <br></br>(x) using the Services or any of its contents to advertise or
        solicit, for any other commercial, political or religious purpose, or to
        compete, directly or indirectly with <i>ACSESTECH LLC</i>; <br></br>(y)
        reselling or repurposing your access to the Services or any purchases
        made through the Services; or <br></br>(z) using the Services or any of
        its resources to solicit other users of the Services, merchants, or
        other business partners of <i>ACSESTECH LLC</i>
        to become users or partners of other online or offline services directly
        or indirectly competitive or potentially competitive with{" "}
        <i>ACSESTECH LLC</i>, including, without limitation aggregating current
        or previously offered coupons or deals. Violations of system or network
        security may result in civil or criminal liability. You agree that it is
        your responsibility to install anti-virus software and related
        protections against viruses, Trojan horses, worms, time bombs,
        cancelbots, or other computer programming routines or engines that are
        intended to damage, destroy, disrupt, or otherwise impair a computer’s
        functionality or operation.
        <br></br>
        <br></br>
        <b>24. Respecting Other People's Rights</b>
        <br></br>
        <br></br>
        <i>ACSESTECH LLC</i> respects the rights of others, and so should you.
        You therefore may not upload, post, send, or store content that:
        <br></br>
        <br></br>• violates or infringes someone else's rights of publicity,
        privacy, copyright, trademark, or other intellectual-property right;{" "}
        <br></br>• bullies, harasses, or intimidates; <br></br>• promotes or
        encourages self-injury, which includes, but is not limited to, suicide,
        eating disorders, cutting, burning, or scratching; <br></br>• promotes
        or advertises the sale of drugs, firearms, explosives, or anything
        illegal; <br></br>• contains pornography, nudity, graphic violence,
        threats, hate speech, or incitements to violence; <br></br>•
        discriminates in any way, including on the basis of race, skin color,
        national origin, gender, disability, religion or age; <br></br>•
        defames; or <br></br>• spams or solicits <i>ACSESTECH LLC</i>'s users.
        <br></br>
        <br></br>You must also respect others’ rights. These Terms do not grant
        you any right to: <br></br>• use branding, logos, designs, photographs,
        videos, or any other materials used in our Services; <br></br>• copy,
        archive, download, upload, distribute, syndicate, broadcast, perform,
        display, make available, or otherwise use any portion of the Services or
        the content on the Services except as set forth in these Terms;{" "}
        <br></br>• use the Services or any content on the Services for any
        commercial purposes without our consent.
        <br></br>
        <br></br>In short: you may not use the Services or the content on the
        Services in ways that are not authorized by these Terms, nor may you
        help or enable anyone else in doing so.
        <br></br>
        <br></br>
        <b>25. Respecting Copyright</b>
        <br></br>
        <br></br>
        We honor the requirements set forth in the Digital Millennium Copyright
        Act. We therefore take reasonable steps to expeditiously remove from our
        Services any infringing material that we become aware of, and if the
        Company becomes aware that one of its users has repeatedly infringed
        copyrights, we will take reasonable steps within our power to terminate
        the user's account. We make it easy for you to report suspected
        copyright infringement. If you believe that anything on the the website
        infringes a copyright that you own or control, please contact us:
        <br></br>Email: <i>SWAPNA.ATMAKURI@ACSESTECH.COM</i>.<br></br>
        <br></br>If you file a notice by mail with us, it must comply with the
        requirements set forth at 17 U.S.C. § 512(c)(3). That means the notice
        must: <br></br>• contain the physical or electronic signature of a
        person authorized to act on behalf of the copyright owner; <br></br>•
        identify the copyrighted work claimed to have been infringed; <br></br>•
        identify the material that is claimed to be infringing or to be the
        subject of infringing activity and that is to be removed, or access to
        which is to be disabled, and information reasonably sufficient to let us
        locate the material; <br></br>• provide your contact information,
        including your address, telephone number, and an email address;{" "}
        <br></br>• provide a personal statement that you have a good-faith
        belief that the use of the material in the manner complained of is not
        authorized by the copyright owner, its agent, or the law; and <br></br>•
        provide a statement that the information in the notification is accurate
        and, under penalty of perjury, that you are authorized to act on behalf
        of the copyright owner.
        <br></br>
        <br></br>
        <b>26. Intellectual Property Rights</b>
        <br></br>
        <br></br>
        You acknowledge that all intellectual property rights in the Website and
        Services, and the Documents anywhere in the world belong to ACSESTECH
        LLC or our licensors, that the intellectual property rights are licensed
        (not sold) to you, and that you have no rights to use the Services or
        the Documents other than the right to use each of them in accordance
        with these Terms.
        <br></br>
        <br></br>You acknowledge that you have no right to have access to the
        Website or Services in source-code form.
        <br></br>
        <br></br>
        <b>27. Trademark Information</b>
        <br></br>
        <br></br>
        You agree that all of <i>ACSESTECH LLC</i>’s trademarks, trade names,
        service marks, and other logos and brand features that are displayed via
        the Website and Services (collectively, the “Marks”) are trademarks and
        the property of <i>ACSESTECH LLC</i>. You agree not to display or use
        our Marks in any manner without receiving our prior permission via
        email. Sponsor and third party website trademarks are the property of
        the respective Sponsors and third parties. The display of any Sponsor or
        third party trademarks via the website and Services does not necessarily
        mean that
        <i>ACSESTECH LLC</i> has an affiliation with these entities.
        <br></br>
        <br></br>
        <b>28. Disclaimers</b>
        <br></br>
        <br></br>
        We try to keep the Website and Services up, running, and free of
        annoyances, but we make no promises that we will succeed. TO THE MAXIMUM
        EXTENT PERMITTED BY APPLICABLE LAW, THE SERVICES AND THE APP ARE
        PROVIDED "AS IS" AND “AS AVAILABLE,” WITH ALL FAULTS AND WITHOUT
        WARRANTY OF ANY KIND, AND WE HEREBY DISCLAIM ALL WARRANTIES AND
        CONDITIONS WITH RESPECT THERETO, EITHER EXPRESS, IMPLIED, OR STATUTORY,
        INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES AND/OR CONDITIONS
        OF MERCHANTABILITY, OF SATISFACTORY QUALITY, OF FITNESS FOR A PARTICULAR
        PURPOSE, OF ACCURACY, OF QUIET ENJOYMENT, AND OF NONINFRINGEMENT OF
        THIRD-PARTY RIGHTS. WE DO NOT WARRANT AGAINST INTERFERENCE WITH USER’S
        ENJOYMENT OF THE SERVICES AND THE APP, THAT THE FUNCTIONS CONTAINED IN
        THE SERVICES OR APP WILL MEET USER’S REQUIREMENTS, THAT THE OPERATION OF
        THE SERVICES OR THE APP WILL BE UNINTERRUPTED OR ERROR-FREE, OR THAT
        DEFECTS IN THE SERVICES OR THE APP WILL BE CORRECTED. NO ORAL OR WRITTEN
        INFORMATION OR ADVICE THAT WE GIVE SHALL CREATE A WARRANTY. SOME
        JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES OR
        LIMITATIONS ON APPLICABLE STATUTORY RIGHTS, SO THE ABOVE EXCLUSIONS AND
        LIMITATIONS MAY NOT APPLY TO YOU.
        <br></br>
        <br></br>
        <i>ACSESTECH LLC</i> TAKES NO RESPONSIBILITY AND ASSUMES NO LIABILITY
        FOR ANY CONTENT OR MATERIALS OF ANY KIND INCLUDING REVIEWS THAT YOU,
        ANOTHER USER, OR A THIRD PARTY CREATES, UPLOADS, POSTS, SENDS, RECEIVES,
        OR STORES ON OR THROUGH OUR WEBSITE, APP, AND SERVICES. YOU UNDERSTAND
        AND AGREE THAT YOU MAY BE EXPOSED TO CONTENT THAT MIGHT BE OFFENSIVE,
        ILLEGAL, MISLEADING, OR OTHERWISE INAPPROPRIATE, NONE OF WHICH ACSESTECH
        LLC WILL BE RESPONSIBLE FOR. <i>ACSESTECH LLC</i> ALSO TAKES NO
        RESPONSIBILITY AND ASSUMES NO LIABILITY FOR THE ACCURACY OF ANY MEDICAL
        OR HEALTH INFORMATION OF ANY KIND THAT YOU, ANOTHER USER, OR A THIRD
        PARTY CREATES, UPLOADS, POSTS, SENDS, RECEIVES, OR STORES ON OR THROUGH
        OUR WEBSITE, APP AND SERVICES. <i>ACSESTECH LLC</i> DOES NOT MAKE ANY
        REPRESENTATIONS OR WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, WITHOUT
        LIMITATION, WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
        PURPOSE, TITLE, SECURITY, ACCURACY, OR USEFULNESS WITH RESPECT TO
        <i>ACSESTECH LLC</i> , THE WEBSITE, APP OR INFORMATION CONTAINED ON THE
        WEBSITE, APP, OR SERVICES, INCLUDING ANY THIRD PARTY SITES OR THIRD
        PARTY LINKS.
        <br></br>
        <br></br>
        USE OF THE WEBSITE, APP AND SERVICES IS NOT INTENDED TO BE A SUBSTITUTE
        FOR PROFESSIONAL MEDICAL ADVICE, DIAGNOSIS, OR TREATMENT. ALWAYS SEEK
        THE ADVICE OF YOUR PHYSICIAN OR OTHER QUALIFIED HEALTH PROVIDER WITH ANY
        QUESTIONS YOU MAY HAVE REGARDING A MEDICAL CONDITION OR THOUGHTS OF
        SUICIDE. NEVER DISREGARD PROFESSIONAL MEDICAL ADVICE OR DELAY SEEKING IT
        BECAUSE OF SOMETHING YOU HAVE READ ON THE WEBSITE, APP OR VIA THE
        SERVICES. TO THE EXTENT PERMITTED BY LAW, THE EXCLUSIONS OR LIMITATIONS
        OF CERTAIN WARRANTIES, OR SOME OR ALL OF THE EXCLUSIONS AND LIMITATIONS
        IN THIS SECTION, MAY NOT APPLY TO YOU.
        <br></br>
        <br></br>
        <b>29. Release</b>
        <br></br>
        <br></br>YOU AND YOUR HEIRS, SUCCESSORS, AND ASSIGNS HEREBY FOREVER
        IRREVOCABLY RELEASE, DISCHARGE, AND HOLD HARMLESS <i>ACSESTECH LLC</i>,
        OUR AFFILIATES, AND OUR AND THEIR SUCCESSORS AND ASSIGNS, AND THEIR
        RESPECTIVE OFFICERS, DIRECTORS, EMPLOYEES, AND AGENTS (COLLECTIVELY,
        “RELEASED PARTIES”) FROM, AND AGREE NOT TO SUE ANY RELEASED PARTY FOR,
        ANY LIABILITIES, CLAIMS, OBLIGATIONS, SUITS, ACTIONS, DEMANDS, EXPENSES,
        AND DAMAGES WHATSOEVER (COLLECTIVELY, “LIABILITIES”) THAT YOU MAY HAVE
        AGAINST ANY RELEASED PARTY WHETHER EXISTING NOW OR IN THE FUTURE,
        WHETHER KNOWN OR UNKNOWN, ARISING OUT OF OR IN CONNECTION WITH YOUR OR A
        THIRD PARTY’S CONDUCT RELATED TO USE OF THE SITE, APP AND SERVICES,
        INCLUDING FOR PERSONAL INJURY OR DEATH OR ANY CLAIMS FOR DAMAGES YOU MAY
        HAVE SUFFERED IN CONNECTION WITH YOUR OR A THIRD PARTY’S CONDUCT RELATED
        TO USE OF THE SITE, APP AND SERVICES. YOU UNDERSTAND AND ACKNOWLEDGE
        THAT THE FOREGOING SENTENCE RELEASES AND DISCHARGES ALL LIABILITIES,
        WHETHER OR NOT THEY ARE CURRENTLY KNOWN TO YOU, AND YOU WAIVE YOUR
        RIGHTS UNDER CALIFORNIA CIVIL CODE SECTION 1542. YOU UNDERSTAND THE
        MEANING OF CALIFORNIA CIVIL CODE SECTION 1542, WHICH READS AS FOLLOWS:
        “A GENERAL RELEASE DOES NOT EXTEND TO CLAIMS WHICH THE CREDITOR DOES NOT
        KNOW OR SUSPECT TO EXIST IN HIS FAVOR AT THE TIME OF EXECUTING THE
        RELEASE, WHICH IF KNOWN BY HIM MUST HAVE MATERIALLY AFFECTED HIS
        SETTLEMENT WITH THE DEBTOR.” BY AGREEING TO THESE TERMS AND THIS WAIVER,
        YOU ASSUME ALL RISK ARISING FROM YET UNKNOWN CLAIMS. <br></br>
        <br></br>
        <b>30. Limitation of Liability</b>
        <br></br>
        <br></br>YOU ACKNOWLEDGE AND AGREE THAT, TO THE MAXIMUM EXTENT PERMITTED
        BY LAW, THE ENTIRE RISK ARISING OUT OF YOUR ACCESS TO AND USE OF THE
        SITE, APP AND SERVICES, OR REQUESTING OR RECEIVING SERVICES THROUGH THE
        SITE, APP AND SERVICES, OR FROM DAMAGES YOU MAY SUFFER AS A RESULT OF A
        THIRD PARTY’S USE OF THE SITE, APP AND SERVICES AND ANY CONTACT YOU HAVE
        WITH OTHER USERS OF <i>ACSESTECH LLC</i>’S SITE, APP AND SERVICES OR
        THIRD PARTIES, WHETHER IN PERSON OR ONLINE, REMAINS WITH YOU. NEITHER
        <i>ACSESTECH LLC</i> NOR ANY OTHER PARTY INVOLVED IN CREATING,
        PRODUCING, OR DELIVERING THE SITE, APP AND SERVICES WILL BE LIABLE
        (WHETHER BASED ON WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE),
        PRODUCT LIABILITY OR ANY OTHER LEGAL THEORY, AND WHETHER OR NOT{" "}
        <i>ACSESTECH LLC</i> HAS BEEN INFORMED OF THE POSSIBILITY OF SUCH
        DAMAGES, EVEN IF A LIMITED REMEDY SET FORTH HEREIN IS FOUND TO HAVE
        FAILED OF ITS ESSENTIAL PURPOSE) FOR: <br></br>(A) ANY INCIDENTAL,
        SPECIAL, EXEMPLARY OR CONSEQUENTIAL DAMAGES, INCLUDING LOST PROFITS,
        LOSS OF DATA OR LOSS OF GOODWILL; <br></br>(B) SERVICE INTERRUPTION,
        COMPUTER DAMAGE OR SYSTEM FAILURE; <br></br>(C) THE COST OF SUBSTITUTE
        PRODUCTS OR SERVICES; <br></br>(D) ANY DAMAGES FOR PERSONAL OR BODILY
        INJURY, DEATH OR EMOTIONAL DISTRESS ARISING OUT OF OR IN CONNECTION WITH
        USING THE SITE, APP AND SERVICES;
        <br></br>(E) THE USE OF OR INABILITY TO USE THE SITE, APP AND SERVICES;{" "}
        <br></br>(F) ANY COMMUNICATIONS, INTERACTIONS OR MEETINGS WITH OTHER
        USERS OF THE SITE, APP AND SERVICES OR OTHER PERSONS WITH WHOM YOU
        COMMUNICATE OR INTERACT AS A RESULT OF YOUR USE OF THE SITE, APP AND
        SERVICES; OR <br></br>(G) YOUR OFFERING OR PROVIDING SERVICES OR
        REQUESTING OR RECEIVING SERVICES THROUGH THE SITE, APP AND SERVICES. WE
        ARE ALSO NOT LIABLE FOR ANY DAMAGES YOU MAY SUFFER CAUSED BY A
        CYBERATTACK, DATA BREACH, DATA INCIDENT, RANSOMWARE ATTACK AND/OR
        MALWARE ATTACK WHILE USING THE SITE, APP AND SERVICES, THIRD PARTY
        CONTENT, OR USER CONTENT.
        <br></br>
        <br></br>
        <i>ACSESTECH LLC</i> WILL NOT BE LIABLE TO YOU OR ANYONE ELSE FOR ANY
        SPECIAL, INDIRECT, INCIDENTAL, EXEMPLARY, PUNITIVE OR CONSEQUENTIAL
        DAMAGES, INCLUDING PERSONAL INJURY OR DEATH IN CONNECTION WITH THE SITE,
        APP AND SERVICES (INCLUDING THE SERVICES AND CONTENT AVAILABLE THROUGH
        THE SITE, APP AND SERVICES) OR THIS AGREEMENT, EVEN IF FORESEEABLE OR
        EVEN IF
        <i>ACSESTECH LLC</i> HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES
        (INCLUDING, WITHOUT LIMITATION, WHETHER CAUSED IN WHOLE OR IN PART BY
        THE NEGLIGENCE, GROSS NEGLIGENCE, OR OTHERWISE, BY ANY THIRD PARTIES.
        <i>ACSESTECH LLC</i> WILL NOT BE LIABLE TO YOU OR ANYONE ELSE FOR ANY
        DAMAGES FOR LOSS OF PROFITS, LOSS OF DATA, BUSINESS INTERRUPTION, OR ANY
        COMMERCIAL DAMAGES, BODILY INJURY DAMAGES, DEATH, PHYSICAL HARM,
        EMOTIONAL DISTRESS, OR LOSSES OF ANY KIND. THE FOREGOING LIMITATIONS
        WILL APPLY EVEN IF ANY STATED REMEDY FAILS OF ITS ESSENTIAL PURPOSE.
        <i>ACSESTECH LLC</i> DOES NOT CONDUCT ANY CRIMINAL BACKGROUND CHECKS ON
        ANY WEBSITE USERS OR COMPANIES THAT SUBSCRIBE AND PAY TO USE OUR
        SERVICES INCLUDING THEIR EMPLOYEES OR INDEPENDENT CONTRACTORS AND YOU
        AGREE THAT
        <i>ACSESTECH LLC</i> IS NOT RESPONSIBLE OR LIABLE FOR THE CONDUCT OR
        ACTIONS OF ANY WEBSITE USERS OR COMPANIES THAT SUBSCRIBE AND PAY TO USE
        ACSESTECH LLC’S SERVICES. IN NO EVENT SHALL THE TOTAL, AGGREGATE
        LIABILITY OF
        <i>ACSESTECH LLC</i> AND ITS AFFILIATES AND SUBSIDIARIES, AND THEIR
        RESPECTIVE OFFICERS, DIRECTORS, EMPLOYEES AND AGENTS, ARISING FROM OR
        RELATING TO THE TERMS, SERVICES, OR FROM THE USE OF OR INABILITY TO USE
        THE SITE, APP AND SERVICES OR INTERACTIONS WITH ANY OTHER USERS OF THE
        SITE, APP AND SERVICES EXCEED THE SUM OF ONE HUNDRED US DOLLARS, AS
        APPLICABLE. TO THE EXTENT PERMITTED BY LAW THE EXCLUSION OR LIMITATION
        OF CERTAIN DAMAGES, OR SOME OR ALL OF THE EXCLUSIONS AND LIMITATIONS IN
        THIS SECTION MAY NOT APPLY TO YOU. You acknowledge and agree that
        without the foregoing exclusions and limitations of liability, the
        Company would not be able to offer the website, app or services, and
        that such exclusions and limitations of liability shall apply, even if
        they would cause user’s remedies under this agreement to fail of their
        essential purpose.
        <br></br>
        <br></br>
        <b>31. Applicable Law</b>
        <br></br>
        <br></br>
        By using the website and Services you agree that the laws of the State
        of Delaware without regard to principles of conflict of laws, will
        govern this Agreement and any dispute of any sort that might arise
        between you and Us.
        <br></br>
        <br></br>
        <b>32. Disputes</b>
        <br></br>
        <br></br>
        ANY DISPUTE RELATING IN ANY WAY TO YOUR USE OF THE WEBSITE AND SERVICES
        SHALL BE SUBMITTED TO CONFIDENTIAL BINDING ARBITRATION IN WILMINGTON,
        DELAWARE EXCEPT FOR INTELLECTUAL PROPERTY CLAIMS BROUGHT BY EITHER PARTY
        (WHICH FOR PURPOSES OF THIS SECTION DO NOT INCLUDE PRIVACY AND PUBLICITY
        CLAIMS) AND CLAIMS THAT MAY BE BROUGHT IN SMALL-CLAIMS COURT.
        <br></br>
        <br></br>
        CONFIDENTIAL ARBITRATION UNDER THIS AGREEMENT SHALL BE RESOLVED
        EXCLUSIVELY UNDER THE CONSUMER ARBITRATION RULES THEN PREVAILING OF THE
        AMERICAN ARBITRATION ASSOCIATION ("AAA'S CONSUMER RULES"), EXCLUDING ANY
        RULES AND PROCEDURES GOVERNING OR PERMITTING CLASS OR REPRESENTATIVE
        ACTIONS. THE RULES ARE AVAILABLE AT THE AMERICAN ARBITRATION
        ASSOCIATION'S WEBSITE.
        <br></br>
        <br></br>
        YOU AND THE COMPANY AGREE TO EXPRESSLY WAIVE ANY RIGHTS TO FILE CLASS OR
        REPRESENTATIVE ACTIONS OR SEEK RELIEF ON A CLASS OR REPRESENTATIVE BASIS
        IN ANY JURISDICTION OR FORUM.
        <br></br>
        <br></br>
        THE ARBITRATOR SHALL APPLY DELAWARE LAW, AND THE ARBITRATOR'S AWARD
        SHALL BE BINDING AND MAY BE ENTERED AS A JUDGMENT IN ANY COURT OF
        COMPETENT JURISDICTION. THERE SHALL BE NO APPEAL FROM ANY AWARD OF THE
        ARBITRATOR. TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, NO
        ARBITRATION UNDER THIS AGREEMENT SHALL BE JOINED TO AN ARBITRATION
        INVOLVING ANY OTHER PARTY SUBJECT TO THIS AGREEMENT, WHETHER THROUGH
        CLASS ARBITRATION PROCEEDINGS OR OTHERWISE. IF ANY PART OF THIS
        ARBITRATION PROVISION IS FOUND TO BE INVALID, UNENFORCEABLE OR ILLEGAL,
        THE REST OF THIS PROVISION SHALL REMAIN IN EFFECT. IF THE ENTIRE
        ARBITRATION PROVISION IS FOUND TO BE INVALID OR UNENFORCEABLE, THEN THE
        PARTIES CONSENT TO PERSONAL JURISDICTION AND EXCLUSIVE VENUE IN THE
        STATE AND FEDERAL COURTS IN WILMINGTON, DELAWARE.
        <br></br>
        <br></br>
        If you initiate the arbitration, your arbitration fees will be limited
        to the filing fee set forth in the AAA's Consumer Rules.
        <br></br>
        <br></br>
        <b>33. Indemnification</b>
        <br></br>
        <br></br>
        You understand and agree that you are personally responsible for your
        behavior on the website and use of the Services. You agree to indemnify,
        defend and hold harmless <i>ACSESTECH LLC</i>, its parent companies,
        subsidiaries, affiliated companies, joint venturers, business partners,
        licensors, employees, agents, and any third-party information providers
        from and against all claims, losses, expenses, damages and costs
        (including, but not limited to, direct, incidental, consequential,
        exemplary and indirect damages), and reasonable attorneys' fees,
        resulting from or arising out of your data, your use, misuse, or
        inability to use the website or Services or the Content you post, or any
        violation by you of these Terms of Service. You shall also indemnify,
        defend and hold harmless <i>ACSESTECH LLC</i>, its parent companies,
        subsidiaries, affiliated companies, joint venturers, business partners,
        licensors, employees, agents, and any third-party information providers
        from and against all claims, losses, expenses, damages and costs
        (including, but not limited to, direct, incidental, consequential,
        exemplary and indirect damages), and reasonable attorneys' fees,
        resulting from or arising out of: <br></br>i) your failure to pay all
        applicable taxes associated with Your use of the Services; and <br></br>
        ii) your use of the Data.
        <br></br>
        <br></br>
        <b>34. Force Majure</b>
        <br></br>
        <br></br>
        In the event that either Party is unable to perform any of its
        obligations under this Agreement or to enjoy any of its benefits because
        of natural disaster, terrorism, fire, explosion, power blackout,
        earthquake, flood, the elements, strike, embargo, labor disputes, acts
        of civil or military authority, war, acts of god, acts or omissions of
        carriers or suppliers, acts of regulatory or governmental agencies,
        actions or decrees of governmental bodies or communication line failure
        not the fault of the affected Party or other causes beyond such Party’s
        reasonable control (a “Force Majeure Event”) the Party who has been so
        affected shall immediately give notice to the other Party and shall do
        everything possible to resume performance.
        <br></br>
        <br></br>
        <b>35. Support, Maintenance and Updates</b>
        <br></br>
        <br></br>During the Term of this Agreement, We will use commercially
        reasonable efforts to provide you with email support related to the
        Services and the App. Scheduled system maintenance shall take place from
        time to time, and during such time, the Services may be unavailable.
        Emergency maintenance may be required at other times in the event of
        system failure. We make no guarantees about uptime. We may, but are not
        required to develop and provide App updates from time to time, which may
        include upgrades, bug fixes, patches and other corrections or new
        features (collectively, “Updates”). Updates may also modify or delete in
        their entirety certain features or functionality. You agree that We have
        no obligation to provide any Updates or to continue to provide or enable
        any particular features or functionality. Based on the settings of your
        mobile device, when your device is connected to the Internet the App
        will automatically download and install available Updates, or you will
        be prompted to download and install available Updates. You acknowledge
        that the App or any of its features may not operate properly if you fail
        to install Updates. You further agree that all Updates will be deemed
        part of the App and subject to the terms of this Agreement. <br></br>
        <br></br>
        <b>36. Waiver and Severability</b>
        <br></br>
        <br></br>
        No waiver by the Company of any term or condition set forth in these
        Terms of Service shall be deemed a further or continuing waiver of such
        term or condition or a waiver of any other term or condition, and any
        failure of the Company to assert a right or provision under these Terms
        of Service shall not constitute a waiver of such right or provision. If
        any provision of these Terms of Service is held by a court or other
        tribunal of competent jurisdiction to be invalid, illegal, or
        unenforceable for any reason, such provision shall be eliminated or
        limited to the minimum extent such that the remaining provisions of the
        Terms of Service will continue in full force and effect.
        <br></br>
        <br></br>
        <b>37. Entire Agreement</b>
        <br></br>
        <br></br>
        The Terms of Service, including all documents expressly incorporated
        herein by reference, constitute the sole and entire agreement between
        you and the Company with respect to the Website and supersede all prior
        and contemporaneous understandings, agreements, representations, and
        warranties, both written and oral, with respect to the Website and
        Services.
        <br></br>
        <br></br>
        <b>38. Your Comments and Concerns</b>
        <br></br>
        <br></br>
        This Website and Services are operated by{" "}
        <b>
          <i>ACSESTECH LLC</i>, 919 NORTH MARKET STREET, STE #950, WILMINGTON,
          DE 19801.
        </b>
        <br></br>
        <br></br>All other notices, feedback, comments, requests for technical
        support, and other communications relating to the Website please contact
        us at: <i>SWAPNA.ATMAKURI@ACSESTECH.COM</i>
        <br></br>
        <br></br>Thank you for visiting the Website.
      </div>
    </div>
  );
}

export default TermsOfService;
