import {ErrorMessage} from "@hookform/error-message";
import Typography from "@mui/material/Typography";

function InputErrorMessasge(props) {
  return (
    <ErrorMessage
      errors={props.errors}
      name={props.name}
      render={({messages}) => {
        return messages
          ? Object.entries(messages).map(([type, message], index) => (
              <span key={index}>
                <Typography
                  variant="caption"
                  sx={{minWidth: 70}}
                  key={type}
                  color="error"
                >
                  {message}
                </Typography>
                <br />
              </span>
            ))
          : null;
      }}
    />
  );
}

export default InputErrorMessasge;
