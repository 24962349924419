import { useState, useRef } from "react";
import { useForm } from "react-hook-form";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import DoneIcon from "@mui/icons-material/Done";
import SaveIcon from "@mui/icons-material/Save";
import withRouter from "components/utils/withRouter.js";
import PatientService from "services/PatientService.js";
import UserService from "services/UserService.js";
import ZipcodeService from "services/ZipcodeService.js";
import ZipcodeUtils from "utils/ZipcodeUtils.js";
import InputErrorMessage from "components/utils/InputErrorMessage.js";
import RegexUtils from "utils/RegexUtils.js";
import StringUtils from "utils/StringUtils.js";
import COUNTRY_CODES_LIST from "utils/constants/CountryCodesList.js";
import useAPI from "hooks/useAPI.js";
import { useOutletContext } from "react-router-dom";

function AddPatient(props) {
  const [hospitalId] = useOutletContext();
  const [hospitalNames, setHospitalNames] = useState([]);
  const sendRegistrationEmailRef = useRef();

  const userHospitalsAPI = useAPI(
    UserService.getHospitals,
    getHospitalsHandler,
    false,
    true
  );
  const addPatientAPI = useAPI(
    PatientService.addPatient,
    addPatientHandler,
    true
  );
  const updateLocationAPI = useAPI(
    ZipcodeService.getLocationDetails,
    updateLocationHandler
  );

  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm({ criteriaMode: "all", mode: "onTouched" });

  /* Utility Functions */
  function addPatient(data) {
    data.sendRegistrationEmail = sendRegistrationEmailRef.current.checked;
    addPatientAPI.execute(hospitalId, data);
  }

  function updateLocation(e) {
    let zipcode = e.target.value;
    updateLocationAPI.execute(zipcode);
  }

  /* API Success Handlers */

  function getHospitalsHandler(response) {
    setHospitalNames(response.data);
  }

  function addPatientHandler(response) {
    props.navigate(-1);
  }

  function updateLocationHandler(response) {
    const { city, state, country } = ZipcodeUtils.getLocation(response.data);
    setValue("city", city);
    setValue("state", state);
    setValue("country", country);
  }

  return (
    <Paper elevation={0}>
      <form className="container py-4 px-4" onSubmit={handleSubmit(addPatient)}>
        <div className="row mb-3">
          <div className="col col-4 mb-3">
            <label htmlFor="firstNameInput" className="form-label">
              First Name
            </label>
            <input
              type="text"
              className="form-control"
              id="firstNameInput"
              {...register("firstName", {
                required: "First Name is required",
                pattern: {
                  value: RegexUtils.NAME,
                  message: "First Name is invalid",
                },
              })}
            />
            <InputErrorMessage name="firstName" errors={errors} />
          </div>
          <div className="col col-4 mb-3">
            <label htmlFor="lastNameInput" className="form-label">
              Last Name
            </label>
            <input
              type="text"
              className="form-control"
              id="lastNameInput"
              {...register("lastName", {
                required: "Last Name is required",
                pattern: {
                  value: RegexUtils.NAME,
                  message: "Last Name is invalid",
                },
              })}
            />
            <InputErrorMessage name="lastName" errors={errors} />
          </div>
          <div className="col col-4 mb-3">
            <label htmlFor="emailInput" className="form-label">
              Email ID
            </label>
            <input
              type="email"
              className="form-control"
              id="emailInput"
              {...register("email", {
                required: "Email ID is required",
                pattern: {
                  value: RegexUtils.EMAIL,
                  message: "Email ID is invalid",
                },
              })}
            />
            <InputErrorMessage name="email" errors={errors} />
          </div>
          <div className="col col-4 mb-3">
            <label htmlFor="dateOfBirthInput" className="form-label">
              Date of Birth
            </label>
            <input
              type="date"
              className="form-control"
              id="dateOfBirthInput"
              {...register("dob", {
                required: "DOB is required",
              })}
            />
            <InputErrorMessage name="dob" errors={errors} />
          </div>
          <div className="col col-4 mb-3">
            <label className="form-label d-block">Gender</label>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                id="genderInputMale"
                value="Male"
                {...register("gender", {
                  required: "Gender is required",
                })}
              />
              <label className="form-check-label" htmlFor="genderInputMale">
                Male
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                id="genderInputFemale"
                value="Female"
                {...register("gender", {
                  required: "Gender is required",
                })}
              />
              <label className="form-check-label" htmlFor="genderInputFemale">
                Female
              </label>
            </div>
            <InputErrorMessage name="gender" errors={errors} />
          </div>
          <div className="col col-4 mb-3">
            <label className="form-label d-block">Age Group</label>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                id="ageGroupChildInput"
                value="Child"
                {...register("ageGroup", {
                  required: "Age Group is required",
                })}
              />
              <label className="form-check-label" htmlFor="ageGroupChildInput">
                Child
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                id="ageGroupAdolescentInput"
                value="Adolescent"
                {...register("ageGroup", {
                  required: "Age Group is required",
                })}
              />
              <label
                className="form-check-label"
                htmlFor="ageGroupAdolescentInput"
              >
                Adolescent
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                id="ageGroupAdultInput"
                value="Adult"
                {...register("ageGroup", {
                  required: "Age Group is required",
                })}
              />
              <label className="form-check-label" htmlFor="ageGroupAdultInput">
                Adult
              </label>
            </div>
            <InputErrorMessage name="ageGroup" errors={errors} />
          </div>
          <div className="col col-4 mb-3">
            <label htmlFor="phoneInput" className="form-label">
              Phone
            </label>
            <input
              type="text"
              className="form-control"
              id="phoneInput"
              placeholder="123-456-7890"
              {...register("phone", {
                required: "Phone is required",
                pattern: {
                  value: RegexUtils.PHONE,
                  message: "Phone is invalid",
                },
              })}
              onKeyUp={(e) => {
                setValue("phone", StringUtils.formatPhoneNumber(e));
              }}
            />
            <InputErrorMessage name="phone" errors={errors} />
          </div>
          <div className="col col-4 mb-3">
            <label htmlFor="countryCode" className="form-label">
              Country Code
            </label>
            <select
              className="form-select"
              id="countryCodeInput"
              aria-label="Default select example"
              {...register("countryCode", {
                required: "Country Code is required",
                minLength: {
                  value: 2,
                  message: "Country Code must exceed 2 characters",
                },
                maxLength: {
                  value: 5,
                  message: "Country Code must not exceed 5 characters",
                },
              })}
            >
              <option></option>
              {COUNTRY_CODES_LIST.map((item, index) => (
                <option key={item.value + index} value={item.value}>
                  {item.country}
                </option>
              ))}
            </select>
            <InputErrorMessage name="countryCode" errors={errors} />
          </div>
          <div className="col col-4 mb-3">
            <label htmlFor="associatedHospitalIdInput" className="form-label">
              Associated Centre
            </label>
            <select
              className="form-select"
              id="associatedHospitalIdInput"
              aria-label="Default select example"
              {...register("associatedHospitalId", {
                required: "Associated Centre is required",
              })}
            >
              <option></option>
              {hospitalNames.map((hospital) => (
                <option key={hospital.id} value={hospital.id}>
                  {hospital.hospitalName}
                </option>
              ))}
            </select>
            <InputErrorMessage name="associatedHospitalId" errors={errors} />
          </div>
          <div className="col col-4 mb-3">
            <label htmlFor="addressLine1Input" className="form-label">
              Address Line 1
            </label>
            <input
              type="text"
              className="form-control"
              id="addressLine1Input"
              {...register("addressLine1", {
                required: "Address Line 1 is required",
              })}
            />
            <InputErrorMessage name="addressLine1" errors={errors} />
          </div>
          <div className="col col-4 mb-3">
            <label htmlFor="addressLine2Input" className="form-label">
              Address Line 2
            </label>
            <input
              type="text"
              className="form-control"
              id="addressLine2Input"
              {...register("addressLine2", {
                required: "Address Line 2 is required",
              })}
            />
            <InputErrorMessage name="addressLine2" errors={errors} />
          </div>
          <div className="col col-4">
            <label htmlFor="zipcodeInput" className="form-label">
              Zipcode
            </label>
            <input
              type="text"
              className="form-control"
              id="zipcodeInput"
              {...register("zipcode", {
                required: "Zipcode is required",
                pattern: {
                  value: RegexUtils.NUMERIC,
                  message: "Zipcode should be numeric",
                },
                minLength: {
                  value: 4,
                  message: "Zipcode must exceed 4 characters",
                },
                maxLength: {
                  value: 6,
                  message: "Zipcode must not exceed 6 characters",
                },
              })}
              onBlur={updateLocation}
            />
            <InputErrorMessage name="zipcode" errors={errors} />
          </div>
          <div className="col col-4 mb-3">
            <label htmlFor="cityInput" className="form-label">
              City
            </label>
            <input
              type="text"
              className="form-control"
              id="cityInput"
              {...register("city", {
                required: "City is required",
              })}
            />
            <InputErrorMessage name="city" errors={errors} />
          </div>
          <div className="col col-4 mb-3">
            <label htmlFor="stateInput" className="form-label">
              State
            </label>
            <input
              type="text"
              className="form-control"
              id="stateInput"
              {...register("state", {
                required: "State is required",
              })}
            />
            <InputErrorMessage name="state" errors={errors} />
          </div>
          <div className="col col-4 mb-3">
            <label htmlFor="countryInput" className="form-label">
              Country
            </label>
            <input
              type="text"
              name="country"
              className="form-control"
              id="countryInput"
              {...register("country", {
                required: "Country is required",
              })}
            />
            <InputErrorMessage name="country" errors={errors} />
          </div>
        </div>
        <div className="form-check mb-3">
          <input
            className="form-check-input"
            type="checkbox"
            name="sendRegistrationEmail"
            id="notifyPatientInput"
            ref={sendRegistrationEmailRef}
            defaultChecked={true}
          />
          <label className="form-check-label" htmlFor="notifyPatientInput">
            Send registration email to patient
          </label>
        </div>
        {addPatientAPI.status === "pending" ? (
          <LoadingButton
            loading
            loadingPosition="start"
            startIcon={<SaveIcon />}
            variant="contained"
            className="me-3"
          >
            Submit
          </LoadingButton>
        ) : (
          <Button
            type="submit"
            className="me-3"
            startIcon={<DoneIcon />}
            variant="contained"
          >
            Submit
          </Button>
        )}
        <Button onClick={() => props.navigate(-1)} variant="outlined">
          Cancel
        </Button>
      </form>
    </Paper>
  );
}

export default withRouter(AddPatient);
