import StringUtils from "utils/StringUtils.js";

function parse(formStatusEnum) {
  let elementTokens = formStatusEnum.split("_");
  let updatedFormStatus = "";
  for (let tokenIndex in elementTokens) {
    updatedFormStatus +=
      StringUtils.capitalizeFirstLetter(
        elementTokens[tokenIndex].toLowerCase()
      ) + " ";
  }
  updatedFormStatus = updatedFormStatus.slice(0, -1);
  return updatedFormStatus;
}

const EnumUtils = {
  parse,
};

export default EnumUtils;
