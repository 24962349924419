import { useState, useEffect, useRef } from "react";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import CircularProgress from "@mui/material/CircularProgress";
import EnhancedTable from "components/utils/Table.js";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import EnrollmentService from "services/EnrollmentService.js";
import Chip from "@mui/material/Chip";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import LoadingButton from "@mui/lab/LoadingButton";
import { Outlet } from "react-router-dom";
import TableRowActionsCell from "components/utils/TableRowActionsCell.js";
import PopUpModal from "components/utils/PopUpModal.js";
import AuditFieldsModal from "components/modals/AuditFieldsModal.js";
import ConfirmActionModal from "components/modals/ConfirmActionModal";
import useAPI from "hooks/useAPI.js";
import DateUtils from "utils/DateUtils.js";
import { useOutletContext } from "react-router-dom";

export function EnrollmentSessions() {
  const [hospitalId] = useOutletContext();
  return <Outlet context={[hospitalId]} />;
}
export function EnrollmentSessionList() {
  let navigate = useNavigate();
  const [hospitalId] = useOutletContext();
  const { patientId } = useParams();
  const { enrollmentId } = useParams();

  const [enrollmentSessions, setEnrollmentSessions] = useState([]);
  const [auditFieldModalContent, setAuditFieldModalContent] = useState({
    createdAt: "",
    lastUpdatedAt: "",
    createdBy: "",
    lastUpdatedBy: "",
  });
  const auditFieldModalRef = useRef(null);
  const deleteSessionModalRef = useRef(null);
  const [deletingSessionId, setDeletingSessionId] = useState("");

  const getSessionsAPI = useAPI(
    EnrollmentService.getEnrollmentSessions,
    getSessionsHandler
  );
  const addSessionAPI = useAPI(
    EnrollmentService.createEnrollmentSession,
    addSessionHandler
  );
  const deleteSessionAPI = useAPI(
    EnrollmentService.deleteEnrollmentSession,
    deleteSessionHandler,
    true
  );

  function getEnrollmentSessions() {
    getSessionsAPI.execute(hospitalId, patientId, enrollmentId);
  }

  function addSession() {
    addSessionAPI.execute(hospitalId, patientId, enrollmentId);
  }

  function deleteSession(sessionId) {
    deleteSessionAPI.execute(hospitalId, patientId, enrollmentId, sessionId);
  }

  function getSessionsHandler(response) {
    let data = response.data;
    let list = [];
    data.map((session, index) =>
      list.push(transformJsonData(session, index + 1))
    );
    setEnrollmentSessions(list);
  }

  function addSessionHandler(response) {
    setEnrollmentSessions((prevState) => [
      ...prevState,
      transformJsonData(response.data, prevState.length + 1),
    ]);
  }

  function deleteSessionHandler(response) {
    getEnrollmentSessions();
  }

  useEffect(() => {
    getEnrollmentSessions();
  }, []);

  return (
    <Paper elevation={0} className="p-4">
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Button onClick={() => navigate(-1)} variant="outlined">
          Cancel
        </Button>
        {addSessionAPI.status === "pending" ? (
          <LoadingButton
            loading
            loadingPosition="start"
            startIcon={<img src="assets/plus-icon.svg" alt="Add icon" />}
            variant="contained"
          >
            Add Session
          </LoadingButton>
        ) : (
          <Button
            className="text-right"
            onClick={addSession}
            startIcon={<img src="assets/plus-icon.svg" alt="Add icon" />}
            variant="contained"
          >
            Add Session
          </Button>
        )}
      </Stack>
      {getSessionsAPI.status === "pending" ? (
        <div className="text-center">
          <CircularProgress
            className="text-center"
            sx={{
              my: 20,
            }}
          />
        </div>
      ) : (
        <EnhancedTable
          headings={tableHeadings}
          rowsData={enrollmentSessions}
          rowsPerPage={10}
        />
      )}
      <PopUpModal ref={auditFieldModalRef} modalWidth={320}>
        <AuditFieldsModal data={auditFieldModalContent} />
      </PopUpModal>
      <PopUpModal ref={deleteSessionModalRef} modalWidth={400}>
        <ConfirmActionModal
          content="Do you want to confirm deleting the session?"
          confirmActionHandler={() => {
            deleteSession(deletingSessionId);
            deleteSessionModalRef.current.closeModal();
          }}
          onCloseHandler={() => deleteSessionModalRef.current.closeModal()}
        />
      </PopUpModal>
    </Paper>
  );

  function transformJsonData(session, index) {
    return {
      id: session.id,
      index,
      name: "Session",
      status: getStatusChip(session.isCompleted),
      actions: <ActionsCell session={session} />,
    };
  }

  function ActionsCell({ session }) {
    const actions = {
      startItems: [
        {
          component: (
            <InfoOutlinedIcon fontSize="inherit" color="custom.light" />
          ),
          onClickHandler: () => {
            setAuditFieldModalContent({
              createdAt: DateUtils.formatDateTime(session.createdAt),
              lastUpdatedAt: DateUtils.formatDateTime(session.lastUpdatedAt),
              createdBy: session.createdByUserFullName,
              lastUpdatedBy: session.lastUpdatedByUserFullName,
            });
            auditFieldModalRef.current.openModal();
          },
        },
        {
          component: <VisibilityIcon fontSize="inherit" color="secondary" />,
          onClickHandler: () => navigate(`${session.id}`),
        },
        {
          component: <DeleteOutlineIcon fontSize="inherit" color="error" />,
          onClickHandler: () => {
            setDeletingSessionId(session.id);
            deleteSessionModalRef.current.openModal();
          },
        },
      ],
      menuItems: [],
    };

    return <TableRowActionsCell actions={actions} />;
  }
}

function getStatusChip(isCompleted) {
  let color = "warning";
  let label = "Not Completed";
  if (isCompleted) {
    color = "success";
    label = "Completed";
  }
  return <Chip label={label} color={color} />;
}

const tableHeadings = [
  {
    id: "id",
    label: "ID",
  },
  {
    id: "sno",
    label: "S.No.",
  },
  {
    id: "name",
    label: "Name",
  },
  {
    id: "status",
    label: "Status",
  },
  {
    id: "actions",
    label: "",
  },
];
