import React from "react";
import PropTypes from "prop-types";
import { toPairs } from "lodash";
import numericalFieldHandler from "../../../utils/numericalFieldHandler";
import translateLabel from "../../../utils/translateLabel";

const RepeatWeekly = ({
  id,
  weekly: { interval, days, options },
  handleChange,
  translations,
}) => {
  let daysArray = toPairs(days);
  if (options.weekStartsOnSunday) {
    daysArray = daysArray.slice(-1).concat(daysArray.slice(0, -1));
  }

  return (
    <div className="py-3">
      <div className="form-group row d-flex align-items-sm-center mb-3">
        <div className="col-sm-2">
          {translateLabel(translations, "repeat.weekly.every")}
        </div>
        <div className="col-sm-3">
          <input
            id={`${id}-interval`}
            name="repeat.weekly.interval"
            aria-label="Repeat weekly interval"
            className="form-control"
            value={interval}
            onChange={numericalFieldHandler(handleChange)}
          />
        </div>
        <div className="col-sm-2">
          {translateLabel(translations, "repeat.weekly.weeks")}
        </div>
      </div>

      <div className="form-group row">
        <div
          className="btn-group btn-group-sm"
          role="group"
          aria-label="Weekly button group"
        >
          {daysArray.map(([dayName, isDayActive]) => (
            <>
              <input
                key={`${id}-${dayName}-input`}
                type="checkbox"
                id={`${id}-${dayName}`}
                name={`repeat.weekly.days[${dayName}]`}
                className="btn-check"
                checked={isDayActive}
                onChange={(event) => {
                  const editedEvent = {
                    ...event,
                    target: {
                      ...event.target,
                      value: !isDayActive,
                      name: event.target.name,
                    },
                  };

                  handleChange(editedEvent);
                }}
                autoComplete="off"
              />
              <label
                key={`${id}-${dayName}-label`}
                htmlFor={`${id}-${dayName}`}
                key={dayName}
                className={`btn btn-outline-primary ${
                  isDayActive ? "active" : ""
                }`}
              >
                {translateLabel(
                  translations,
                  `days_short.${dayName.toLowerCase()}`
                )}
              </label>
            </>
          ))}
        </div>
      </div>
    </div>
  );
};

RepeatWeekly.propTypes = {
  id: PropTypes.string.isRequired,
  weekly: PropTypes.shape({
    interval: PropTypes.number.isRequired,
    days: PropTypes.shape({
      mon: PropTypes.bool.isRequired,
      tue: PropTypes.bool.isRequired,
      wed: PropTypes.bool.isRequired,
      thu: PropTypes.bool.isRequired,
      fri: PropTypes.bool.isRequired,
      sat: PropTypes.bool.isRequired,
      sun: PropTypes.bool.isRequired,
    }).isRequired,
    options: PropTypes.shape({
      weekStartsOnSunday: PropTypes.bool,
    }).isRequired,
  }).isRequired,
  handleChange: PropTypes.func.isRequired,
  translations: PropTypes.oneOfType([PropTypes.object, PropTypes.func])
    .isRequired,
};

export default RepeatWeekly;
