const SnackbarConstants = {
  maxSnack: 10,
  autoHideDuration: 3000,
  anchorOrigin: {vertical: "top", horizontal: "right"},
  success: {
    variant: "success"
  },
  error: {
    variant: "error"
  },
  warning: {
    variant: "warning"
  }
};

export default SnackbarConstants;
