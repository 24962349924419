import ApiBaseService from "services/ApiBaseService";

class SignupService {
  static registerPatient(data, captchaToken) {
    const headers = {
      "Captcha-Token": captchaToken,
    };
    return ApiBaseService.request(
      "/patients/signup/register",
      "POST",
      data,
      headers
    );
  }

  static verifyRegisteredPatient(data, captchaToken) {
    const headers = {
      "Captcha-Token": captchaToken,
    };
    return ApiBaseService.request(
      "/patients/signup/verify",
      "POST",
      data,
      headers
    );
  }

  static signupPatient(data, captchaToken) {
    const headers = {
      "Captcha-Token": captchaToken,
    };
    return ApiBaseService.request(`/patients/signup`, "POST", data, headers);
  }

  static resendOTP(data, captchaToken) {
    const headers = {
      "Captcha-Token": captchaToken,
    };
    return ApiBaseService.request(
      "/patients/signup/register/otp",
      "POST",
      data,
      headers
    );
  }
}

export default SignupService;
