import { useState, useEffect, useRef, useCallback } from "react";
import Paper from "@mui/material/Paper";
import { useNavigate, useSearchParams } from "react-router-dom";
import Button from "@mui/material/Button";
import ColumnBarChart from "components/charts/ColumnBarChart";
import RadialChart from "components/charts/RadialChart";
import useAPI from "hooks/useAPI.js";
import DoctorFormService from "services/DoctorFormService.js";
import DoctorAsmtDataUtils from "utils/DoctorAsmtChartDataUtils";
import StringUtils from "utils/StringUtils.js";
import DOCTOR_ASMT_CHART_PROPS from "utils/constants/DoctorAsmtChartProps";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import DoctorAsmtChartDataUtils from "utils/DoctorAsmtChartDataUtils";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import PatientService from "services/PatientService.js";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PopUpModal from "components/utils/PopUpModal.js";
import AuditFieldsModal from "components/modals/AuditFieldsModal.js";
import ReactToPrint from "react-to-print";
import { IconButton, Tooltip } from "@mui/material";
import PendingIcon from "@mui/icons-material/Pending";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import PrintIcon from "@mui/icons-material/Print";
import DateUtils from "utils/DateUtils.js";
import { useOutletContext } from "react-router-dom";

function AssessmentAnalysis() {
  let navigate = useNavigate();
  const [hospitalId] = useOutletContext();
  let [searchParams] = useSearchParams();
  const assessmentChartsRef = useRef();
  let patientId = searchParams.get("patientId");
  const [formsList, setFormsList] = useState([]);
  const [assessmentsStagedData, setAssessmentsStagedData] = useState();
  const [isPrintLoading, setIsPrintLoading] = useState(false);

  const [patientDetails, setPatientDetails] = useState({
    fullName: "",
    email: "",
    patientId: "",
    age: "",
  });

  const getPatientDetailsAPI = useAPI(
    PatientService.getPatient,
    getPatientDetailsHandler
  );

  const getFormsAPI = useAPI(
    DoctorFormService.getAssessmentForms,
    getFormsHandler
  );

  const getDwFormDataAPI = useAPI(
    DoctorFormService.getDwAssessmentFormData,
    getDwFormDataHandler
  );

  const getPatientDetails = useCallback(() => {
    getPatientDetailsAPI.execute(hospitalId, patientId);
  }, []);

  const getForms = useCallback(() => {
    getFormsAPI.execute(hospitalId, patientId);
  }, [formsList]);

  const getDwFormData = useCallback(() => {
    getDwFormDataAPI.execute(hospitalId, patientId);
  }, []);

  useEffect(() => {
    getPatientDetails();
    getForms();
    getDwFormData();
  }, [patientId]);

  /* API Success Handlers */

  function getPatientDetailsHandler(response) {
    let data = response.data;

    setPatientDetails({
      fullName: data.firstName + " " + data.lastName,
      email: data.email,
      patientId: data.id,
      age: StringUtils.getAgeFromDOB(data.dob),
      gender: data.gender.toLowerCase(),
    });
  }

  function getFormsHandler(response) {
    let data = response.data;
    setFormsList(data);
  }

  function getDwFormDataHandler(response) {
    let result = DoctorAsmtDataUtils.buildKeyChains(response.data);
    setAssessmentsStagedData(result);
  }

  return (
    <Paper elevation={0} className="p-3">
      <Button onClick={() => navigate(-1)} variant="outlined">
        Back
      </Button>
      <div className="my-3">
        {getDwFormDataAPI.status === "pending" ? (
          <div className="text-center">
            <CircularProgress className="text-center" />
          </div>
        ) : (
          <div ref={assessmentChartsRef}>
            <div className="d-flex justify-content-between mb-3">
              <PatientDetails patientDetails={patientDetails} />
              <AssessmentsDetail
                assessmentFormsList={formsList}
                assessmentsStagedData={assessmentsStagedData}
              />
            </div>
            <div className="d-flex justify-content-end mb-3">
              <ReactToPrint
                trigger={() => (
                  <a>
                    <Tooltip title="Print Analytics">
                      <IconButton>
                        {isPrintLoading ? (
                          <PendingIcon disabled />
                        ) : (
                          <PrintIcon />
                        )}
                      </IconButton>
                    </Tooltip>
                  </a>
                )}
                content={() => assessmentChartsRef.current}
                onBeforeGetContent={() => {
                  setIsPrintLoading(true);
                  return Promise.resolve();
                }}
                onAfterPrint={() => {
                  setIsPrintLoading(false);
                }}
              />
            </div>
            {DOCTOR_ASMT_CHART_PROPS.map((assessmentCharts, index) => {
              return (
                <RenderChart
                  key={`ASMT_CHART_${index}`}
                  componentKey={`ASMT_CHART_${index}`}
                  assessmentCharts={assessmentCharts}
                  assessmentsStagedData={assessmentsStagedData}
                  patientDetails={patientDetails}
                />
              );
            })}
          </div>
        )}
      </div>
    </Paper>
  );
}

function PatientDetails({ patientDetails }) {
  const theme = useTheme();

  return (
    <Paper
      className="p-3"
      sx={{
        width: "260px",
        height: "130px",
        backgroundColor: theme.palette.background.default,
      }}
      elevation={0}
    >
      <div className="d-flex justify-content-between">
        <Typography
          variant="body1"
          sx={{
            textDecoration: "underline",
          }}
          className="mb-2"
        >
          Patient Details
        </Typography>
        <Typography variant="h6" color={theme.palette.text.main}>
          {patientDetails.patientId}
        </Typography>
      </div>

      <div
        style={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        <Typography variant="subtitle2">{patientDetails.fullName}</Typography>
        <Typography variant="body2">{patientDetails.email}</Typography>
      </div>
    </Paper>
  );
}

function AssessmentsDetail({ assessmentFormsList, assessmentsStagedData }) {
  const [popUpModalContent, setPopUpModalContent] = useState({
    createdAt: "",
    lastUpdatedAt: "",
    createdBy: "",
    lastUpdatedBy: "",
  });

  const popUpModalRef = useRef(null);

  if (assessmentsStagedData === undefined) return null;

  return (
    <Paper
      variant="outlined"
      className="p-3 text-center"
      sx={{
        width: "280px",
        height: "130px",
        overflowY: "scroll",
      }}
    >
      <Typography
        variant="body1"
        sx={{
          textDecoration: "underline",
        }}
        className="mb-2"
      >
        Assessment Details
      </Typography>
      {assessmentsStagedData.map((assessment, index) => {
        const assessmentForm = getFormById(assessment.assessmentId);
        return (
          <div className="row gx-0" key={`ASMT_DETAIL_${index}`}>
            <div className="col-5">
              <Typography variant="subtitle2" color="text.secondary">
                {"Assessment " + assessment.assessmentNumber}
              </Typography>
            </div>
            <div className="col-5">
              <Typography variant="subtitle2" color="text.secondary">
                {assessment.assessmentType}
              </Typography>
            </div>
            <div className="col-2">
              <InfoOutlinedIcon
                fontSize="inherit"
                color="custom.light"
                onClick={() => {
                  setPopUpModalContent({
                    createdAt: DateUtils.formatDateTime(
                      assessmentForm.createdAt
                    ),
                    lastUpdatedAt: DateUtils.formatDateTime(
                      assessmentForm.lastUpdatedAt
                    ),
                    createdBy: assessmentForm.createdByUserFullName,
                    lastUpdatedBy: assessmentForm.lastUpdatedByUserFullName,
                  });
                  popUpModalRef.current.openModal();
                }}
              />
            </div>
          </div>
        );
      })}
      <PopUpModal ref={popUpModalRef} modalWidth={320}>
        <AuditFieldsModal data={popUpModalContent} />
      </PopUpModal>
    </Paper>
  );

  function getFormById(id) {
    return assessmentFormsList.filter((form) => form.id === id)[0];
  }
}

function RenderChart({
  componentKey,
  assessmentCharts,
  assessmentsStagedData,
  patientDetails,
}) {
  if (assessmentsStagedData === undefined) return null;
  return (
    <Accordion className="px-3" defaultExpanded={true}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`${componentKey}-content`}
        id={`${componentKey}-header`}
      >
        <Typography variant="h4" color="text.light">
          {assessmentCharts.sectionName}
        </Typography>
      </AccordionSummary>

      <AccordionDetails className="d-flex flex-wrap justify-content-between">
        {assessmentCharts.charts.map((asmtChart, index) => {
          // Calculate normative data of chart
          setChartNormativeData(
            asmtChart,
            patientDetails.age,
            patientDetails.gender
          );
          return (
            <Box
              key={`ASMT_SEC_CHART_${index}`}
              sx={{
                backgroundColor: "background.paper",
                "&:hover": {
                  backgroundColor: "background.default",
                },
              }}
              className="py-4"
            >
              {getChartBasedOnType(assessmentsStagedData, asmtChart)}
            </Box>
          );
        })}
      </AccordionDetails>
    </Accordion>
  );
}

function getChartBasedOnType(assessmentsStagedData, asmtChart) {
  if (asmtChart.type === "columnBar") {
    return (
      <ColumnBarChart
        chartProps={asmtChart}
        data={DoctorAsmtChartDataUtils.getColumnBarDataSeries(
          assessmentsStagedData,
          asmtChart.dataKeyChains
        )}
      />
    );
  } else if (asmtChart.type === "radial") {
    return (
      <RadialChart
        chartProps={asmtChart}
        dataLabels={DoctorAsmtChartDataUtils.getRadialDataLabels(
          assessmentsStagedData
        )}
        data={DoctorAsmtChartDataUtils.getRadialDataSeries(
          assessmentsStagedData,
          asmtChart.dataKeyChains
        )}
      />
    );
  } else {
    return null;
  }
}

function setChartNormativeData(asmtChart, patientAge, patientGender) {
  let normativeData = DoctorAsmtChartDataUtils.getNormativeData(
    asmtChart,
    patientAge,
    patientGender
  );
  if (normativeData != null) {
    asmtChart.annotations.yaxis.push(...normativeData);
  }
}

export default AssessmentAnalysis;
