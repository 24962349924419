import { useState, useEffect, useRef } from "react";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { useParams } from "react-router";
import { Outlet } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { CardActionArea } from "@mui/material";
import Typography from "@mui/material/Typography";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Chip from "@mui/material/Chip";
import LoadingButton from "@mui/lab/LoadingButton";
import EnrollmentService from "services/EnrollmentService.js";
import PopUpModal from "components/utils/PopUpModal.js";
import ConfirmActionModal from "components/modals/ConfirmActionModal";
import useAPI from "hooks/useAPI.js";
import DateUtils from "utils/DateUtils.js";
import { useOutletContext } from "react-router-dom";

export function Enrollments() {
  const [hospitalId] = useOutletContext();

  return <Outlet context={[hospitalId]} />;
}
export function EnrollmentList() {
  const [hospitalId] = useOutletContext();
  const [enrollmentList, setEnrollmentList] = useState([]);
  const { patientId } = useParams();

  const deleteEnrollmentModalRef = useRef(null);
  const [deletingEnrollmentId, setDeletingEnrollmentId] = useState("");

  const getEnrollmentsAPI = useAPI(
    EnrollmentService.getPatientEnrollments,
    getEnrollmentsHandler
  );
  const addEnrollmentAPI = useAPI(
    EnrollmentService.createEnrollment,
    addEnrollmentHandler
  );
  const deleteEnrollmentAPI = useAPI(
    EnrollmentService.deleteEnrollment,
    deleteEnrollmentHandler,
    true
  );

  function getEnrollments() {
    getEnrollmentsAPI.execute(hospitalId, patientId);
  }

  function addEnrollment() {
    addEnrollmentAPI.execute(hospitalId, patientId);
  }

  function deleteEnrollment(enrollmentId) {
    deleteEnrollmentAPI.execute(hospitalId, patientId, enrollmentId);
  }

  useEffect(() => {
    getEnrollments();
  }, []);

  /* API Success Handlers */

  function getEnrollmentsHandler(response) {
    let data = response.data;
    setEnrollmentList(data);
  }

  function addEnrollmentHandler(response) {
    setEnrollmentList((prevState) => [...prevState, response.data]);
  }

  function deleteEnrollmentHandler(response) {
    getEnrollments();
  }

  return (
    <Paper elevation={0} className="p-4">
      <Stack direction="row" justifyContent="right" alignItems="center">
        {addEnrollmentAPI.status === "pending" ? (
          <LoadingButton
            loading
            loadingPosition="start"
            startIcon={<img src="assets/plus-icon.svg" alt="Add icon" />}
            variant="contained"
          >
            Create Enrollment
          </LoadingButton>
        ) : (
          <Button
            className="text-right"
            onClick={addEnrollment}
            startIcon={<img src="assets/plus-icon.svg" alt="Add icon" />}
            variant="contained"
          >
            Create Enrollment
          </Button>
        )}
      </Stack>
      {getEnrollmentsAPI.status === "pending" ? (
        <div className="text-center">
          <CircularProgress
            className="text-center"
            sx={{
              my: 20,
            }}
          />
        </div>
      ) : (
        <div className="d-flex align-items-center flex-wrap mt-3">
          {enrollmentList.map((enrollment, index) => {
            return (
              <EnrollmentCard
                key={index}
                index={index + 1}
                enrollment={enrollment}
              />
            );
          })}
        </div>
      )}
      <PopUpModal ref={deleteEnrollmentModalRef} modalWidth={400}>
        <ConfirmActionModal
          content="Do you want to confirm deleting the enrollment?"
          confirmActionHandler={() => {
            deleteEnrollment(deletingEnrollmentId);
            deleteEnrollmentModalRef.current.closeModal();
          }}
          onCloseHandler={() => deleteEnrollmentModalRef.current.closeModal()}
        />
      </PopUpModal>
    </Paper>
  );

  function EnrollmentCard({ enrollment, index }) {
    const theme = useTheme();
    let navigate = useNavigate();

    return (
      <Card
        sx={{
          width: 240,
          height: 150,
          mx: 4,
          my: 3,
        }}
      >
        <CardActionArea onClick={() => navigate(`${enrollment.id}/sessions`)}>
          <CardContent
            sx={{
              px: 4,
              background: theme.palette.background.default,
            }}
          >
            <Stack
              direction="row"
              justifyContent="space-between"
              sx={{
                marginBottom: 3,
              }}
            >
              <div>
                <Typography color="text.secondary" variant="body1">
                  Enrollment
                </Typography>
                <Typography
                  variant="h2"
                  sx={{
                    marginTop: 2,
                  }}
                  color="text.secondary"
                >
                  {index}
                </Typography>
              </div>
              <div>
                <Typography color="text.secondary" variant="body1">
                  Sessions
                </Typography>
                <Typography
                  sx={{
                    marginTop: 1,
                    textAlign: "center",
                  }}
                  component="div"
                >
                  <Chip
                    color="info"
                    label={enrollment.enrollmentSessions.length}
                  />
                </Typography>
              </div>
            </Stack>
            <Stack
              direction="row"
              justifyContent="space-between"
              sx={{
                marginBottom: 3,
              }}
              className="align-items-end"
            >
              <div>
                <Typography color="text.secondary" variant="caption">
                  Created At
                </Typography>
                <Typography variant="subtitle2" component="div">
                  {DateUtils.formatDateTime(enrollment.createdAt)}
                </Typography>
              </div>
              <IconButton
                size="small"
                onClick={(event) => {
                  event.stopPropagation();
                  setDeletingEnrollmentId(enrollment.id);
                  deleteEnrollmentModalRef.current.openModal();
                }}
              >
                <Tooltip title="Delete Enrollment">
                  <DeleteOutlineIcon fontSize="inherit" color="error" />
                </Tooltip>
              </IconButton>
            </Stack>
          </CardContent>
        </CardActionArea>
      </Card>
    );
  }
}
