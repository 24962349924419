import ApiBaseService from "services/ApiBaseService.js";

class DoctorFormService {
  static addAssessmentForm([hospitalId, patientId, data]) {
    return ApiBaseService.request(
      `hospitals/${hospitalId}/patients/${patientId}/assessment-forms`,
      "POST",
      data
    );
  }

  static getAssessmentForms([hospitalId, patientId]) {
    return ApiBaseService.request(
      `hospitals/${hospitalId}/patients/${patientId}/assessment-forms`,
      "GET"
    );
  }

  static getAssessmentForm(hospitalId, patientId, formId) {
    return ApiBaseService.request(
      `hospitals/${hospitalId}/patients/${patientId}/assessment-forms/${formId}`,
      "GET"
    );
  }

  static saveAssessmentForm(hospitalId, patientId, formId, data) {
    return ApiBaseService.request(
      `hospitals/${hospitalId}/patients/${patientId}/assessment-forms/${formId}`,
      "PUT",
      data
    );
  }

  static submitAssessmentForm(hospitalId, patientId, formId) {
    return ApiBaseService.request(
      `hospitals/${hospitalId}/patients/${patientId}/assessment-forms/${formId}`,
      "POST",
      {}
    );
  }

  static deleteAssessmentForm([hospitalId, patientId, formId]) {
    return ApiBaseService.request(
      `hospitals/${hospitalId}/patients/${patientId}/assessment-forms/${formId}`,
      "DELETE"
    );
  }

  static revokeAssessmentFormSubmission([hospitalId, patientId, formId]) {
    return ApiBaseService.request(
      `hospitals/${hospitalId}/patients/${patientId}/assessment-forms/${formId}/revoke`,
      "POST"
    );
  }

  static getDwAssessmentFormData([hospitalId, patientId]) {
    return ApiBaseService.request(
      `hospitals/${hospitalId}/patients/${patientId}/assessment-forms/dw/data`,
      "GET"
    );
  }
}

export default DoctorFormService;
