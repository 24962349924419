import React, { useState, useCallback, useEffect, useRef } from "react";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Chip from "@mui/material/Chip";
import { useForm } from "react-hook-form";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import DoneIcon from "@mui/icons-material/Done";
import SaveIcon from "@mui/icons-material/Save";
import CircularProgress from "@mui/material/CircularProgress";
import useAPI from "hooks/useAPI.js";
import InputErrorMessage from "components/utils/InputErrorMessage.js";
import PopUpModal from "components/utils/PopUpModal.js";
import EventRecurrenceModal from "./EventRecurrenceModal";
import PatientService from "services/PatientService.js";
import OrganizationService from "services/OrganizationService";
import SchedulingService from "services/SchedulingService";
import DateUtils from "utils/DateUtils";
import { useSnackbar } from "notistack";
import SnackbarConstants from "utils/constants/Snackbar.js";

const AddAppointmentModal = ({ hospitalId, onCloseHandler }) => {
  const [patientList, setPatientList] = useState([]);
  const [doctorList, setDoctortList] = useState([]);
  const [eventGuestList, setEventGuestList] = useState([]);
  const [eventGuest, setEventGuest] = useState("");
  const [isEventRecurring, setIsEventRecurring] = useState(false);
  const [rRule, setRrule] = useState("RRULE:FREQ=DAILY;INTERVAL=1;COUNT=1");

  const { enqueueSnackbar } = useSnackbar();

  const setEventRecurrenceModalRef = useRef(null);

  const {
    register,
    formState: { errors },
    handleSubmit,
    getValues,
    setValue,
  } = useForm({ criteriaMode: "all", mode: "onTouched" });

  const fetchPatientsAPI = useAPI(
    PatientService.getAllPatients,
    fetchPatientsHandler,
    false
  );

  const fetchDoctorsAPI = useAPI(
    OrganizationService.getHospitalDoctors,
    fetchDoctorsHandler,
    false
  );

  const createAppointmentAPI = useAPI(
    SchedulingService.createAppointment,
    createAppointmentHandler,
    false
  );

  useEffect(() => {
    fetchPatients();
    fetchDoctors();
  }, [hospitalId]);

  const fetchPatients = useCallback(() => {
    // Fetch patients only after hospital has been set
    if (hospitalId !== "") {
      fetchPatientsAPI.execute(hospitalId);
    }
  }, [fetchPatientsAPI]);

  const fetchDoctors = useCallback(() => {
    // Fetch patients only after hospital has been set
    if (hospitalId !== "") {
      fetchDoctorsAPI.execute(hospitalId);
    }
  }, [fetchDoctorsAPI]);

  function createAppointment(data) {
    if (isEventRecurring && rRule === "") {
      enqueueSnackbar("Recurrence is not set", SnackbarConstants.error);
      return;
    }

    data.calendarEvent.isRecurring = isEventRecurring;
    if (isEventRecurring) {
      const rule = rRule.substring(6); // removes the preceding 'RRULE:'
      data.calendarEvent.recurrentEvent = {
        rule,
        until: DateUtils.addOneYear(data.calendarEvent.startDate),
      };
    }

    data.doctorId = data.doctorId.match(/\((.*?)\)/)[1];
    data.patientId = data.patientId.match(/\((.*?)\)/)[1];
    data.calendarEvent.eventGuests = eventGuestList.map((guest) => guest.email);

    data.calendarEvent.startsAt = DateUtils.formatDateTimeToTimestamp(
      data.calendarEvent.startDate + " " + data.calendarEvent.startTime
    );
    data.calendarEvent.endsAt = DateUtils.formatDateTimeToTimestamp(
      data.calendarEvent.endDate + " " + data.calendarEvent.endTime
    );

    createAppointmentAPI.execute(hospitalId, data);
  }

  /* API Success Handlers */
  function fetchPatientsHandler(response) {
    let patients = [];
    response.data.content.map((patient) =>
      patients.push({
        patient,
      })
    );

    setPatientList(patients);
  }

  function fetchDoctorsHandler(response) {
    let doctors = [];
    response.data.map((doctor) =>
      doctors.push({
        doctor,
      })
    );

    setDoctortList(doctors);
  }

  function createAppointmentHandler(response) {
    onCloseHandler();
  }

  return (
    <>
      <div className="my-2">
        <div
          className="rounded p-3"
          style={{
            backgroundColor: "#eef3f8",
          }}
        >
          <Typography variant="h3">Create Appointment</Typography>
        </div>
        <form className="m-3" onSubmit={handleSubmit(createAppointment)}>
          <div className="row">
            <div className="col col-6 my-3">
              <Autocomplete
                disablePortal
                id="patientInput"
                getOptionLabel={(option) =>
                  `${option.patient.firstName}  ${option.patient.lastName} (${option.patient.id})`
                }
                options={patientList}
                loading={fetchPatientsAPI.status === "pending"}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Patient"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {fetchPatientsAPI.status === "pending" ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                    {...register("patientId", {
                      required: "Patient is required",
                    })}
                  />
                )}
                onChange={(event, value) => {
                  // Remove the already present patient from guest list
                  setEventGuestList((prevState) => {
                    let index = prevState.findIndex(
                      (guest) => guest.type === "patient"
                    );
                    if (index !== -1) prevState.splice(index, 1);
                    return [...prevState];
                  });

                  // Add patient to guest list if value is present
                  if (value) {
                    setEventGuestList((prevState) => {
                      return [
                        ...prevState,
                        {
                          type: "patient",
                          email: value.patient.email,
                          isDisabled: true,
                        },
                      ];
                    });

                    // Set event title
                    setValue(
                      "calendarEvent.title",
                      `Appointment: ${value.patient.firstName} <> ${
                        getValues("doctorId").split(" ")[0]
                      }`
                    );
                  }
                }}
              />
              <InputErrorMessage name="patientId" errors={errors} />
            </div>
            <div className="col col-6 my-3">
              <Autocomplete
                disablePortal
                id="doctorInput"
                getOptionLabel={(option) =>
                  `${option.doctor.firstName}  ${option.doctor.lastName} (${option.doctor.id})`
                }
                options={doctorList}
                loading={fetchDoctorsAPI.status === "pending"}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Doctor"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {fetchDoctorsAPI.status === "pending" ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                    {...register("doctorId", {
                      required: "Doctor is required",
                    })}
                  />
                )}
                onChange={(event, value) => {
                  // Remove the already present doctor from guest list
                  setEventGuestList((prevState) => {
                    let index = prevState.findIndex(
                      (guest) => guest.type === "doctor"
                    );
                    if (index !== -1) prevState.splice(index, 1);
                    return [...prevState];
                  });

                  // Add doctor to guest list if present
                  if (value) {
                    setEventGuestList((prevState) => {
                      return [
                        ...prevState,
                        {
                          type: "doctor",
                          email: value.doctor.email,
                          isDisabled: true,
                        },
                      ];
                    });

                    // Set event title
                    setValue(
                      "calendarEvent.title",
                      `Appointment: ${
                        getValues("patientId").split(" ")[0]
                      } <> ${value.doctor.firstName}`
                    );
                  }
                }}
              />
              <InputErrorMessage name="doctorId" errors={errors} />
            </div>
            <div className="col col-12 mb-3">
              <label htmlFor="titleInput" className="form-label">
                Title
              </label>
              <input
                type="text"
                className="form-control"
                id="titleInput"
                {...register("calendarEvent.title", {
                  required: "Title is required",
                })}
              />
              <InputErrorMessage name="calendarEvent.title" errors={errors} />
            </div>
            <div className="col col-12 mb-3">
              <label htmlFor="descriptionInput" className="form-label">
                Description
              </label>
              <textarea
                rows={3}
                className="form-control"
                id="descriptionInput"
                {...register("calendarEvent.description", {
                  required: "Description is required",
                })}
              />
              <InputErrorMessage
                name="calendarEvent.description"
                errors={errors}
              />
            </div>
            <div className="col col-3 mb-3">
              <label htmlFor="startDateInput" className="form-label">
                Start Date
              </label>
              <input
                type="date"
                className="form-control"
                id="startDateInput"
                {...register("calendarEvent.startDate", {
                  required: "Start date is required",
                })}
              />
              <InputErrorMessage
                name="calendarEvent.startDate"
                errors={errors}
              />
            </div>
            <div className="col col-3 mb-3">
              <label htmlFor="endDateInput" className="form-label">
                End Date
              </label>
              <input
                type="date"
                className="form-control"
                id="endDateInput"
                {...register("calendarEvent.endDate", {
                  required: "End date is required",
                })}
              />
              <InputErrorMessage name="calendarEvent.endDate" errors={errors} />
            </div>
            <div className="col col-3 mb-3">
              <label htmlFor="startTimeInput" className="form-label">
                Start Time
              </label>
              <input
                type="time"
                className="form-control"
                id="startTimeInput"
                {...register("calendarEvent.startTime", {
                  required: "Start time is required",
                })}
              />
              <InputErrorMessage
                name="calendarEvent.startTime"
                errors={errors}
              />
            </div>
            <div className="col col-3 mb-3">
              <label htmlFor="endTimeInput" className="form-label">
                End Time
              </label>
              <input
                type="time"
                className="form-control"
                id="endTimeInput"
                {...register("calendarEvent.endTime", {
                  required: "End time is required",
                })}
              />
              <InputErrorMessage name="calendarEvent.endTime" errors={errors} />
            </div>
            <div className="col col-6 mb-3">
              <label className="form-label d-block">Recurring</label>
              <div className="form-check form-switch">
                <input
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  id="isRecurringInput"
                  name="isRecurring"
                  onChange={() => {
                    setIsEventRecurring(!isEventRecurring);
                  }}
                />
                {isEventRecurring ? (
                  <Button
                    onClick={() => {
                      setEventRecurrenceModalRef.current.openModal();
                    }}
                    size="small"
                    variant="outlined"
                    className="ms-2"
                  >
                    Set Recurrence
                  </Button>
                ) : null}
              </div>
            </div>
            <div className="col col-6 mb-3">
              <label className="form-label d-block">Location Type</label>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  id="locationTypeOnline"
                  name="locationType"
                  value="Online"
                  {...register("calendarEvent.locationType", {
                    required: "Location type is required",
                  })}
                />
                <label
                  className="form-check-label"
                  htmlFor="locationTypeOnline"
                >
                  Online
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  id="locationTypeOffline"
                  name="locationType"
                  value="Offline"
                  {...register("calendarEvent.locationType", {
                    required: "Location type is required",
                  })}
                />
                <label
                  className="form-check-label"
                  htmlFor="locationTypeOffline"
                >
                  Offline
                </label>
              </div>
              <InputErrorMessage
                name="calendarEvent.locationType"
                errors={errors}
              />
            </div>
            <div className="col col-7 mb-3">
              <label htmlFor="eventGuestInput" className="form-label">
                Event Guests
              </label>
              <div className="d-flex flex-row">
                <input
                  type="text"
                  className="form-control"
                  id="eventGuestInput"
                  placeholder="Guest email id"
                  value={eventGuest}
                  onChange={(event) => {
                    setEventGuest(event.target.value);
                  }}
                />
                <Button
                  type="button"
                  className="ms-2"
                  size="small"
                  variant="outlined"
                  onClick={() => {
                    if (eventGuest != "") {
                      setEventGuestList((prevState) => {
                        return [
                          ...prevState,
                          {
                            type: "guest",
                            email: eventGuest,
                            isDisabled: false,
                          },
                        ];
                      });
                      setEventGuest("");
                    }
                  }}
                >
                  Add
                </Button>
              </div>
              <div className="d-flex flex-wrap mt-2">
                {eventGuestList.map((guest, index) => {
                  if (guest.isDisabled) {
                    return (
                      <Chip
                        key={index}
                        label={guest.email}
                        variant="outlined"
                        className="me-2 mb-2"
                      />
                    );
                  }
                  return (
                    <Chip
                      key={index}
                      label={guest.email}
                      variant="outlined"
                      className="me-2 mb-2"
                      onDelete={() => {
                        setEventGuestList((prevState) => {
                          let index = prevState.findIndex(
                            (guestItem) => guestItem.email === guest.email
                          );
                          prevState.splice(index, 1);
                          return [...prevState];
                        });
                      }}
                    />
                  );
                })}
              </div>
            </div>
          </div>
          <div className="text-end">
            <Button onClick={() => onCloseHandler()} variant="outlined">
              Close
            </Button>
            {createAppointmentAPI.status === "pending" ? (
              <LoadingButton
                loading
                loadingPosition="start"
                startIcon={<SaveIcon />}
                variant="contained"
                className="ms-2"
              >
                Submit
              </LoadingButton>
            ) : (
              <Button
                type="submit"
                startIcon={<DoneIcon />}
                variant="contained"
                className="ms-2"
              >
                Submit
              </Button>
            )}
          </div>
        </form>
      </div>
      <PopUpModal ref={setEventRecurrenceModalRef} modalWidth={600}>
        <EventRecurrenceModal
          rRule={rRule}
          setRrule={setRrule}
          onCloseHandler={() => {
            setEventRecurrenceModalRef.current.closeModal();
          }}
        />
      </PopUpModal>
    </>
  );
};

export default AddAppointmentModal;
