import React from "react";
import ReactDOM from "react-dom/client";
import "styles/index.css";
import App from "./App";
import ReactGA from "react-ga4";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import * as Sentry from "@sentry/react";
import sentryConfig from "./config/sentry";
import store from "./state/store";
import Cookies from "universal-cookie";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <Provider store={store}>
      <App />
    </Provider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

const setOrganizationDomain = () => {
  const cookies = new Cookies();

  const domain = window.location.hostname;
  let subDomain = domain.split(".")[0];

  if (subDomain === "localhost" || subDomain === "uat") {
    subDomain = "testorg";
  }
  cookies.set("organization", subDomain, { path: "/" });
};

// Immediately Invoked Function Expression
(() => {
  // Initialize Google ReCaptcha
  ReactGA.initialize(process.env.REACT_APP_G_TAG_ID);

  // Read and set organization domain
  setOrganizationDomain();

  // Initialize Sentry
  // Sentry.init(sentryConfig);

  // Disable logs if enviroment is not development
  if (process.env.NODE_ENV !== "development") console.log = () => {};
})();
