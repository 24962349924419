const ASMT_NORMATIVE_DATA = {
  // key -> chart title
  "Pulse Rate": [
    {
      label: "Norm",
      data: [
        {
          ageGt: 0,
          ageLte: 0.5,
          value: {
            male: {
              low: 90,
              high: 150,
            },
            female: {
              low: 90,
              high: 150,
            },
          },
        },
        {
          ageGt: 0.5,
          ageLte: 1,
          value: {
            male: {
              low: 80,
              high: 140,
            },
            female: {
              low: 80,
              high: 140,
            },
          },
        },
        {
          ageGt: 1,
          ageLte: 3,
          value: {
            male: {
              low: 80,
              high: 130,
            },
            female: {
              low: 80,
              high: 130,
            },
          },
        },
        {
          ageGt: 3,
          ageLte: 5,
          value: {
            male: {
              low: 80,
              high: 120,
            },
            female: {
              low: 80,
              high: 120,
            },
          },
        },
        {
          ageGt: 6,
          ageLte: 10,
          value: {
            male: {
              low: 70,
              high: 110,
            },
            female: {
              low: 70,
              high: 110,
            },
          },
        },
        {
          ageGt: 11,
          ageLte: 14,
          value: {
            male: {
              low: 60,
              high: 105,
            },
            female: {
              low: 60,
              high: 105,
            },
          },
        },
        {
          ageGt: 15,
          ageLte: 20,
          value: {
            male: {
              low: 60,
              high: 100,
            },
            female: {
              low: 60,
              high: 100,
            },
          },
        },
        {
          ageGt: 20,
          ageLte: 100,
          value: {
            male: {
              low: 50,
              high: 80,
            },
            female: {
              low: 50,
              high: 80,
            },
          },
        },
      ],
    },
  ],
  "Respiration Rate": [
    {
      label: "Norm",
      data: [
        {
          ageGt: 0,
          ageLte: 0.5,
          value: {
            male: {
              low: 25,
              high: 40,
            },
            female: {
              low: 25,
              high: 40,
            },
          },
        },
        {
          ageGt: 0.5,
          ageLte: 1,
          value: {
            male: {
              low: 20,
              high: 30,
            },
            female: {
              low: 20,
              high: 30,
            },
          },
        },
        {
          ageGt: 1,
          ageLte: 3,
          value: {
            male: {
              low: 20,
              high: 30,
            },
            female: {
              low: 20,
              high: 30,
            },
          },
        },
        {
          ageGt: 3,
          ageLte: 5,
          value: {
            male: {
              low: 20,
              high: 30,
            },
            female: {
              low: 20,
              high: 30,
            },
          },
        },
        {
          ageGt: 6,
          ageLte: 10,
          value: {
            male: {
              low: 15,
              high: 30,
            },
            female: {
              low: 15,
              high: 30,
            },
          },
        },
        {
          ageGt: 11,
          ageLte: 14,
          value: {
            male: {
              low: 12,
              high: 20,
            },
            female: {
              low: 12,
              high: 20,
            },
          },
        },
        {
          ageGt: 15,
          ageLte: 20,
          value: {
            male: {
              low: 12,
              high: 30,
            },
            female: {
              low: 12,
              high: 30,
            },
          },
        },
        {
          ageGt: 20,
          ageLte: 100,
          value: {
            male: {
              low: 16,
              high: 20,
            },
            female: {
              low: 16,
              high: 20,
            },
          },
        },
      ],
    },
  ],
  "Curl Ups": [
    {
      label: "Level 1",
      data: [
        {
          ageGt: 3,
          ageLte: 7,
          value: {
            male: {
              low: 15,
              high: undefined,
            },
            female: {
              low: 15,
              high: undefined,
            },
          },
        },
        {
          ageGt: 7,
          ageLte: 12,
          value: {
            male: {
              low: 25,
              high: undefined,
            },
            female: {
              low: 25,
              high: undefined,
            },
          },
        },
        {
          ageGt: 12,
          ageLte: 17,
          value: {
            male: {
              low: 35,
              high: undefined,
            },
            female: {
              low: 35,
              high: undefined,
            },
          },
        },
      ],
    },
    {
      label: "Level 2",
      data: [
        {
          ageGt: 3,
          ageLte: 7,
          value: {
            male: {
              low: 25,
              high: undefined,
            },
            female: {
              low: 25,
              high: undefined,
            },
          },
        },
        {
          ageGt: 7,
          ageLte: 12,
          value: {
            male: {
              low: 35,
              high: undefined,
            },
            female: {
              low: 35,
              high: undefined,
            },
          },
        },
        {
          ageGt: 12,
          ageLte: 17,
          value: {
            male: {
              low: 45,
              high: undefined,
            },
            female: {
              low: 40,
              high: undefined,
            },
          },
        },
      ],
    },
    {
      label: "Level 3",
      data: [
        {
          ageGt: 3,
          ageLte: 7,
          value: {
            male: {
              low: 35,
              high: undefined,
            },
            female: {
              low: 35,
              high: undefined,
            },
          },
        },
        {
          ageGt: 7,
          ageLte: 12,
          value: {
            male: {
              low: 45,
              high: undefined,
            },
            female: {
              low: 45,
              high: undefined,
            },
          },
        },
        {
          ageGt: 12,
          ageLte: 17,
          value: {
            male: {
              low: 55,
              high: undefined,
            },
            female: {
              low: 50,
              high: undefined,
            },
          },
        },
      ],
    },
  ],
  "Body Fat": [
    {
      label: "Lean",
      data: [
        {
          ageGt: 0,
          ageLte: 20,
          value: {
            male: {
              low: 2,
              high: 8,
            },
            female: {
              low: 11,
              high: 18,
            },
          },
        },
        {
          ageGt: 20,
          ageLte: 25,
          value: {
            male: {
              low: 3,
              high: 10,
            },
            female: {
              low: 12,
              high: 19,
            },
          },
        },
        {
          ageGt: 25,
          ageLte: 30,
          value: {
            male: {
              low: 4,
              high: 11,
            },
            female: {
              low: 13,
              high: 20,
            },
          },
        },
        {
          ageGt: 30,
          ageLte: 35,
          value: {
            male: {
              low: 5,
              high: 13,
            },
            female: {
              low: 13,
              high: 21,
            },
          },
        },
        {
          ageGt: 35,
          ageLte: 40,
          value: {
            male: {
              low: 6,
              high: 15,
            },
            female: {
              low: 14,
              high: 22,
            },
          },
        },
        {
          ageGt: 40,
          ageLte: 45,
          value: {
            male: {
              low: 7,
              high: 16,
            },
            female: {
              low: 14,
              high: 23,
            },
          },
        },
        {
          ageGt: 45,
          ageLte: 50,
          value: {
            male: {
              low: 8,
              high: 17,
            },
            female: {
              low: 15,
              high: 24,
            },
          },
        },
        {
          ageGt: 50,
          ageLte: 55,
          value: {
            male: {
              low: 9,
              high: 19,
            },
            female: {
              low: 16,
              high: 26,
            },
          },
        },
        {
          ageGt: 55,
          ageLte: 100,
          value: {
            male: {
              low: 10,
              high: 21,
            },
            female: {
              low: 16,
              high: 27,
            },
          },
        },
      ],
    },
    {
      label: "Ideal",
      data: [
        {
          ageGt: 0,
          ageLte: 20,
          value: {
            male: {
              low: 8,
              high: 14,
            },
            female: {
              low: 18,
              high: 23,
            },
          },
        },
        {
          ageGt: 20,
          ageLte: 25,
          value: {
            male: {
              low: 10,
              high: 15,
            },
            female: {
              low: 19,
              high: 24,
            },
          },
        },
        {
          ageGt: 25,
          ageLte: 30,
          value: {
            male: {
              low: 11,
              high: 16,
            },
            female: {
              low: 21,
              high: 25,
            },
          },
        },
        {
          ageGt: 30,
          ageLte: 35,
          value: {
            male: {
              low: 13,
              high: 17,
            },
            female: {
              low: 21,
              high: 26,
            },
          },
        },
        {
          ageGt: 35,
          ageLte: 40,
          value: {
            male: {
              low: 15,
              high: 20,
            },
            female: {
              low: 22,
              high: 27,
            },
          },
        },
        {
          ageGt: 40,
          ageLte: 45,
          value: {
            male: {
              low: 16,
              high: 22,
            },
            female: {
              low: 23,
              high: 28,
            },
          },
        },
        {
          ageGt: 45,
          ageLte: 50,
          value: {
            male: {
              low: 17,
              high: 23,
            },
            female: {
              low: 24,
              high: 30,
            },
          },
        },
        {
          ageGt: 50,
          ageLte: 55,
          value: {
            male: {
              low: 20,
              high: 25,
            },
            female: {
              low: 26,
              high: 31,
            },
          },
        },
        {
          ageGt: 55,
          ageLte: 100,
          value: {
            male: {
              low: 21,
              high: 26,
            },
            female: {
              low: 27,
              high: 32,
            },
          },
        },
      ],
    },
    {
      label: "Average",
      data: [
        {
          ageGt: 0,
          ageLte: 20,
          value: {
            male: {
              low: 14,
              high: 21,
            },
            female: {
              low: 23,
              high: 30,
            },
          },
        },
        {
          ageGt: 20,
          ageLte: 25,
          value: {
            male: {
              low: 15,
              high: 22,
            },
            female: {
              low: 24,
              high: 30,
            },
          },
        },
        {
          ageGt: 25,
          ageLte: 30,
          value: {
            male: {
              low: 16,
              high: 21,
            },
            female: {
              low: 25,
              high: 31,
            },
          },
        },
        {
          ageGt: 30,
          ageLte: 35,
          value: {
            male: {
              low: 17,
              high: 25,
            },
            female: {
              low: 26,
              high: 33,
            },
          },
        },
        {
          ageGt: 35,
          ageLte: 40,
          value: {
            male: {
              low: 20,
              high: 26,
            },
            female: {
              low: 27,
              high: 34,
            },
          },
        },
        {
          ageGt: 40,
          ageLte: 45,
          value: {
            male: {
              low: 22,
              high: 27,
            },
            female: {
              low: 28,
              high: 35,
            },
          },
        },
        {
          ageGt: 45,
          ageLte: 50,
          value: {
            male: {
              low: 23,
              high: 29,
            },
            female: {
              low: 30,
              high: 36,
            },
          },
        },
        {
          ageGt: 50,
          ageLte: 55,
          value: {
            male: {
              low: 25,
              high: 30,
            },
            female: {
              low: 31,
              high: 36,
            },
          },
        },
        {
          ageGt: 55,
          ageLte: 100,
          value: {
            male: {
              low: 26,
              high: 31,
            },
            female: {
              low: 32,
              high: 37,
            },
          },
        },
      ],
    },
    {
      label: "Over Fat",
      data: [
        {
          ageGt: 0,
          ageLte: 20,
          value: {
            male: {
              low: 21,
              high: 25,
            },
            female: {
              low: 30,
              high: 35,
            },
          },
        },
        {
          ageGt: 20,
          ageLte: 25,
          value: {
            male: {
              low: 23,
              high: 26,
            },
            female: {
              low: 30,
              high: 35,
            },
          },
        },
        {
          ageGt: 25,
          ageLte: 30,
          value: {
            male: {
              low: 21,
              high: 27,
            },
            female: {
              low: 31,
              high: 36,
            },
          },
        },
        {
          ageGt: 30,
          ageLte: 35,
          value: {
            male: {
              low: 25,
              high: 28,
            },
            female: {
              low: 33,
              high: 36,
            },
          },
        },
        {
          ageGt: 35,
          ageLte: 40,
          value: {
            male: {
              low: 26,
              high: 29,
            },
            female: {
              low: 34,
              high: 37,
            },
          },
        },
        {
          ageGt: 40,
          ageLte: 45,
          value: {
            male: {
              low: 27,
              high: 30,
            },
            female: {
              low: 35,
              high: 38,
            },
          },
        },
        {
          ageGt: 45,
          ageLte: 50,
          value: {
            male: {
              low: 29,
              high: 31,
            },
            female: {
              low: 36,
              high: 38,
            },
          },
        },
        {
          ageGt: 50,
          ageLte: 55,
          value: {
            male: {
              low: 31,
              high: 33,
            },
            female: {
              low: 36,
              high: 39,
            },
          },
        },
        {
          ageGt: 55,
          ageLte: 100,
          value: {
            male: {
              low: 31,
              high: 34,
            },
            female: {
              low: 37,
              high: 40,
            },
          },
        },
      ],
    },
  ],
};

export default ASMT_NORMATIVE_DATA;
