import {createTheme} from '@mui/material/styles';

const DATA_TABLE_THEME = () => createTheme({
  components: {
    MUIDataTableBodyCell: {
      styleOverrides: {
        root: {
          backgroundColor: "#FFF"
        }
      }
    },
    MUIDataTableToolbar: {
      styleOverrides: {
        root: {
          width: "100%"
        },
        titleText: {
          fontWeight: 600,
          color: "#3c3c3c"
        }
      }
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          padding: "10px 15px",
          marginTop: "10px"
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        body: {
          padding: "8px 16px"
        }
      }
    }
  }
});

export default DATA_TABLE_THEME;
