const CALENDAR_EVENT_CANCEL_TYPES = [
  {
    name: "This event",
    typeEnum: "CURRENT_EVENT",
  },
  {
    name: "This and following events",
    typeEnum: "CURRENT_AND_FOLLOWING_EVENTS",
  },
  {
    name: "All events in series",
    typeEnum: "ALL_EVENTS",
  },
];

export default CALENDAR_EVENT_CANCEL_TYPES;
