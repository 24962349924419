import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, {tableCellClasses} from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Tooltip from '@mui/material/Tooltip';
import Paper from "@mui/material/Paper";
import {styled} from "@mui/material/styles";
import "styles/mui-table.css";

function EnhancedTableHead(props) {
  const {headCells} = props;

  return (<TableHead>
    <TableRow>
      {
        headCells.map((headCell, index) => {
          if (headCell.id === "id") // Hide 'id' field from table heading
            return null;
          return (<StyledTableCell key={index} align={"left"} padding="normal">
            <Tooltip title={headCell.label}>
              <Typography variant="subtitle2" sx={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitLineClamp: "1",
                  WebkitBoxOrient: "vertical"
                }}>
                {headCell.label}
              </Typography>
            </Tooltip>
          </StyledTableCell>);
        })
      }
    </TableRow>
  </TableHead>);
}

EnhancedTableHead.propTypes = {
  headCells: PropTypes.array.isRequired
};

EnhancedTable.propTypes = {
  headings: PropTypes.array.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  rowsData: PropTypes.array.isRequired
};

const StyledTableCell = styled(TableCell)(({theme}) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  }
}));

const StyledTableRow = styled(TableRow)(({theme}) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0
  }
}));

export default function EnhancedTable(props) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(props.rowsPerPage);
  const rows = props.rowsData;

  const handleClick = (event, id) => {};

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0
    ? Math.max(0, (1 + page) * rowsPerPage - rows.length)
    : 0;

  function convertJsonValuesIntoArray(json) {
    let arr = [];
    Object.keys(json).forEach(function(key) {
      if (key !== "id") // Hide 'id' field from table row data
        arr.push(json[key]);
      }
    );

    return arr;
  }

  return (<Box sx={{
      width: "100%"
    }}>
    <Paper sx={{
        width: "100%",
        mb: 2,
        overflow: "hidden"
      }} elevation={0}>
      <TableContainer sx={{
          maxHeight: 600
        }}>
        <Table sx={{
            minWidth: 750
          }} aria-labelledby="tableTitle" size="medium" stickyHeader aria-label="sticky table">
          <EnhancedTableHead headCells={props.headings}/>
          <TableBody>
            {
              rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                return (<StyledTableRow hover onClick={event => handleClick(event, row.id)} tabIndex={-1} key={`enhanced-table-row-${index}`} sx={{
                    "&:hover" : {
                      boxShadow: 0,
                      backgroundColor: "#fcfcfc !important"
                    }
                  }}>
                  {
                    convertJsonValuesIntoArray(row).map((item, itemIndex) => (<StyledTableCell key={`enhanced-table-row-cell-${itemIndex}`} align="left" size="small">
                      {
                        (React.isValidElement(item))
                          ? item
                          : <Tooltip title={item}>
                              <Typography variant="body1" component={"div"} sx={{
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  display: "-webkit-box",
                                  WebkitLineClamp: "1",
                                  WebkitBoxOrient: "vertical"
                                }}>
                                {item}
                              </Typography>
                            </Tooltip>
                      }
                    </StyledTableCell>))
                  }
                </StyledTableRow>);
              })
            }
            {
              emptyRows > 0 && (<StyledTableRow style={{
                  height: 53 * emptyRows
                }}>
                <StyledTableCell colSpan={7}/>
              </StyledTableRow>)
            }
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination rowsPerPageOptions={[5, 10, 25]} component="div" count={rows.length} rowsPerPage={rowsPerPage} page={page} onPageChange={handleChangePage} onRowsPerPageChange={handleChangeRowsPerPage}/>
    </Paper>
  </Box>);
}
