import ApiBaseService from "services/ApiBaseService.js";

class UserService {
  static getHospitals() {
    return ApiBaseService.request(`/users/hospitals`, "GET", null);
  }

  static getRoles() {
    return ApiBaseService.request(`/users/roles`, "GET", null);
  }

  static getUser() {
    return ApiBaseService.request(`/users`, "GET", null);
  }

  static getProfile() {
    return ApiBaseService.request(`/users/profile`, "GET", null);
  }

  static updateProfile([formData]) {
    return ApiBaseService.request(`/users/profile`, "PUT", formData);
  }
}

export default UserService;
