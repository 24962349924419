function getLocation(data) {
  let city = "";
  let state = "";
  let country = "";

  data = data.results;

  if (data.length == 0) return {city, state, country};

  data = data[0].address_components;
  for (let component of data) {
    if (component.types.includes("locality")) {
      city = component.long_name;
    } else if (component.types.includes("administrative_area_level_1")) {
      state = component.long_name;
    } else if (component.types.includes("country")) {
      country = component.long_name;
    }
  }

  return {city, state, country};
}

const ZipcodeUtils = {
  getLocation
};

export default ZipcodeUtils;
