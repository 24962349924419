import { useState, useEffect, useCallback } from "react";
import useAPI from "hooks/useAPI.js";
import OrganizationService from "services/OrganizationService";
import { useSelector } from "react-redux";
import { selectHospital } from "state/slices/hospitalSlice";
import { Paper } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import ColumnBarChart from "components/charts/ColumnBarChart";
import PieChart from "components/charts/PieChart";
import Typography from "@mui/material/Typography";
import HospitalStatsChartsUtils from "utils/HospitalStatsChartDataUtils";
import HOSPITAL_STATS_CHART_PROPS from "utils/constants/HospitalStatsChartProps";

function Dashboard() {
  const theme = useTheme();
  const focusedHospital = useSelector(selectHospital);
  const [statsData, setStatsData] = useState({
    adolescentPatientsCount: 0,
    adultPatientsCount: 0,
    childPatientsCount: 0,
    patientsCount: 0,
    completedDoctorAssessmentsCount: 0,
    completedEnrollmentSessionsCount: 0,
    last4WeeksCompletedDoctorAssessmentForms: [],
    last4WeeksCompletedEnrollmentSessions: [],
  });

  const fetchHospitalStatsAPI = useAPI(
    OrganizationService.getHospitalStats,
    fetchHospitalStatsHandler,
    false
  );

  useEffect(() => {
    if (focusedHospital.id !== "") {
      // Fetch stats only after hospital has been set
      fetchHospitalStats();
    }

    //Set chart categories
    setLast4WeekBasedChartCategories(
      HOSPITAL_STATS_CHART_PROPS.doctorAsmtForms
    );
    setLast4WeekBasedChartCategories(
      HOSPITAL_STATS_CHART_PROPS.enrollmentSessions
    );
  }, [focusedHospital]);

  const fetchHospitalStats = useCallback(() => {
    fetchHospitalStatsAPI.execute(focusedHospital.id);
  }, [fetchHospitalStatsAPI]);

  /* API Success Handlers */
  function fetchHospitalStatsHandler(response) {
    setStatsData(response.data);
  }

  return (
    <Paper
      elevation={0}
      className="d-flex flex-row flex-wrap justify-content-between p-3"
    >
      <div className="d-flex flex-column justify-content-between">
        <Paper
          elevation={0}
          sx={{
            width: "200px",
            height: "85px",
            backgroundColor: theme.palette.background.default,
          }}
          className="p-3"
        >
          <Typography variant="h1" color={theme.palette.text.secondary}>
            {statsData.patientsCount}
          </Typography>
          <Typography
            className="mt-1"
            variant="subtitle2"
            color={theme.palette.text.main}
          >
            Patients
          </Typography>
        </Paper>
        <Paper
          elevation={0}
          sx={{
            width: "200px",
            height: "85px",
            backgroundColor: theme.palette.background.default,
          }}
          className="p-3 mt-3"
        >
          <Typography variant="h1" color={theme.palette.text.secondary}>
            {statsData.completedDoctorAssessmentsCount}
          </Typography>
          <Typography
            className="mt-1"
            variant="subtitle2"
            color={theme.palette.text.main}
          >
            Assessments Completed
          </Typography>
        </Paper>
        <Paper
          elevation={0}
          sx={{
            width: "200px",
            height: "85px",
            backgroundColor: theme.palette.background.default,
          }}
          className="p-3 mt-3"
        >
          <Typography variant="h1" color={theme.palette.text.secondary}>
            {statsData.completedEnrollmentSessionsCount}
          </Typography>
          <Typography
            className="mt-1"
            variant="subtitle2"
            color={theme.palette.text.main}
          >
            Sessions Completed
          </Typography>
        </Paper>
      </div>
      <Paper
        elevation={0}
        sx={{
          backgroundColor: theme.palette.background.default,
        }}
        className="px-3"
      >
        <PieChart
          dataLabels={HOSPITAL_STATS_CHART_PROPS.patientAgeGroup.dataLabels}
          data={HospitalStatsChartsUtils.getPatientAgeGroupDataSeries(
            statsData
          )}
        />
      </Paper>
      <Paper
        elevation={0}
        sx={{
          backgroundColor: theme.palette.background.default,
        }}
        className="pt-3 px-3"
      >
        <ColumnBarChart
          chartProps={HOSPITAL_STATS_CHART_PROPS.doctorAsmtForms}
          data={HospitalStatsChartsUtils.getLast4WeeksDataSeries(
            statsData.last4WeeksCompletedDoctorAssessmentForms
          )}
        />
      </Paper>
      <Paper
        elevation={0}
        sx={{
          backgroundColor: theme.palette.background.default,
        }}
        className="pt-3 px-3 mt-3"
      >
        <ColumnBarChart
          chartProps={HOSPITAL_STATS_CHART_PROPS.enrollmentSessions}
          data={HospitalStatsChartsUtils.getLast4WeeksDataSeries(
            statsData.last4WeeksCompletedEnrollmentSessions
          )}
        />
      </Paper>
    </Paper>
  );
}

function setLast4WeekBasedChartCategories(chartProps) {
  chartProps.xaxis.categories =
    HospitalStatsChartsUtils.getLast4WeeksAsXaxisCategories();
}
export default Dashboard;
