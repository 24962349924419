import EnumUtils from "./EnumUtils";
import ASMT_NORMATIVE_DATA from "./constants/DoctorAsmtNormativeData";

function buildKeyChains(formDataItems) {
  let assessmentDataKeyChains = [];

  let count = 1; // For associating assessment number

  for (let data of formDataItems) {
    let assessmentId = data.assessmentId;
    let assessment = assessmentDataKeyChains.find(
      (ob) => ob.assessmentId === assessmentId
    );
    if (!assessment) {
      assessment = {
        assessmentId,
        assessmentNumber: count,
        assessmentType: EnumUtils.parse(data.assessmentFormTypeEnum).split(
          " "
        )[0],
        data: {},
      };
      assessmentDataKeyChains.push(assessment);
      count++;
    }
    let keyChain = `${data.sectionName}:${data.subSectionNo}:${data.subSectionName}:${data.fieldName}:${data.fieldCategory}`;

    assessment.data[keyChain] = data.fieldValue;
  }
  return assessmentDataKeyChains;
}

function getColumnBarDataSeries(asmtStagedData, keyChains) {
  return asmtStagedData.map((asmtData) => {
    return {
      name: "Assessment " + asmtData.assessmentNumber,
      data: keyChains.map((keyChain) => {
        if (asmtData.data[keyChain] === undefined) return 0;
        return asmtData.data[keyChain];
      }),
    };
  });
}

function getRadialDataLabels(asmtStagedData) {
  return asmtStagedData.map(
    (asmtData) => "Assessment " + asmtData.assessmentNumber
  );
}

function getRadialDataSeries(asmtStagedData, keyChains) {
  return asmtStagedData.map((asmtData) => {
    let keyChain = keyChains[0]; // Since radial chart only accepts single category
    if (asmtData.data[keyChain] === undefined) return 0;
    return asmtData.data[keyChain];
  });
}

function getNormativeData(asmtChart, patientAge, patientGender) {
  let normativeDataList = ASMT_NORMATIVE_DATA[asmtChart.title]; // of section's chart
  if (normativeDataList == undefined) {
    // if normative data not exist for chart
    return null;
  }
  let patientNorms = [];
  normativeDataList.forEach((normativeData) => {
    let norm = normativeData.data.find(
      (data) => patientAge > data.ageGt && patientAge <= data.ageLte
    );
    if (norm) {
      patientNorms.push({
        low: norm.value[patientGender].low,
        high: norm.value[patientGender].high,
        label: normativeData.label,
      });
    }

  });
  return patientNorms;
}

const DoctorAsmtChartDataUtils = {
  buildKeyChains,
  getColumnBarDataSeries,
  getRadialDataLabels,
  getRadialDataSeries,
  getNormativeData,
};

export default DoctorAsmtChartDataUtils;
