import {Outlet} from "react-router-dom";
import SideNavBar from "components/navs/SideNavBar.js";
import ORGANIZATION_ADMIN_MAIN_NAV_BAR_ITEMS from "utils/constants/OrganizationAdminSideNavBarItems.js";

function OrganizationAdmin() {

  return (<div>
    <div className="d-flex flex-row flex-nowrap justify-content-around mt-3">
      <div className="me-3">
        <SideNavBar items={ORGANIZATION_ADMIN_MAIN_NAV_BAR_ITEMS} basePathElement={"organization"}/>
      </div>
      <div className="flex-grow-1">
        <Outlet/>
      </div>
    </div>
  </div>);
}

export default OrganizationAdmin;
