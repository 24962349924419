import * as React from "react";
import styles from "styles/header.module.css";
import withRouter from "components/utils/withRouter.js";
import Typography from "@mui/material/Typography";

function Header(props) {
  return (
    <nav className="navbar navbar-expand-lg bg-body-tertiary">
      <div className="container-fluid">
        <img
          id={styles["logo"]}
          className="navbar-brand"
          src="../../assets/logo-color.webp"
          alt="Neuroelpis Logo"
          onClick={() => props.navigate("/")}
        />
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#headerNav"
          aria-controls="headerNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="headerNav">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            {/*
          This tag is required to place the nav items to the end
          */}
          </ul>
          <span className="navbar-text">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <Typography
                  className="nav-link text-end"
                  sx={{
                    cursor: "pointer",
                  }}
                  onClick={() => props.navigate("/account/")}
                >
                  Login
                </Typography>
              </li>
              <li className="nav-item">
                <Typography
                  className="nav-link text-end"
                  sx={{
                    cursor: "pointer",
                  }}
                  onClick={() => props.navigate("/account/signup/patient")}
                >
                  Patient Signup
                </Typography>
              </li>
            </ul>
          </span>
        </div>
      </div>
    </nav>
  );
}

export default withRouter(Header);
