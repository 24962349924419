import Authenticator from "services/Authenticator.js";
import {Navigate} from "react-router-dom";

function RequireAuth({routeType, children}) {
  return (Authenticator.isAuthenticated() && Authenticator.isAuthorized(routeType)) ? (
    children
  ) : (
    <Navigate to="/" replace />
  );
}
export default RequireAuth;
