import { useState, useEffect, useCallback, useRef } from "react";
import Paper from "@mui/material/Paper";
import MUIDataTable from "mui-datatables";
import { ThemeProvider } from "@mui/material/styles";
import DATA_TABLE_THEME from "themes/DataTableTheme.js";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import { useForm } from "react-hook-form";
import EnumUtils from "utils/EnumUtils.js";
import EditIcon from "@mui/icons-material/Edit";
import DoneIcon from "@mui/icons-material/Done";
import SaveIcon from "@mui/icons-material/Save";
import BlockRoundedIcon from "@mui/icons-material/BlockRounded";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import Tooltip from "@mui/material/Tooltip";
import StringUtils from "utils/StringUtils.js";
import TableRowActionsCell from "components/utils/TableRowActionsCell.js";
import OrganizationService from "services/OrganizationService.js";
import OrganizationAdminService from "services/OrganizationAdminService.js";
import PopUpModal from "components/utils/PopUpModal.js";
import InputErrorMessage from "components/utils/InputErrorMessage.js";
import COUNTRY_CODES_LIST from "utils/constants/CountryCodesList.js";
import RegexUtils from "utils/RegexUtils.js";
import useAPI from "hooks/useAPI";

function UsersManagement() {
  const [usersList, setUsersList] = useState([]);
  const [roleTypeList, setRoleTypeList] = useState([]);
  const [hospitalList, setHospitalList] = useState([]);
  const [popUpModalData, setPopUpModalData] = useState({});
  const popUpModalRef = useRef(null);

  const getUsersAPI = useAPI(
    OrganizationAdminService.getUsers,
    getUsersHandler,
    false,
    true
  );
  const getUserRoleTypesAPI = useAPI(
    OrganizationAdminService.getUserRoleTypes,
    getUserRoleTypesHandler,
    false,
    true
  );
  const getOrganizationHospitalsAPI = useAPI(
    OrganizationService.getOrganizationHospitals,
    getOrganizationHospitalsHandler,
    false,
    true
  );

  /* API success handlers */
  function getUsersHandler(response) {
    let data = response.data;
    let users = [];
    data.map((user, index) => users.push(transformJsonData(user, index + 1)));
    setUsersList(users);
  }

  function getUserRoleTypesHandler(response) {
    let roleTypes = response.data;
    setRoleTypeList(roleTypes);
  }

  function getOrganizationHospitalsHandler(response) {
    let hospitals = response.data;
    setHospitalList(hospitals);
  }

  const dataTableOptions = {
    filter: false,
    responsive: "standard",
    selectableRows: "none",
    download: false,
    print: false,
    jumpToPage: true,
  };

  return (
    <>
      <Button
        sx={{
          marginTop: "-52px",
        }}
        className="float-end"
        onClick={() => {
          setPopUpModalData({});
          openModal();
        }}
        startIcon={<img src="assets/plus-icon.svg" alt="Add icon" />}
        variant="contained"
      >
        Add User
      </Button>
      <Paper elevation={0}>
        {getUsersAPI.status === "pending" ? (
          <div className="text-center">
            <CircularProgress
              className="text-center"
              sx={{
                my: 20,
              }}
            />
          </div>
        ) : (
          <ThemeProvider theme={DATA_TABLE_THEME}>
            <MUIDataTable
              title={"Users"}
              data={usersList}
              columns={dataTableColumns}
              options={dataTableOptions}
            />
          </ThemeProvider>
        )}
        <PopUpModal ref={popUpModalRef}>
          <UserModal
            user={popUpModalData}
            roleTypeList={roleTypeList}
            hospitalList={hospitalList}
            closeModalHandler={closeModal}
          />
        </PopUpModal>
      </Paper>
    </>
  );

  function ActionsCell({ user }) {
    const [isUserLocked, setIsUserLocked] = useState(user.isLocked);
    const [isUserBlocked, setIsUserBlocked] = useState(user.isBlocked);

    const unlockUserAPI = useAPI(
      OrganizationAdminService.unlockUser,
      lockUnlockUserHandler,
      true
    );
    const lockUserAPI = useAPI(
      OrganizationAdminService.lockUser,
      lockUnlockUserHandler,
      true
    );
    const blockUserAPI = useAPI(
      OrganizationAdminService.blockUser,
      blockUserHandler,
      true
    );

    function lockOrUnlockUser(userId, isUserLocked) {
      if (isUserLocked) {
        unlockUserAPI.execute(userId);
      } else {
        lockUserAPI.execute(userId);
      }
    }

    function blockUser(userId) {
      blockUserAPI.execute(userId);
    }

    /* API Success Handlers */

    function lockUnlockUserHandler(response) {
      setIsUserLocked((prevState) => {
        return !prevState;
      });
    }

    function blockUserHandler(response) {
      setIsUserBlocked(true);
    }

    const actions = {
      startItems: [
        {
          component: <EditIcon fontSize="inherit" color="secondary" />,
          onClickHandler: () => {
            setPopUpModalData(user);
            openModal();
          },
        },
        {
          component: isUserBlocked ? (
            <LockIcon fontSize="inherit" color="custom.dark" />
          ) : isUserLocked ? (
            <Tooltip title="Tap to unlock user">
              <LockIcon fontSize="inherit" color="warning" />
            </Tooltip>
          ) : (
            <Tooltip title="Tap to lock user">
              <LockOpenIcon fontSize="inherit" color="warning" />
            </Tooltip>
          ),
          onClickHandler: () => {
            // If user is blocked, it is not required to lock or unlock user
            if (!isUserBlocked) lockOrUnlockUser(user.id, isUserLocked);
          },
        },
        {
          component: isUserBlocked ? (
            <Tooltip title="User already blocked">
              <RemoveCircleIcon fontSize="inherit" color="error" />
            </Tooltip>
          ) : (
            <Tooltip title="Tap to block user">
              <BlockRoundedIcon fontSize="inherit" color="error" />
            </Tooltip>
          ),
          onClickHandler: () => {
            // Only block user if user is not blocked
            if (!isUserBlocked) blockUser(user.id);
          },
        },
      ],
      menuItems: [],
    };

    return <TableRowActionsCell actions={actions} />;
  }

  function transformJsonData(user, index) {
    return {
      id: user.id,
      index: index,
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      countryCode: user.countryCode,
      phone: user.phone,
      actions: <ActionsCell user={user} />,
    };
  }

  function openModal() {
    popUpModalRef.current.openModal();
  }

  function closeModal() {
    popUpModalRef.current.closeModal();
    getUsersAPI.execute();
  }
}

function UserModal({ user, roleTypeList, hospitalList, closeModalHandler }) {
  const [isAddingUser, setIsAddingUser] = useState(
    Object.keys(user).length == 0
  );

  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    reset,
  } = useForm({ criteriaMode: "all", mode: "onTouched" });

  const addUserAPI = useAPI(
    OrganizationAdminService.addUser,
    () => closeModalHandler(),
    true
  );
  const updateUserAPI = useAPI(
    OrganizationAdminService.updateUser,
    () => closeModalHandler(),
    true
  );

  useEffect(() => {
    reset(user);
    setFormMultipleValueInputs();
  }, [user]);

  function setFormMultipleValueInputs() {
    if (!isAddingUser) {
      // Set roles
      setValue("roleType", user.roles[0].typeEnum);

      // Set associated hospitals
      for (let key in user.hospitals) {
        setValue(`associatedHospitalId-${user.hospitals[key].id}`, true);
      }
    }
  }

  function addOrUpdateUser(formData) {
    delete formData.hospitals;
    delete formData.roles;
    let userData = {};
    userData.roleTypes = [];
    userData.associatedHospitalIds = [];
    // Parse Role Types, Hospital Ids and assign other userData
    for (let input in formData) {
      if (input.includes("roleType") && formData[input]) {
        userData.roleTypes.push(formData[input]);
      } else if (input.includes("associatedHospitalId-") && formData[input]) {
        userData.associatedHospitalIds.push(parseInt(input.split("-")[1]));
      } else {
        userData[input] = formData[input];
      }
    }

    if (isAddingUser) {
      addUserAPI.execute(userData);
    } else {
      updateUserAPI.execute(formData.id, userData);
    }
  }

  return (
    <div>
      <form
        className="container py-4 px-4"
        onSubmit={handleSubmit(addOrUpdateUser)}
      >
        <h5 className="mb-4">
          {isAddingUser ? "Add " : "Update "}
          User
        </h5>
        <div className="row mb-4">
          <div className="col col-5 mb-3">
            <label htmlFor="firstNameInput" className="form-label">
              First Name
            </label>
            <input
              type="text"
              name="firstName"
              className="form-control"
              id="firstNameInput"
              {...register("firstName", {
                required: "First Name is required",
                pattern: {
                  value: RegexUtils.NAME,
                  message: "Fist Name is invalid",
                },
              })}
            />
            <InputErrorMessage name="firstName" errors={errors} />
          </div>
          <div className="col col-5 mb-3">
            <label htmlFor="lastNameInput" className="form-label">
              Last Name
            </label>
            <input
              type="text"
              name="lastName"
              className="form-control"
              id="lastNameInput"
              {...register("lastName", {
                required: "Last Name is required",
                pattern: {
                  value: RegexUtils.NAME,
                  message: "Last Name is invalid",
                },
              })}
            />
            <InputErrorMessage name="lastName" errors={errors} />
          </div>
          <div className="col col-4 mb-3">
            <label htmlFor="emailInput" className="form-label">
              Email ID
            </label>
            <input
              type="email"
              name="email"
              className="form-control"
              id="emailInput"
              {...register("email", {
                required: "Email ID is required",
                pattern: {
                  value: RegexUtils.EMAIL,
                  message: "Email ID is invalid",
                },
              })}
            />
            <InputErrorMessage name="email" errors={errors} />
          </div>
          <div className="col col-4 mb-3">
            <label htmlFor="phoneInput" className="form-label">
              Phone
            </label>
            <input
              type="text"
              name="phone"
              className="form-control"
              id="phoneInput"
              {...register("phone", {
                required: "Phone is required",
                pattern: {
                  value: RegexUtils.PHONE,
                  message: "Phone is invalid",
                },
              })}
              onKeyUp={(e) => {
                setValue("phone", StringUtils.formatPhoneNumber(e));
              }}
            />
            <InputErrorMessage name="phone" errors={errors} />
          </div>
          <div className="col col-4 mb-3">
            <label htmlFor="countryCode" className="form-label">
              Country Code
            </label>
            <select
              className="form-select"
              id="countryCodeInput"
              aria-label="Default select example"
              {...register("countryCode", {
                required: "Country Code is required",
                minLength: {
                  value: 2,
                  message: "Country Code must exceed 2 characters",
                },
                maxLength: {
                  value: 5,
                  message: "Country Code must not exceed 5 characters",
                },
              })}
            >
              <option></option>
              {COUNTRY_CODES_LIST.map((item, index) => (
                <option key={item.value + index} value={item.value}>
                  {item.country}
                </option>
              ))}
            </select>
            <InputErrorMessage name="countryCode" errors={errors} />
          </div>
          <div className="col col-4 mb-3">
            <label className="form-label d-block">Role Types</label>
            {roleTypeList.map((role, index) => {
              return (
                <div
                  key={`roleIndex-${index}`}
                  className="form-check form-check-inline"
                >
                  <input
                    className="form-check-input"
                    type="radio"
                    name="roleType"
                    id={`roleTypeInput-${role.typeEnum}`}
                    value={role.typeEnum}
                    {...register("roleType")}
                  />
                  <label
                    className="form-check-label"
                    htmlFor={`roleTypeInput-${role.typeEnum}`}
                  >
                    {EnumUtils.parse(role.typeEnum)}
                  </label>
                </div>
              );
            })}
          </div>
          <div className="col col-4 mb-3">
            <label htmlFor="associatedHospitalIdInput" className="form-label">
              Associated Centres
            </label>
            {hospitalList.map((hospital, index) => {
              return (
                <div
                  key={`hospitalIndex-${index}`}
                  className="form-check form-check-inline"
                >
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name={`associatedHospitalId-${hospital.id}`}
                    id={`associatedHospitalIdInput-${hospital.id}`}
                    {...register(`associatedHospitalId-${hospital.id}`)}
                  />
                  <label
                    className="form-check-label"
                    htmlFor={`associatedHospitalIdInput-${hospital.id}`}
                  >
                    {hospital.hospitalName}
                  </label>
                </div>
              );
            })}
            <InputErrorMessage name="associatedHospitalId" errors={errors} />
          </div>
        </div>
        {(
          isAddingUser
            ? addUserAPI.status === "pending"
            : updateUserAPI.status === "pending"
        ) ? (
          <LoadingButton
            loading
            loadingPosition="start"
            startIcon={<SaveIcon />}
            variant="contained"
            className="me-3"
          >
            {isAddingUser ? "Add" : "Update"}
          </LoadingButton>
        ) : (
          <Button
            type="submit"
            className="me-3"
            startIcon={<DoneIcon />}
            variant="contained"
          >
            {isAddingUser ? "Add" : "Update"}
          </Button>
        )}
        <Button onClick={() => closeModalHandler()} variant="outlined">
          Cancel
        </Button>
      </form>
    </div>
  );
}

const dataTableColumns = [
  {
    name: "id",
    label: "User ID",
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: "firstName",
    label: "First Name",
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: "lastName",
    label: "Last Name",
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: "email",
    label: "Email ID",
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: "countryCode",
    label: "Country Code",
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: "phone",
    label: "Phone",
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: "actions",
    label: "Actions",
    options: {
      filter: true,
      sort: false,
    },
  },
];

export default UsersManagement;
